import React,{useState,useEffect} from 'react';
import {Table,TableBody,TableCell,TableContainer, IconButton,Tooltip,
        TableHead,TableRow,Card,TextField,Box,LinearProgress} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import SaveIcon from '@material-ui/icons/Save';
import RefreshIcon from '@material-ui/icons/Refresh';
import NumberFormat from 'react-number-format';
import { useHistory } from "react-router-dom";
import TablaPromov from './MTablaPromovidos';
import XLSX from 'xlsx';
import moment from 'moment';
import axios from 'axios';
import qs from 'qs';
const useStyles = makeStyles({
    root: {
      width: '100%',
    },
    estiloCard:{
        minWidth: '250px',
        maxWidth:'100%',
        boxShadow:'0 0.5em 1em -0.125em rgb(10 10 10 / 10%), 0 0 0 1px rgb(10 10 10 / 2%)',
        color:'#4a4a4a',
        display:'block',
        padding:'1rem',
        minHeight: 280,
        marginRight:'1em',
    },
    estiloCardDatos:{
        color:'white',
        textAlign:'center',
        fontWeight:600,
        fontSize:.8+'rem'
    },
    formControl:{
        boxSizing:'border-box',
        fontSize:'1rem',
        position:'relative',
        marginBottom:'1em'
    },
    tablaContainer: {
        width: '240px',
        minHeight: 150,
        //minWidth:'100%',
      },
    unionHeaders:{
      top: 22,
      backgroundColor: process.env.REACT_APP_Color_Second,
      color:'white'
    },
    totalEstilo:{
        fontSize:'1.25rem',
        fontWeight:'bold'
    },
    tituloEstilo:{
        paddingBottom:'.25rem',
        fontWeight:'bold'
    },
    tableRow: {
      "&$selected, &$selected:hover": {
        backgroundColor: "#0F91C1"
      }
    },
    tableCell: {
      "$selected &": {
        color: "white"
      }
    },
    hover: {},
    selected: {}
  });

  const alturaCont = window.innerHeight<937 ? window.innerHeight-130 : window.innerHeight-220

export default function TablaMunicipios({datos,setPromovSec,actualizar,setActualizar}){
    let history = useHistory();
    const classes = useStyles();
    const [espera,setEspera] = useState(false);
    const [listaSecc, setListaSecc] = useState(datos) 
    const [secc,guardarSecc] = useState({ nombre:''});
    const [idSelec,setIdSelec] = useState(-1)
    const [resultados,setResultados] = useState([])
    const auxColor=process.env.REACT_APP_Color_Second
	const {nombre} = secc;
    let auxArreglo=[]

    useEffect(()=>{
        setListaSecc(datos)
    },[datos])
    

    function getDatos(num){
        const source = axios.CancelToken.source(); 
        async function getPromovidos(){
            setEspera(true);
            let auxU=process.env.REACT_APP_LINK +`/promovido-web-promocion-listsecc-promovido`;               
            let token =localStorage.getItem('token20') ;                
            let dataInfo = qs.stringify({                      
                'seccion':num,
                'idDispositivo':'89wuuidsddsfe'      
                });
            let config = {
                url:auxU ,
                method: 'POST',
                headers: { 'access-token' : token, 'Content-Type': 'application/x-www-form-urlencoded' },
                data: dataInfo,
                cancelToken: source.token
            };
            let aux ={};
            await axios(config)
            .then(function (response) {
                aux=  response.data;                                                        
                                                                                           
                   if (aux) 
                   {//console.log(aux);                       
                    setResultados(aux);
                   }              
            })
            .catch(function (error) {                
                console.log(error);
                if ( ( error && error.response  && error.response.data && error.response.data.error && error.response.data.error.name)
                     && ( error.response.data.error.name === "JsonWebTokenError" || error.response.data.error.name === "TokenExpiredError" )) 
                {   localStorage.clear();                    
                    history.push("/");                                          
                }
                if ( error && error.response  && error.response.data && error.response.data.mensaje 
                    && error.response.data.mensaje=== "Datos Incorrectos") 
                {
                    console.log('sin datos' );                    
                }
            });                                  
            setEspera(false);
        }
        getPromovidos()
    }
    const exportarArch = () => {
        let today = new Date();                    
        let fec = moment(today);
        const ws = XLSX.utils.aoa_to_sheet(auxArreglo);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "Reporte");
        XLSX.writeFile(wb, fec.format("YYMMDDHHmmss")+"Lista de Municipios.xlsx")
    };

    function selecFila(promov){                
        setIdSelec(promov.SeccId);
        setPromovSec(promov.SeccId)
        getDatos(promov.SeccId)
    }

    function filtro(){        
        let auxCadena=nombre.trim();         
        let info=listaSecc.filter((dat)=>dat.SeccNum.search(auxCadena) !== -1)         
        return generarTabla(info);
    }

    const onChange = e =>
    {   let expNopermitida = new RegExp('[A-Za-z._:$!%-({})?/*|]');
        let expMenos = new RegExp('-');
        let expMas = new RegExp('[+]');     
        let expCadena = new RegExp('[0-9]');
        if ((expCadena.test(e.target.value) && !expNopermitida.test(e.target.value) && !expMenos.test(e.target.value) &&!expMas.test(e.target.value))
            ||e.target.value===' '||e.target.value==='') 
        {
            guardarSecc({
                ...secc, //copiamos el nombre
                [e.target.name] : e.target.value  
            })
        }        
    };

    function sinDatos(){
        return(
            <TableContainer className={classes.tablaContainer} id="tablaPromotor" style={{height:alturaCont-100}}>
                <Table stickyHeader aria-label="sticky table">
                    <TableHead >    
                        <TableRow >    
                            <TableCell padding='none' className={classes.unionHeaders}>Secc</TableCell>   
                            <TableCell padding='none' className={classes.unionHeaders}>Promov</TableCell>       
                            <TableCell padding='none' className={classes.unionHeaders}>Movilizado</TableCell>    
                        </TableRow>   
                    </TableHead>
                    <TableBody>
                        <TableRow>
                            <TableCell padding='none' align="center">Sin</TableCell>
                            <TableCell padding='none' align="center">Resultados</TableCell>
                            <TableCell padding='none' align="center">Disponibles</TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
        )
    }


    function generarColor(valor,votos){
        var colorEfecti='';    
        if(valor<30){
            colorEfecti= '#FF0505E8';        
        }
        else{
            if(valor>=30 && valor<60)
            {
                colorEfecti= '#FFC300';               
            }
            else{
                if(valor>=60 )
                {
                    colorEfecti= '#1CC63D';
                }
            }
        }
        return(
            <Card className={classes.estiloCardDatos} style={{backgroundColor:colorEfecti}}>
               <NumberFormat value= {votos} displayType={'text'}  thousandSeparator={true}/>
            </Card>
        )
    }

    function getTotales(valorTotal){
        let auxMov=0
        let auxPromv=0
        let arrTotal=[]
        valorTotal.forEach((promov) => 
        {             
            auxMov=auxMov+promov.Movilizado       
            auxPromv=auxPromv+promov.Promovido
        })
        arrTotal={totMov:auxMov,totProm:auxPromv}
        return (arrTotal)
    }
    function generarTabla(lista) {
        let auxi2=["#","Movilizado","Promovido","Sección"];
        auxArreglo.push(auxi2);
        let arrTot=[];
        arrTot=getTotales(lista)
        let tot= Object.keys(lista).length;
        if(tot===0){
            let auxi3=["","Sin","Secciones"]
            auxArreglo.push(auxi3);            
        }  
        return(
            <TableContainer className={classes.tablaContainer} id="tablaPromotor" style={{height:alturaCont-100}}>
                <Table stickyHeader aria-label="sticky table">
                    <TableHead >    
                            <TableRow>  
                                <TableCell padding='none' align="center" style={{backgroundColor:auxColor,color:'white', borderBottom:"1px solid "+auxColor}}>
                                    Secc
                                </TableCell>   
                                <TableCell padding='none' align="center" style={{backgroundColor:auxColor,color:'white', borderBottom:"1px solid "+auxColor}}>
                                    Promov
                                </TableCell>        
                                <TableCell padding='none' align="center" style={{backgroundColor:auxColor,color:'white', borderBottom:"1px solid "+auxColor}}>
                                    Movilizado
                                </TableCell>
                            </TableRow>  
                            <TableRow padding='none' className={classes.unionHeaders}>
                                <TableCell padding='none' align="center" style={{width:3+'rem'}} className={classes.unionHeaders}>
                                    {tot}
                                </TableCell>
                                <TableCell padding='none' align="center" style={{width:4+'rem'}} className={classes.unionHeaders}>
                                    <NumberFormat value={arrTot.totProm} displayType={'text'}  thousandSeparator={true}/>
                                </TableCell>
                                <TableCell padding='none' align="center"  style={{width:'4.5rem'}} className={classes.unionHeaders}>
                                    {generarColor((arrTot.totMov/arrTot.totProm)*100 , arrTot.totMov)}
                                </TableCell>
                            </TableRow> 
                    </TableHead>
                    <TableBody>
                    {lista.map((munici,index) => {
                            //console.log(auxNombre);
                            let auxi=[index+1,munici.Movilizado,munici.Promovido,munici.SeccId]
                            auxArreglo.push(auxi);                      
                        return (                  
                            <TableRow className={classes.tableRow} selected={idSelec === munici.SeccId}  key={index}
                            classes={{ hover: classes.hover, selected: classes.selected }} onClick={ () => selecFila(munici,index) }
                            >                             
                                <TableCell className={classes.tableCell} padding='none' align="center" style={{width:3+'rem'}}>{munici.SeccId}</TableCell>  
                                <TableCell className={classes.tableCell} padding='none' align="center" style={{width:4+'rem'}}>{munici.Promovido}</TableCell>
                                <TableCell className={classes.tableCell} padding='none' style={{width:'4.5rem'}} align="center" >                                         
                                    {generarColor((munici.Movilizado/munici.Promovido)*100 , munici.Movilizado)}                    
                                </TableCell>                                                  
                            </TableRow>              
                            );
                        })
                        } 
                    </TableBody>
                </Table>
            </TableContainer>
            )                   
        }        

    function actualizarDat(e){
        e.preventDefault();
        setActualizar(actualizar+1)
    }
    
    const contenido = secc? (filtro()):(generarTabla(listaSecc));
    const validaCont = listaSecc.length!==0 ? contenido : sinDatos();
    const validaTab = idSelec>0 ? <TablaPromov lista={resultados} secc={idSelec}/> : null
    const tab = espera ? (<Box style={{marginTop:'2rem',marginLeft:'1em',width:'600px'}} sx={{width:'100%'}}>
                        <LinearProgress/> </Box>) : validaTab;
    return (
        <Box display={'flex'} flexDirection="row" style={{paddingTop:1+'rem',height: alturaCont+10}}>
        <Card className={classes.estiloCard}>     
                    <Box display="flex" flexDirection="row">
                        <TextField className={classes.formControl}
                            name="nombre" placeholder="Buscar Sección" disabled={listaSecc.length===0}
                            value={nombre} onChange={onChange}/>
                                <IconButton  onClick={actualizarDat} style={{marginLeft:'.3em',marginTop:'.5em',width:'20px', height:'20px'}}>
                                    <Tooltip title="Recuperar Secciones">
                                        <RefreshIcon color="primary"/>
                                    </Tooltip>
                                </IconButton>
                                {listaSecc.length!==0 ? 
                                <IconButton  onClick={()=>{}} style={{marginLeft:'.3em',marginTop:'.5em',width:'20px', height:'20px'}}>
                                    <Tooltip title="Exportar Secciones">
                                        <SaveIcon color="primary"/>
                                    </Tooltip>
                                </IconButton>
                                : null}
                    </Box>                   	            
                    {validaCont}
        </Card>
        {tab}
    </Box>     
  );
}