import React from 'react'
import {Box,Card,Typography} from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import NumberFormat from 'react-number-format';

const useStyles = makeStyles({
    root: {
      width: '100%',
    },
    estiloCard:{
        width: '180px',
        boxShadow:'0 0.5em 1em -0.125em rgb(10 10 10 / 10%), 0 0 0 1px rgb(10 10 10 / 2%)',
        color:'#4a4a4a',
        display:'block',
        padding:'1rem',
      //  marginTop:'1em',
        marginRight:'.5em'
    },
    estiloCardDatos:{
        color:'white',
        textAlign:'center',
        fontWeight:600,
        fontSize:.8+'rem'
    },
    
    totalEstilo:{
        fontSize:'1.25rem',
        textAlign:'center',
        fontWeight:'bold'
    },
    tituloEstilo:{
        paddingBottom:'.25rem',
        fontWeight:'bold'
    },
  });

  const alturaCont = window.innerHeight<937 ? 240 : 220
export default function CardTotal({datos}){
    const classes = useStyles();

    function generarColor(valor,votos){
        var colorEfecti='';    
        if(valor<30){
            colorEfecti= '#FF0505E8';        
        }
        else{
            if(valor>=30 && valor<60)
            {
                colorEfecti= '#FFC300';               
            }
            else{
                if(valor>=60 )
                {
                    colorEfecti= '#1CC63D';
                }
            }
        }
        return(
            <Card className={classes.estiloCardDatos} style={{backgroundColor:colorEfecti,fontSize:18}}>
               <NumberFormat value= {votos} displayType={'text'}  thousandSeparator={true}/>
            </Card>
        )
    }

    return(<Card className={classes.estiloCard} style={{height:alturaCont}}>
        <Box display="flex" flexDirection="column">
            <Box display="flex" flexDirection="column">
                <div className={classes.totalEstilo}>
                <NumberFormat value= {datos.lNominal} displayType={'text'}  thousandSeparator={true}/>
                </div>
                <Typography style={{textAlign:'center'}} className={classes.tituloEstilo}>L. Nominal</Typography>
            </Box>
            <Box display="flex" flexDirection="column" style={{alignContent:'center'}}>
                {generarColor((datos.votacion/datos.lNominal)*100 , datos.votacion)}
                <Typography style={{textAlign:'center'}} className={classes.tituloEstilo}>Votación</Typography>
            </Box>
            <Box display="flex" flexDirection="column">
                <Box display="flex" flexDirection="row">
                    <img  src= {`./partidos/${datos.partImgWeb}`}  alt="" border="0" height="40" width="40"  style={{marginRight:'.5em'}}/>  
                    <div className={classes.totalEstilo}>
                        <NumberFormat value= {datos.partVoto}displayType={'text'}  thousandSeparator={true}/>
                    </div>
                </Box>
                <Typography style={{textAlign:'center'}} className={classes.tituloEstilo}>Mayoría</Typography>
            </Box>
        </Box>
    </Card>)
}