import React,{useState} from 'react';
import TablaExterno from './TablaExterno'
import { makeStyles } from '@material-ui/core/styles';
import {Box,IconButton,Tooltip, Breadcrumbs,Typography,Card,} from "@material-ui/core";
import IntervaloSelect from './IntervaloTabla';
import SearchIcon from '@material-ui/icons/Search';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import CardPerfil  from './CardPerfil';
//import ContenedorMapa from './ContenedorMapa';
import TotalExt from './TotalExt'
import MapDelegacion from '../MapaEdo';
import TablaxMun from './TablaxMun';
const useStyles = makeStyles((theme) => ({
  textoSelect: {
    '&:hover': {
      color:'#9ccdfa',
    },
    fontSize:1.25+'rem'
  },
}));
const PExterno = () => {
    const classes = useStyles(); 
    const [fSelect, setFSelect] = useState({inicio:new Date(),final:new Date()});
    //const[totalPerfil,setTotalPerfil]= useState({Valido:0,Pendiente:0,Rechazado:0});
    const[totalPerfil,setTotalPerfil]= useState([]);
    const[totalPrflExt,setTotalPrflExt]= useState([]);
    const[actualizar,setActualizar]=useState(true);
    const [arregloColor,setArregloColor] = useState([])  
    const [infoNvlCoord,setInfoNvlCoord] = useState([])   
    const [listMun,setListMun]=useState([])
    const [munSvgSelect,setMunSvgSelect]=useState(0)
    const [tipoTot,setTipoTot]=useState(1)

    const generarLista = e =>{   
      e.preventDefault()    
      setTotalPerfil([])
      setTotalPrflExt([])
      setArregloColor([])
      setActualizar(!actualizar)        
    }
    const letraTipo=(auxNvl,auxNvlId,auxUser)=>{
      //console.log(auxNvl);
      return(
      <span   >
        <Typography color="primary" variant="body2" id={auxUser+auxNvlId} 
          style={{fontSize:.8+'rem',fontWeight:600}}
        >
          {auxUser}
        </Typography>
        <Typography color="textPrimary" variant="body2" id={auxNvl+auxNvlId} 
          style={{fontSize:.7+'rem',textAlign:"center"}}
        >
          {auxNvl}
        </Typography>
      </span>
      )
    }
    
  const nvlInfo=(lista)=>{
  //console.log(lista);
    return(
    <Box mt={1} mb={2}>
      <Breadcrumbs separator={<NavigateNextIcon  />} aria-label="breadcrumb"  >
        {lista.CZId>0 ? letraTipo(lista.CZNom,lista.CZId,lista.CZUser) : null}
        {lista.CRId>0 ? letraTipo(lista.CRNom,lista.CRId,lista.CRUser) : null}
        {lista.CMId>0 ? letraTipo(lista.CMNom,lista.CMId,lista.CMUser) : null}    
        {lista.CSId>0 ? letraTipo(lista.CSNom,lista.CSId,lista.CSUser) : null} 
        {lista.PrId>0 ? letraTipo(lista.PrNom,lista.PrId,lista.PrUser) : null}            
      </Breadcrumbs>
    </Box>
    )
  }
  
  const generaCard=(seleccionado)=>{
    let auxMun=listMun.filter((muni)=>muni.Id===seleccionado)
    //console.log(auxMun);
    return(
    <Card style={{padding:.3+'rem'}}>
      <Typography>{auxMun.length > 0 ? auxMun[0].Nom : ""}</Typography>
    </Card>
    )
  }
 
  const onChangeTipo =(e,auxValor)=>{
    e.preventDefault();
    setTipoTot(auxValor)
  }

  return(
  <div>
    <Box display={'flex'} pb={1}>
      <Box >
        <IconButton color="primary"  aria-label="add circle" 
          component="span" onClick={generarLista}>
            <Tooltip title="Buscar">
            <SearchIcon/>
            </Tooltip>
        </IconButton>                                                                                                                              
      </Box>    
      <Box>
        <IntervaloSelect fSelect={fSelect}  setFSelect={setFSelect} />
      </Box>
    </Box>   
    {infoNvlCoord.length > 0 ? nvlInfo(infoNvlCoord[0]) :null}
    <Box>
      {totalPerfil.length !== 0 ? 
      <CardPerfil valor={totalPerfil} />
      :null}
    </Box>
    <Box display={"flex"} row  width={"100%"} >
      <Box width={"71%"} style={{minWidth:63+'rem'}} >
      <TablaExterno fSelect={fSelect} actualizar={actualizar} setActualizar={setActualizar}
        setTotalPerfil={setTotalPerfil} setArregloColor={setArregloColor}
        setInfoNvlCoord={setInfoNvlCoord } setListMun={setListMun} listMun={listMun} 
        setTotalPrflExt={setTotalPrflExt}
      />
      </Box>
      {arregloColor.length !== 0 ?
      <Box display={"flex"} flexDirection="column"  style={{minWidth:20+'rem'}} >
       
       <Box display="flex"  pb={1} style={{justifyContent:'center'}}>
        <Breadcrumbs separator="-"   aria-label="breadcrumb"   > {/*nivel administrador */}               
          <Typography variant="h5" className={classes.textoSelect} 
            color={tipoTot === 1 ? "Primary":'textPrimary'} onClick={e=>onChangeTipo(e,1)}
          >
            Avance
          </Typography>
          <Typography variant="h5" className={classes.textoSelect} 
            color={tipoTot === 2 ? "Primary":'textPrimary'} onClick={e=>onChangeTipo(e,2)}
          >
            Mapa
          </Typography>               
          </Breadcrumbs> 
        </Box>
        {totalPrflExt.length!==0 &&tipoTot === 1  ?
          <TotalExt valor={totalPrflExt}  tipo={1}/>
        :null}
         {totalPrflExt.length!==0 &&tipoTot === 1  ?
          <TablaxMun seleccionado={totalPrflExt}  tipo={1}/>
        :null}
        {tipoTot === 2  ?<span style={{marginLeft:1+'rem'}} >
          <MapDelegacion arregloColor={arregloColor}/>
        </span>:null}
      </Box>
      :null
      }
    </Box>
  </div>
  );
};

export default PExterno;