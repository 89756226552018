import React,{useState,useEffect,useContext} from 'react';
import {Box,LinearProgress,//IconButton,Typography
    IconButton,Tooltip,} from "@material-ui/core";
//import MenuIcon from '@material-ui/icons/Menu'; 
import SearchIcon from '@material-ui/icons/Search';
import { authUser } from '../funciones/AuthUser'
import { useHistory } from "react-router-dom";
import {AuthContext} from '../context/AuthContext'
import {GeneralesContext} from '../context/GeneralesContext'
import axios from 'axios';
import qs from 'qs';
import moment from 'moment';
import TablaMunicipio from './TablaMunicipio';
import IntervaloSelect from '../perfiles/IntervaloTabla';
//import { makeStyles } from '@material-ui/core/styles';
//import Lista from '../layout/Lista'
import { llamaApiCargaToken, ErrorGeneral } from '../funciones/ClienteAxios';
 

export default function MunicipioEspecial(){
 
    const history = useHistory();
    const source = axios.CancelToken.source();        
    const id =localStorage.getItem('UsuId') ;    
    const usuTipo =localStorage.getItem('Tipo') ;    
    const {usuario,guardarUsuario}= useContext(AuthContext);
    const {generales,guardarGenerales}= useContext(GeneralesContext);     
    const [listMuni,setListMuni] = useState([]);
    const [listMunEsp,setListMunEsp] = useState([]);
    const [fSelect, setFSelect] = useState({inicio:"Thu Feb 02 2023 00:00:00 GMT-0600 (hora estándar central)",final:new Date()});
    const[actualizar,setActualizar]=useState(true);
    const[espera,setEspera]=useState(false);
    //const drawerWidth = 125;
 useEffect(()=>{        

    const autenticado = authUser();
    if(!autenticado)
    {   localStorage.clear();
        history.push("/");
        guardarUsuario([])
    }
   else
   {
        const usu= localStorage.getItem('UsuarioNom');
        const nvl= localStorage.getItem('Tipo');           
        if (usuario.length===0) 
        {
            guardarUsuario({Nombre:usu,nivel:nvl});                                                 
        }
        //document.title="Municipio";        
        getMunicipios()     
        muniEspecial()
    }

    return () => {
        source.cancel();
    }
   //la siguiente linea puede generar errores o no en el useEffect
   // eslint-disable-next-line react-hooks/exhaustive-deps
},[actualizar]);

    function getMunicipios()
    {   const auxU=`perfil-lista-total`;    
        const fInicio=moment(fSelect.inicio).format("YYYY-MM-DD");
        const fFinal=moment(fSelect.final).format("YYYY-MM-DD");
        const dataInfo = qs.stringify({
            'usuario': id,
            'tipo':usuTipo,
            'fini':fInicio+'T00:00:00',
            'ffin':fFinal+'T23:59:59',
            'idDispositivo': 'csdq21weddewde' 
            });            
        function respuesta (aux) 
        {   if (aux[0].MuniId>=0) 
            {   console.log(aux);  
                setListMuni(aux);
            }                                                           
        }
        llamaApiCargaToken(dataInfo, auxU, respuesta, ErrorGeneral, setEspera, history, source);
    }

    function muniEspecial()
    {   const auxU=`proyecto-edo-muni-diferencia`;            
        const dataInfo = qs.stringify({                        
            'Edo':15,
            'Usuario':id,
            'idDispositivo': 'csdq21weddewde' 
            });            
        function respuesta (aux) 
        {   if (aux[0].muniId>=0) 
            {   console.log(aux);  
                setListMunEsp(aux);
            }                                                           
        }
        llamaApiCargaToken(dataInfo, auxU, respuesta, ErrorGeneral, setEspera, history, source);
    }

    const generarLista = e =>{   
        e.preventDefault()    
        setActualizar(!actualizar)
       // setAuxBandera(1)
    }

    const tabla=<Box display={'flex'} flexDirection="column" >
               {/*  <Box pb={1}>
                    <IconButton color="primary"  aria-label="add circle" 
                        component="span" onClick={generarLista}>
                        <Tooltip title="Buscar">
                            <SearchIcon/>
                        </Tooltip>
                    </IconButton>  
                    <IntervaloSelect fSelect={fSelect}  setFSelect={setFSelect} />
                </Box>   */}              
                <Box>
                    <TablaMunicipio muni={listMuni} listMunEsp={listMunEsp} />
                </Box>
                </Box>
    const contenido = listMuni.length!==0 && listMunEsp.length!==0 && !espera? (tabla) :  (<Box pt={1} sx={{width:'100%'}}><LinearProgress/> </Box>);
    return (
    <Box display="flex" flexDirection="row" 
        style={{ marginLeft:'1rem'}}
    >  
        {contenido}
    </Box>
    )
}
 