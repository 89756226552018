import React, {useState, useEffect} from 'react';
import {Grid,Select,InputLabel,MenuItem} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from "react-router-dom";
import axios from 'axios';
import qs from 'qs';
import ModalInformativo from '../generales/ModalInformativo';
import {llamadaApiToken,ErrorGeneral } from '../funciones/ClienteAxios';
const useStyles = makeStyles((theme) => ({
    root: {
     width: '100%',
      margin: {
        margin: theme.spacing(1),
      },
    },

    formSelect: {
      width:'12em'
    },
    formObs:{
        marginTop:'1.7em',
        width:'40em',
        height:'8em'
    },
  }));


export default function NuevaIncidencia({titulo,modalAbierto,setModalAbierto,seleccionado}){
  const classes = useStyles();  
  const history = useHistory();
  const source = axios.CancelToken.source(); 
  const Dispvo =localStorage.getItem('Dispvo') ;    
  const auxDispV =Dispvo && Dispvo.length >= 12 ? Dispvo:'w8rf51v21dsd2cs'
  const [listaSeccion, setListSeccion] = useState([])
  const [listaCasillas, setListaCasillas] = useState([])
  const [seccion,setSeccion] = useState(seleccionado.IdSeccion);
  const registro ={ Casilla: parseInt(seleccionado.CasillaId),
                    Descr: seleccionado.Descr,
                    Nota: seleccionado.Nota,}
  const {Casilla,Descr,Nota} = registro

  useEffect(()=>{                 
    getSecciones()
    return ()=> {
      source.cancel();
  }
  },[seleccionado]);

  const getSecciones = () => {            
    const data = qs.stringify({                
        idDispositivo: auxDispV,
    });
    const url = "incidencia-web-list-secc";
    function respuesta(aux) {
      if (aux)
      { const auxSecciones=aux;     
        let arreSecc=[], variable=0;
        auxSecciones.forEach((seccion) => {
          if (variable!==seccion.SeccionId)
          {
            variable=seccion.SeccionId;                        
            arreSecc.push({ value : variable, label :variable })                                               
          }                                        
        })
        setListSeccion(arreSecc);
        setSeccion(seleccionado.IdSeccion)
        const dato= auxSecciones.filter(  (registro) => registro.SeccionId === seleccionado.IdSeccion )                          
        let arreCasilla=[];                      
        dato.forEach((seccion) => 
        {            
          arreCasilla.push({ value : seccion.CasillaId, label : seccion.TipoNom })      
                                                                                                  
        })
        setListaCasillas(arreCasilla);
      }       
    }                        
    llamadaApiToken(data, url, respuesta,ErrorGeneral,history,source)                     
  }; 

  return (
  <ModalInformativo
    setModalAbierto={setModalAbierto} modalAbierto={modalAbierto}
    titulo={titulo} tamanio={'sm'} >
    <form className={classes.root} noValidate autoComplete="off"> 
      <Grid container spacing={1} >
        {listaSeccion.length!==0 ? 
          <Grid item xs={6}>
            <InputLabel id="seccion">Sección</InputLabel>
            <Select style={{width:'11em',marginBottom:'.3em'}}
              label="Sección" id="Sección" name="seccion"
              value={seccion} disabled
            >
              {listaSeccion.map((secc, index) => (
                <MenuItem value={secc.value} key={secc.value}>
                  <em>{secc.label}</em>
                </MenuItem>
              ))}
            </Select>
          </Grid> :null
        }
        {listaCasillas.length!==0 ? 
        <Grid item xs={2}>
          <InputLabel id="casilla">Casilla</InputLabel>
          <Select style={{width:'11em',marginBottom:'.3em'}}
            label="Casilla" id="Casilla" name="Casilla"
            value={Casilla} disabled
          >
            {listaCasillas.map((casilla, index) => (
              <MenuItem value={casilla.value} key={casilla.value}>
                <em>{casilla.label}</em>
              </MenuItem>
            ))}
          </Select>
        </Grid> :null
        }
      </Grid>
      <textarea className={classes.formObs} placeholder="Descripción"
        value={Descr} disabled id={'Descr'}
        multiline rows={2} maxRows={4}/>
      <textarea className={classes.formObs} placeholder="Nota"
        value={Nota} disabled id={'Nota'}
        multiline rows={2} maxRows={4}/>
      </form>
    </ModalInformativo>
    )
}