import React,{useState,useEffect} from 'react';
import {Table,TableBody,TableCell,TableContainer, IconButton,Tooltip,
        TableHead,TableRow,Card,TextField,Box} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import SaveIcon from '@material-ui/icons/Save';
import RefreshIcon from '@material-ui/icons/Refresh';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import NuevaIncidencia from './NuevaIncidencia';
import { useHistory } from "react-router-dom";
import XLSX from 'xlsx';
import moment from 'moment';
import axios from 'axios';
import qs from 'qs';

const useStyles = makeStyles({
    root: {
      width: '100%',
    },
    estiloCard:{
        width:'100%',
        minWidth:'550px',
        maxWidth: '100%',
        boxShadow:'0 0.5em 1em -0.125em rgb(10 10 10 / 10%), 0 0 0 1px rgb(10 10 10 / 2%)',
        color:'#4a4a4a',
        display:'block',
        padding:'1rem',
        minHeight: 150,
        marginRight:'1rem'
    },
    formControl:{
        boxSizing:'border-box',
        fontSize:'1rem',
        position:'relative',
        marginBottom:'1em',
        width:'70%'
    },
    tablaContainer: {
        minWidth: '280px',
        maxWidth:'100%',
        minHeight: 280,
        //minWidth:'100%',
      },
    totalEstilo:{
        fontSize:'1.25rem',
        fontWeight:'bold'
    },
    tituloEstilo:{
        paddingBottom:'.25rem',
        fontWeight:'bold'
    },
    tableRow: {
      "&$selected, &$selected:hover": {
        backgroundColor:  process.env.REACT_APP_Color_Second
      }
    },
    tableCell: {
      "$selected &": {
        color: "white"
      }
    },
    hover: {},
    selected: {}
  });


export default function InciTablaTipo({listaTp,setResTipo,actualizar,setActualizar,setSelec,setObjSelec}){
    const alturaCont = window.innerHeight<937 ? window.innerHeight-480 : window.innerHeight-620
    let auxArreglo=[];
    const fondo=process.env.REACT_APP_Fondo_Color
    const history = useHistory();
    const classes = useStyles();
    const [listaTipo, setListaTipo] = useState(listaTp) 
    const [tipo, setTipo] = useState({nombre:""})
    const [idSelec,setIdSelec] = useState(-1)
    const [modalNvo, setModalNvo] = useState(false);
	const {nombre} = tipo;
    

    useEffect(()=>{
        setListaTipo(listaTp)
    },[listaTp])
    
    function abrirModalNuevo(){
        setModalNvo(!modalNvo)
    }

    const exportarArch = () => {
        let today = new Date();                    
        let fec = moment(today);
        const ws = XLSX.utils.aoa_to_sheet(auxArreglo);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "Reporte");
        XLSX.writeFile(wb, fec.format("YYMMDDHHmmss")+" Totales de incidencias "+listaTipo.length +".xlsx")
    };

   function getResSel(numTipo){
        const source = axios.CancelToken.source();             
        async function getResultados()  {                                                         
            let auxU=process.env.REACT_APP_LINK +`/incidencia-web-list-tipo`;               
            let token =localStorage.getItem('token20') ;
            let usuid =localStorage.getItem('UsuId') ;                  
            let dataInfo = qs.stringify({                      
                'usuario':usuid,                      
                'tipo':numTipo,
                'idDispositivo':'89wuuidsddsfe'      
                });
            let config = {
                url:auxU ,
                method: 'POST',
                headers: { 'access-token' : token, 'Content-Type': 'application/x-www-form-urlencoded' },
                data: dataInfo,
                cancelToken: source.token
            };
            let aux ={};
            await axios(config)
            .then(function (response) {
                aux=  response.data;                                                                                                                  
                   if (aux) 
                   {//console.log(aux);                       
                    setResTipo(aux);
                   }else{
                       setResTipo([])
                   }              
            })
            .catch(function (error) {                
                console.log(error);                
                if ( ( error && error.response  && error.response.data && error.response.data.error && error.response.data.error.name)
                     && ( error.response.data.error.name === "JsonWebTokenError" || error.response.data.error.name === "TokenExpiredError" )) 
                {   localStorage.clear();                    
                    history.push("/");                                          
                }
                if ( error && error.response  && error.response.data && error.response.data.mensaje 
                    && error.response.data.mensaje === "Datos Incorrectos") 
                {
                    console.log('sin datos' );                    
                }
            });                                  
        };
        getResultados();
    }


    function selecFila(tipo){                
        setIdSelec(tipo.TipoId);
        getResSel(tipo.TipoId)
        setSelec(tipo.TipoId)
        setObjSelec(tipo);
    }

    function filtro(){        
        let auxCadena=nombre.trim();   
        auxArreglo=[]                  
        let info=listaTipo.filter((dat)=>dat.TipoNom.toUpperCase().search(auxCadena.toUpperCase() ) !== -1)          
        return generarTabla(info);
    }

    const onChange = e =>
    {         
        let expNopermitida = new RegExp('[0-9._:$!%-({})/*|]');
        let expMenos = new RegExp('-');
        let expMas = new RegExp('[+]');     
        let expCadena = new RegExp('[A-Za-z]');
        if ((expCadena.test(e.target.value) && !expNopermitida.test(e.target.value) && !expMenos.test(e.target.value) &&!expMas.test(e.target.value))
            ||e.target.value===' '||e.target.value==='') 
        {
            setTipo({
                ...tipo, 
                [e.target.name] : e.target.value  
            })
        }  
    };


    function sinDatos(){
        return<TableContainer className={classes.tablaContainer} id="tablaPromotor" style={{height:150}}>
        <Table stickyHeader aria-label="sticky table">
            <TableHead>    
                <TableRow>            
                    <TableCell padding="none" 
                        style={{backgroundColor:fondo,color:'white', borderBottom:"1px solid "+fondo
                                ,width:2.5+'rem',paddingLeft:'.2em'}}>
                        <b>Total</b>
                    </TableCell>     
                    <TableCell padding="none" style={{backgroundColor:fondo,color:'white', borderBottom:"1px solid "+fondo}}>
                        <b>Tipo</b>
                    </TableCell>            
                </TableRow>                        
            </TableHead>  
            <TableBody>                                                 
                <TableRow>   
                    <TableCell className={classes.tableCell}padding='none' align="center">
                        {listaTipo.length}
                    </TableCell>                               
                    <TableCell className={classes.tableCell} padding='none' align="left"  style={{fontSize:15}}>
                        Sin Resultados Disponibles
                    </TableCell>                                                    
                </TableRow>                                             
            </TableBody>
        </Table>
    </TableContainer>   
    }
    
    function generarTabla(lista) {
        let numRenglon=0;
        let auxi2=["#","Total","Tipo"];
        auxArreglo.push(auxi2);
        let tot= Object.keys(lista).length;
        if(tot===0){
            let auxi3=["","Sin","Tipos"]
            auxArreglo.push(auxi3);            
        } 
        return(
        <TableContainer className={classes.tablaContainer} id="tablaPromotor" style={{height:alturaCont-100}}>
        <Table stickyHeader aria-label="sticky table">
            <TableHead >    
                <TableRow>            
                    <TableCell padding="none" 
                        style={{backgroundColor:fondo,color:'white', borderBottom:"1px solid "+fondo
                                ,width:2.5+'rem',paddingLeft:'.2em'}}>
                        <b>Total</b>
                    </TableCell>     
                    <TableCell padding="none" style={{backgroundColor:fondo,color:'white', borderBottom:"1px solid "+fondo}}>
                        <b>Tipo</b>
                    </TableCell>            
                </TableRow>                        
            </TableHead>  
            <TableBody>                                  
            {lista.map((incidencia,index) => { 
                numRenglon++;  
                let auxi=[index+1,incidencia.Total,incidencia.TipoNom]
                auxArreglo.push(auxi);                                                                                                          
                return (                  
                <TableRow className={classes.tableRow} selected={ idSelec === incidencia.TipoId} 
                    classes={{ hover: classes.hover, selected: classes.selected }} 
                    key={numRenglon} onClick={ () => selecFila(incidencia) }  
                >   
                    <TableCell className={classes.tableCell}padding='none' align="center">
                        {incidencia.Total}
                    </TableCell>                               
                    <TableCell className={classes.tableCell} padding='none' align="left"  style={{fontSize:15,fontWeight:500}}>
                        {incidencia.TipoNom}
                    </TableCell>                                                    
                </TableRow>              
                );                        
            })}                                                
            </TableBody>
        </Table>
        </TableContainer>
        );
        }        

    const actualizarDat=e=>{
        e.preventDefault();
        setActualizar(actualizar+1)
    }
    const modNvo = modalNvo ? <NuevaIncidencia titulo={"Nueva Incidencia"} modalAbierto={modalNvo} setModalAbierto={setModalNvo}/> : null;
    const contenido = tipo? (filtro()):(generarTabla(listaTipo));
    const tabla1= listaTipo.length!==0?(contenido):(sinDatos())
    return (
    <Box display={'flex'} style={{marginTop:1+'rem',width:'45%'}}>
    <Card className={classes.estiloCard} style={{height: alturaCont-10}}>     
        <Box display="flex" flexDirection="row">
            <TextField className={classes.formControl}
                name="nombre" placeholder="Tipo Incidencia"
                value={nombre} onChange={onChange}/>
            {listaTipo.length!==0 ? 
                <IconButton  onClick={()=>{}} style={{marginLeft:'.3em',marginTop:'.5em',width:'20px', height:'20px'}}>
                    <Tooltip title="Exportar Incidencias">
                        <SaveIcon color="primary"/>
                    </Tooltip>
                </IconButton>
            : null}
            <IconButton  onClick={actualizarDat} style={{marginLeft:'.3em',marginTop:'.5em',width:'20px', height:'20px'}}>  
                <Tooltip title="Recuperar Tipos">
                    <RefreshIcon color="primary"/>
                </Tooltip>
            </IconButton>
            {listaTipo.length===0 ? 
            <IconButton onClick={abrirModalNuevo}  style={{marginLeft:'.3em',marginTop:'.5em',width:'20px', height:'20px'}}>
                <Tooltip title="Nueva Incidencia">
                    <AddCircleOutlineIcon color="primary"/>
                </Tooltip>
            </IconButton>
            :null}
        </Box>                   	            
        {tabla1} 
    </Card>
    {modNvo}
    </Box>     
  );
}