import {Dialog, DialogContent, DialogTitle, IconButton, Typography} from "@material-ui/core";
import {useEffect, useState} from "react";
import Mapa from "./Mapa";
import {generaCoordenadaDinam} from "../funciones/Mapas/FormatoCoord";
import moment from 'moment';
import CloseIcon from "@material-ui/icons/Close";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    closeIcon: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1)
    }
}));

const ModalMapa = ({abrirModal, setAbrirModal, datos}) => {

    let altuTamMax = 600;
    const classes = useStyles();
    const [coordPerfiles, setCoordPerfiles] = useState([])
    const libreriaG = [process.env.REACT_APP_GOOGLE_LIBRARIE];

    const handleClose = () => {
        setAbrirModal(false)
    };

    useEffect(() => {
        let coordenadas = generaCoordenadaDinam(datos.Lat, datos.Lon)
        let fech = moment.utc(datos.FReg).local(true).format("DD/MM/YYYY")
        setCoordPerfiles({coordenada: coordenadas, nombre: datos.UsrNom, fecha: fech,dir:datos.Direcc})
    }, [datos]);

    const tamanioH = window.innerHeight < altuTamMax ? window.innerHeight - 150 : window.innerHeight - 250;

    return (
    <Dialog aria-labelledby="responsive-dialog-title"
        open={abrirModal} onClose={handleClose} fullWidth="md"        
    >
        <DialogTitle id="titulo" align={'center'} onClose={handleClose}>
            <Typography variant={'h6'} color={'primary'} >
                {coordPerfiles.length !== 0 ? `${coordPerfiles.nombre} ${coordPerfiles.fecha}` : "Mapa"}
            </Typography>
            <IconButton aria-label="close" className={classes.closeIcon} onClick={handleClose}>
                <CloseIcon/>
            </IconButton>
            <Typography color="textPrimary" style={{ fontWeight:600,fontSize:13,marginBottom:0,paddingBottom:0}}>
            {coordPerfiles?.dir}
          </Typography> 
        </DialogTitle>
        <DialogContent style={{padding: 0, overflowY: 'hidden'}}>
            {coordPerfiles.length !== 0 ? 
                <Mapa zoom={15}
                    tamanio={{height: tamanioH, width: "100%"}}
                    libreriaG={libreriaG} coordPerfiles={coordPerfiles}
                /> 
            : null}
        </DialogContent>
    </Dialog>);
};

export default ModalMapa;
