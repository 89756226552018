import React,{useState} from 'react';
import {Box,LinearProgress} from "@material-ui/core";
import CardTotal from './PrepCardTotal';
import GraficaMunicipio from './PrepGrafMuni';
import TablaVotosGeneral from './PrepTabVotoGen';
import TablaSeccion from './PrepTablaSecc';
import GraficaSeccion from './PrepGraficaSeccion';
export default function PanelVoto({datos,setActualizar,actualizar,procSel}){
    const [listaXMunicipio,setListaXMunicipio]=  useState([]);      
    const [listaXSeccion,setListaXSeccion]=  useState([]);    
    const [listaXSecCasilla,setListaXSecCasilla]=  useState([]);     
    const [tituloSeccion,setTituloSeccion]=  useState("");       
    const [tituloMunicipio,setTituloMunicipio]=  useState("");  
    const [municipioSelec,setMunicipioSelec]=  useState([]); 
    const [espera,setEspera] = useState(false) 
    const [mostrarTab2,setMostrarTab2] = useState(false)  

    const graf = listaXMunicipio.length!==0 ? <GraficaMunicipio valor={listaXMunicipio} descripcion="Votación"/> : null;
    const validaGraf = espera ?  <Box sx={{width:'100%'}} style={{marginTop:'1em'}}><LinearProgress/></Box> : graf;
    return(
    <Box display="flex" flexDirection="column" style={{marginTop:'1em'}}>
        <Box display="flex" flexDirection="column">
            <Box display="flex" flexDirection="row">
                {datos.length!==0 ?<CardTotal datos={municipioSelec.length===0 ? datos[0] : municipioSelec}/>:null}
                <TablaVotosGeneral lista={datos}  setListaXMunicipio={setListaXMunicipio} setActualizar={setActualizar} 
                    actualizar={actualizar}  setListaXSeccion={setListaXSeccion} setTituloMunicipio={setTituloMunicipio}
                    procesoSeleccionado={procSel} setMunicipioSeleccionado={setMunicipioSelec} 
                    setEspera={setEspera} setMostrarTab2={setMostrarTab2}
                />
                {validaGraf}
            </Box>
            <Box display="flex" flexDirection="row" mt={2}>
                {mostrarTab2 ? 
                    <TablaSeccion datos={listaXSeccion}
                        setListaXSecCasilla={setListaXSecCasilla} municipio={municipioSelec}
                        setTituloSeccion={setTituloSeccion} tituloMunicipio={tituloMunicipio} 
                        setActualizar={setActualizar} actualizar={actualizar} procesoSeleccionado={procSel}
                    />
                :null}
                {listaXSecCasilla.length!==0 ?
                    <GraficaSeccion valor={listaXSecCasilla} tituloSeccion={tituloSeccion} 
                        descripcion={tituloSeccion?("Votos Secc. " +tituloSeccion):("Votos")}/>
                :null}
            </Box>
        </Box>
    </Box>
    )
}