import React,{useState,useEffect} from 'react';
import {Box,LinearProgress,} from "@material-ui/core";
import { authUser } from '../funciones/AuthUser'
import { useHistory } from "react-router-dom";
import InciPanel from './InciPanel';
//import {AuthContext} from '../context/AuthContext'
//import {GeneralesContext} from '../context/GeneralesContext'
import {llamaApiCargaToken,ErrorGeneral,llamadaApiToken } from '../funciones/ClienteAxios';
import axios from 'axios';
import qs from 'qs';

export default function Incidencia(){
    const history = useHistory();
    const source = axios.CancelToken.source();
    const id =localStorage.getItem('UsuId') ;            
    const Dispvo =localStorage.getItem('Dispvo') ;    
    const auxDispV =Dispvo && Dispvo.length >= 12 ? Dispvo:'w8rf51v21dsd2cs'      
    const [listInci,setListInci] = useState([])
    const [actualizar,setActualizar] = useState(0);
    const [espera,setEspera]=useState(false);
    
    useEffect(()=>{
        
    const autenticado = authUser();
        
        if(!autenticado){
            localStorage.clear();
            history.push("/");
            //guardarUsuario([])
        }else{
            getIncidenciaList()    
            //incidenciaRedisClear()        
            /*if(!nivel||nivel==="3"||nivel==="5"||nivel===" "){
                localStorage.clear();            
                history.push("/");    
                guardarUsuario([])
            }else{
                if (usuario.length===0) 
                {
                    guardarUsuario({Nombre:usu,nivel:nivel}); 
                   // guardarGenerales({...generales,Titulo:'Coordinadores'})                                         
                }
                getCoord()
            }*/
        }
        return ()=> {
            source.cancel();
        }  
    },[actualizar])

    const getIncidenciaList = () => {            
        const data = qs.stringify({
            usuario: id,                
            idDispositivo: auxDispV,
        });
        const url = "incidencia-web-list-gral";
        function respuesta(aux) {
            if (aux[0].Id!== -1)
            {   setListInci(aux)
            }
            else
            {   setListInci([])
            }    
        }
        llamaApiCargaToken(data, url, respuesta,ErrorGeneral,setEspera,history,source);
    };

   /*  const incidenciaRedisClear = () => {            
        const data = qs.stringify({                         
            idDispositivo: auxDispV,
        });
        const url = "incidencia-redis-clear";
        function respuesta(aux) {
            console.log("se limpiio");
        }
        llamadaApiToken(data, url, respuesta,ErrorGeneral,history,source);
    }; */

    const contenido = listInci.length!==0 ? <InciPanel listTipo={listInci} actualizar={actualizar} setActualizar={setActualizar}  /> 
                                            : <h2>Sin Incidencias disponibles</h2>
    const validaCarga = espera ? (<Box style={{marginTop:'2rem',marginLeft:'1em',width:'600px'}} sx={{width:'100%'}}>
                                <LinearProgress/> </Box>) : contenido;
    return(
    <Box style={{width:'97%',marginLeft:'.5rem'}}>
        {validaCarga}
    </Box>)
}