import React,{useState} from 'react';
import {Box} from "@material-ui/core";
import InciGrafica from './InciGrafica';
import InciTablaTipo from './InciTablaTipo';
import InciTablaRes from './InciTablaResultados';
export default function InciPanel({listTipo,setActualizar,actualizar}){
    const [resTipo,setResTipo] = useState([])
    const [selec,setSelec] = useState(-1)
    const [objSelec,setObjSelec] = useState([])
    return(
    <Box display="flex" flexDirection="column">
        <Box display="flex" flexDirection="row" style={{marginBottom:'1em'}}>
            <InciTablaTipo listaTp={listTipo} setResTipo={setResTipo} actualizar={actualizar} 
                setActualizar={setActualizar} setSelec={setSelec} setObjSelec={setObjSelec} 
            />
            <InciGrafica valor={listTipo} descripcion="Gráfica de Incidencias" />
        </Box>
        <Box>
            {selec > 0 ?
                <InciTablaRes lista={resTipo} objSelec={objSelec} setActualizar={setActualizar} actualizar={actualizar} />
            :null}
        </Box>
    </Box>
    )
}