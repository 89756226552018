import React,{useState,useEffect} from 'react';
import {Box,IconButton,Tooltip,TextField, LinearProgress,
    //Select,MenuItem,InputLabel,FormControl
    Breadcrumbs,Typography} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import SearchIcon from '@material-ui/icons/Search';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import axios from 'axios';
import qs from 'qs';
import { useHistory } from "react-router-dom";
import IntervaloSelect from './IntervaloTabla';
import TablaSeccion from './TablaXSeccion'
import CardPerfil  from './CardPerfil';
import ContenedorMapa from './ContenedorMapa';
import MapaPerfil from '../MapaPerfil/Index';
const SeccInicio = ({listMuni}) => {
  let history = useHistory();
  const nvl= parseInt(localStorage.getItem('Tipo'));     
  const [fSelect, setFSelect] = useState({inicio:new Date(),final:new Date()});
  const[actualizar,setActualizar]=useState(true);
  const[listSecc,setListSecc]=useState([]);
  const[listUnSecc,setListUnSecc]=useState([]);
  const [espera,setEspera]=React.useState(false);
  const [muniSelect,setMuniSelect]=React.useState([]);
  const [seccionSelect,setSeccionSelect]=React.useState([]);
  const [auxBandera,setAuxBandera]=React.useState(0);
  const[totalPerfil,setTotalPerfil]= useState([]);
  const [arregloColor,setArregloColor] = useState([])   
  const [infoNvlCoord,setInfoNvlCoord] = useState([])   
  const [munSvgSelect,setMunSvgSelect]=useState(0)
  const [seccGps,setSeccGps] = useState([])   
  const [coordPerfiles,setCoordPerfiles] = useState([])  
  const [auxMapVar,setAuxMapVar] = useState([])   
     

  const generarLista = e =>{   
    e.preventDefault()
    setTotalPerfil([])
    setArregloColor([])
    setActualizar(!actualizar)
    setAuxBandera(1)
    setSeccGps([])

    setCoordPerfiles([])
  }
  useEffect(()=>{
    if (listMuni.length===1) 
    {
      setMuniSelect(listMuni[0])
      if (nvl===6) {
        llamadaUnSecc()  
      }
      else
      {
        llamadaSecc(listMuni[0].Id)
      }
    }

  },[])

  async function llamadaSecc(auxMuni)  {       
    setEspera(true) ;
    const source = axios.CancelToken.source();
    const usuID = localStorage.getItem('UsuId');      
    let auxU=process.env.REACT_APP_LINK +`/lugar-seccion`;               
    let token =localStorage.getItem('token20') ;               
    let dataInfo = qs.stringify({                
        'usuario':usuID,              
        'municipio':auxMuni,                
        'idDispositivo':'w8rf51v21dsd2cs',
        });
    let config = {
        url:auxU ,
        method: 'POST',
        headers: { 'access-token' : token, 'Content-Type': 'application/x-www-form-urlencoded' },
        data: dataInfo,
        cancelToken: source.token
    };
    let aux ={};           
    await axios(config)
    .then(function (response) {                
      aux=  response.data;                           
      if (aux[0].Id != -1) 
      {
        setListSecc(aux)          
      }                                                    
    })
    .catch(function (error) {                
      console.log(error);        
      if( error && error.response && error.response.data && error.response.data.mensaje==="Datos Incorrectos")
      {
        console.log('datos incorrectos' );  
      }else
      {
        if ( ( error && error.response && error.response.data && error.response.data.error.name==="JsonWebTokenError" )||
            ( error && error.response && error.response.data && error.response.data.error.name==="TokenExpiredError" ) ) 
        {
          console.log('loguearse de nuevo');
          localStorage.clear();                      
          history.push("/");                              
        } 
      }
    });              
    setEspera(false)  ;
  }; 

  async function llamadaUnSecc( )  {       
    setEspera(true) ;
    const source = axios.CancelToken.source();
    const usuID = localStorage.getItem('UsuId');
    
    let auxU=process.env.REACT_APP_LINK +`/lugar-seccion-list`
    let token =localStorage.getItem('token20') ;               
    let dataInfo = qs.stringify({                
        'usuario':usuID,                                      
        'idDispositivo':'w8rf51v21dsd2cs',
        });
    let config = {
        url:auxU ,
        method: 'POST',
        headers: { 'access-token' : token, 'Content-Type': 'application/x-www-form-urlencoded' },
        data: dataInfo,
        cancelToken: source.token
    };
    let aux ={};           
    await axios(config)
    .then(function (response) {                
      aux=  response.data;                           
      if (aux[0].Id != -1 && aux.length === 1) 
      {          
        setSeccionSelect(aux[0])
        setListUnSecc(aux)
        //console.log(aux);        
      }                                          
    })
    .catch(function (error) {                
      console.log(error);        
      if( error && error.response && error.response.data && error.response.data.mensaje==="Datos Incorrectos")
      {
        console.log('datos incorrectos' );  
      }else
      {
        if ( ( error && error.response && error.response.data && error.response.data.error.name==="JsonWebTokenError" )||
            ( error && error.response && error.response.data && error.response.data.error.name==="TokenExpiredError" ) ) 
        {
          console.log('loguearse de nuevo');
          localStorage.clear();                      
          history.push("/");                              
        } 
      }
    });              
    setEspera(false)  ;
  }; 

  const guardarMuni=(e)=>{
   // console.log(e);
    if (e!== null && e!== undefined) 
    {
      setMuniSelect(e)    
      llamadaSecc(e.Id)
     // console.log('Con valores');            
    }else
    {
      setMuniSelect([])    
      //console.log("Sin valores")
    }
    setAuxBandera(0)
    setSeccionSelect([]) 
    setInfoNvlCoord([]) 
  }

  const guardarSecc=(e)=>{
    //console.log(e);
    if (e!== null && e!== undefined) 
    { setSeccionSelect(e)                
    }
    else
    { setSeccionSelect([])          
    }
    setAuxBandera(0)
    setInfoNvlCoord([]) 
  }

  const letraTipo=(auxNvl,auxNvlId,auxUser)=>{
    //console.log(auxNvl);
    return(
    <span   >
      <Typography color="primary" variant="body2" id={auxUser+auxNvlId} 
        style={{fontSize:.8+'rem',fontWeight:600}}
      >
        {auxUser}
      </Typography>
      <Typography color="textPrimary" variant="body2" id={auxNvl+auxNvlId} 
        style={{fontSize:.7+'rem',textAlign:"center"}}
      >
        {auxNvl}
      </Typography>
    </span>
    )
  }
  const letraTipo2=(auxNvl,auxId,auxUser)=>{
    //console.log(auxNvl);
    return(
    <span   >
      <Typography color="primary" variant="body2" id={auxId} 
        style={{fontSize:.8+'rem',fontWeight:600}}
      >
        {auxUser}
      </Typography>
      <Typography color="textPrimary" variant="body2" id={auxId} 
        style={{fontSize:.7+'rem',textAlign:"center"}}
      >
        {auxNvl}
      </Typography>
    </span>
    )
  }

  const nvlInfo=(lista)=>{
    //console.log(lista);
    return(
    <Box mt={1} mb={2}>
    <Breadcrumbs separator={<NavigateNextIcon  />} aria-label="breadcrumb"  >
      {lista.CZId>0 ? letraTipo(lista.CZNom,lista.CZId,lista.CZUser) : null}
      {lista.CRId>0 ? letraTipo(lista.CRNom,lista.CRId,lista.CRUser) : null}
      {lista.CMId>0 ? letraTipo(lista.CMNom,lista.CMId,lista.CMUser) : null}    
      {lista.CSId>0 ? letraTipo(lista.CSNom,lista.CSId,lista.CSUser) : null} 
      {lista.PrId>0 ? letraTipo(lista.PrNom,lista.PrId,lista.PrUser) : null}            
      {lista.SeccionValor && lista.SeccionValor.length !== 0 ? letraTipo2(lista.SeccionTitulo,"meta-secc2",lista.SeccionValor) : null}
    </Breadcrumbs>
    </Box>
    )
  }

  return(
  <div>
    <Box display={'flex'} pb={1}>
      <Box >
        <Tooltip title="Buscar">
        <IconButton color="primary"  aria-label="add circle" 
          component="span" onClick={generarLista}
        >          
          <SearchIcon/>          
        </IconButton>                                                                                                                              
        </Tooltip>
      </Box>    
      <Box>
        <IntervaloSelect fSelect={fSelect}  setFSelect={setFSelect} />
      </Box>
      <Box pl={1} pt={1}>
        {listMuni.length!==0 ?
          nvl ===6 ? 
          <Autocomplete
            id="municipio-demo" size='small'   options={listMuni} value={muniSelect}
            disabled //noOptionsText={"Sin coincidencias"}         
            getOptionLabel={(option) => option.Nom} style={{ width: 22+'rem' }}            
            renderInput={(params) => (
              <TextField {...params}  label="Municipio" variant="outlined" />
            )}
          />
          :
          <Autocomplete
            id="municipio-demo" size='small'   options={listMuni} value={muniSelect}
            onChange={(e,option)=>guardarMuni(option)} noOptionsText={"Sin coincidencias"}         
            getOptionLabel={(option) => option.Nom} style={{ width: 22+'rem' }}            
            renderInput={(params) => (
              <TextField {...params} 
                label="Municipio" variant="outlined" 
              />
            )}
          />        
        :<LinearProgress/>
        }
      </Box>  
      <Box pl={1} pt={1}>
        {muniSelect.length!== 0 && listSecc.length !==0 && !espera ?
          <Autocomplete
            id="Seccion-demo" size='small' options={listSecc}   
            onChange={(e,option)=>guardarSecc(option)}            
            getOptionLabel={(option) => option.Numero.toString()}
            style={{ width: 10+'rem' }} noOptionsText={"Sin coincidencias"}
            renderInput={(params) => (
              <TextField {...params} 
                label="Sección" variant="outlined" 
              />
            )}
          />
        : nvl===6 ? null : <LinearProgress/> 
        }

        {nvl===6 &&muniSelect.length!== 0  && listUnSecc.length>0  && !espera ?
        <span>    
          <Autocomplete
            id="Seccion-demo" size='small'options={listUnSecc} disabled                         
            getOptionLabel={(option) => option.Numero.toString()}
            style={{ width: 10+'rem' }}  value={seccionSelect }
            noOptionsText={"Sin coincidencias"}
            renderInput={(params) => (
              <TextField {...params} 
                label="Sección" variant="outlined" 
              />
            )}
          />
        </span>
        :null
        }

      </Box>   
    </Box> 
    {infoNvlCoord.length > 0 ? nvlInfo(infoNvlCoord[0]) :null}
    <Box>
      {totalPerfil.length !== 0  && auxBandera === 1 ? 
        <CardPerfil valor={totalPerfil} />
      :null}
    </Box>  
    {  seccionSelect.length !== 0 && auxBandera === 1 ? 
      <Box display={"flex"} row >
        <TablaSeccion fSelect={fSelect} actualizar={actualizar} 
          seccionSelect={seccionSelect} setTotalPerfil={setTotalPerfil}
          setArregloColor={setArregloColor} muniSelect={muniSelect.Id}
          setInfoNvlCoord={setInfoNvlCoord} setActualizar={setActualizar}
          setSeccGps={setSeccGps} setCoordPerfiles={setCoordPerfiles} 
          setAuxMapVar={setAuxMapVar}
        />
        {/* arregloColor.length !== 0 ?
          <span style={{marginLeft:3+'rem'}} >
            <ContenedorMapa coloreados={arregloColor} setMunSvgSelect={setMunSvgSelect}   />
          </span>
          :null */
        }
         { auxBandera===1 && seccGps.length !== 0 ? 
          <MapaPerfil muniGps={seccGps} zoom={16} nomMuni={muniSelect.Nom} 
            tipo={2} coordPerfiles={coordPerfiles} auxMapVar={auxMapVar} /> 
        : null}
      </Box>
    :null  
    }
  </div>
  );
};

export default SeccInicio;
