import React from 'react';
import { makeStyles } from "@material-ui/core/styles";
import {Card,CardContent,Box} from "@material-ui/core";
import NumberFormat from 'react-number-format';
const useStyles = makeStyles((theme) => ({
    card:{
      height:3.5+'rem',
      width:130,
     // padding:'1.1 rem 0px 1.12rem',
      marginRight:'.5em',
    },
    titulo:{
        //color:'#4a4a4a',
        textAlign:'center',
        marginTop:'-.4rem',
        fontSize:'1rem',
        fontWeight:600
    },
    tituloColor:{

        //color:'white',
        textAlign:'center',
        marginTop:'-.6rem',
        fontSize:'1rem',
        fontWeight:600
    },
    subtitulo:{
        //color:'#4a4a4a',
        textAlign:'center',
        marginTop:'-1.4rem',
        fontSize:'.8rem',
    },
    subtituloCard:{
       // color:'#4a4a4a',
        textAlign:'center',
        marginTop:'.28rem',
        fontSize:'.8rem',
    },
}));


export default function CardPerfil({valor}){
    const classes = useStyles();
    //let auxAGlobal=valor.avance_global;
    //let auxEfe=valor.efectividad;
   /*  React.useEffect(()=>{
        console.log(valor);
    },[]) */
    function generarCards(mensaje,datos,auxColor){
        return( 
        <CardContent>
            <Card className={classes.tituloColor} 
                style={{backgroundColor:auxColor,color:'white'}} 
            >                
                <NumberFormat value={datos} displayType={'text'}  thousandSeparator={true}/>
            </Card>
            <p className={classes.subtituloCard} >
                {mensaje}
            </p>
        </CardContent>
        )
    }

  
    return ( 
        
    <Box display="flex" flexDirection="row" mb={1}  >   
        <Box display="flex" flexDirection="row">                                    
            {/* <Card className={classes.card} style={{width:12+'rem'}}>
                <CardContent >
                    <p className={classes.titulo} >                        
                        <NumberFormat value={valor.Valido+valor.Pendiente+valor.Rechazado} 
                            displayType={'text'}  thousandSeparator={true}
                        />{' / '}
                        <NumberFormat value={valor.Meta} 
                            displayType={'text'}  thousandSeparator={true}
                        />
                    </p>
                    <p className={classes.subtitulo}>
                        Total
                    </p>
                </CardContent>
            </Card>  */}
            <Card className={classes.card}>
                {generarCards('Valido',valor.Valido,'#19A11E')}
            </Card>
            <Card className={classes.card}>
                {generarCards('Pendiente',valor.Pendiente ,'#FBBE06')}
            </Card>
            <Card className={classes.card}>
                {generarCards('Rechazado', valor.Rechazado,'#E91E63')}
            </Card>            
        </Box>   
    </Box>
    );
}
 