import React,{useState} from 'react';
import ModalGeneral from '../generales/Modal';
import {Radio, RadioGroup,FormControl,FormLabel,Grid,FormControlLabel} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
//import { useHistory } from "react-router-dom";
import moment from 'moment';
import axios from 'axios';
import qs from 'qs';


const useStyles = makeStyles((theme) => ({
    root: {
      '& > *': {
        margin: theme.spacing(1),
        width: '20ch',
      }, 
    },
    campos:{
        width: '500px',
        marginBottom: '1em'
      },
  }));

export default function ModalMovilizar({seleccionado,setModalAbierto,modalAbierto,datos,setDatos}){
    //let history = useHistory();
    const classes = useStyles();
    const [espera, setEspera] = useState(false)
    const [aviso, setAviso] = useState(false);
    const [mensaje, setMensaje] = useState("");
    const [tipoAdvertencia, setTipoAdvertencia] = useState("success");
    const [btnDeshabilitado,setBtnDeshabilitado]= useState(false)
    const [moviliza, setMoviliza] = useState("no");
    const [movilizado,setMovilizado] = useState(0)

    let colorCheckR=process.env.REACT_APP_Color_Second  

    const onChangeMoviliza = (event) => {
        event.preventDefault();
        setMoviliza(event.target.value);
        switch(event.target.value){
          case "si":
            setMovilizado(1)
          break;
          case "no":
            setMovilizado(0)
          break;
          default:
            setMovilizado(0)
          break;
        }
      };


    async function movilizar(){
        const source = axios.CancelToken.source();        
        setEspera(true)
        setBtnDeshabilitado(true)
        let id =localStorage.getItem('UsuId') ;
        let auxU=process.env.REACT_APP_LINK +`/promovido-movil-promocion`;               
        let token =localStorage.getItem('token20') ;                
        let dataInfo = qs.stringify({                      
            "usuario":id,
            "promovido":seleccionado.Id,
            "status":movilizado
             });
        let config = {
            url:auxU ,
            method: 'POST',
            headers: { 'access-token' : token, 'Content-Type': 'application/x-www-form-urlencoded' },
            data: dataInfo,
            cancelToken: source.token
        };
        let aux ={};
        await axios(config)
            .then(function (response) {
                aux=  response.data;                                                                                                                  
                   if (aux.respuesta===1) 
                   {                   
                        setMensaje("Movilización registrada con éxito")
                        toast.success("Movilización registrada con éxito", 
                            {  position: toast.POSITION.BOTTOM_RIGHT, autoClose: 3500, containerId:'ModalNvaMovilizacion'} );   
                        setTipoAdvertencia("success")
                        setAviso(true)
                        let info=datos.filter((dat)=>dat.Id !== seleccionado.Id)   
                        let today = new Date();                    
                        let fec = moment(today);
                        let auxi={ 
                                Id:seleccionado.Id ,
                                UserCambio:seleccionado.UserCambio,
                                Moviliza:movilizado,
                                Hora:fec.format("HH:mm"), 
                                Nombre:seleccionado.Nombre, 
                                Tel:seleccionado.Tel, 
                                Cel:seleccionado.Cel
                            }
                        info.push(auxi);   
                        setDatos(info)                                                                                     
                   } else{
                    setMensaje("Ha ocurrido un error, intente más tarde")
                    setTipoAdvertencia("success")
                    setAviso(true)
                   }             
            })
            .catch(function (error) {                
                //console.log(`Mensaje error API: ${JSON.stringify(error.response.data)}`)               
//                console.log(`Error: ${error.response.data} mensaje: ${error.response.data.mensaje}`);
                /*if (error.response.data.error.name==="JsonWebTokenError"||error.response.data.error.name==="TokenExpiredError") 
                {                  
                }
                if (error.response.data.mensaje.trim()==="Datos Incorrectos") 
                {
                    console.log('sin datos' );                 
                }*/
            });
        setEspera(false)
    }

      const onSubmit = e =>{      
		e.preventDefault();
        movilizar();                                                 
    }

    return(
        <ModalGeneral
        titulo={"Movilizar "+ seleccionado.Nombre}
        modalAbierto={modalAbierto}
        setModalAbierto={setModalAbierto}
        mensaje={mensaje}
        tipoAdvertencia={tipoAdvertencia}
        aviso={aviso}
        btnDeshabilitado={btnDeshabilitado}
        setAviso={setAviso}
        tamanio={'md'}
        guardarDatos={onSubmit}
        cargando={espera}>  
        <form className={classes.root} autoComplete="off">         
             <FormControl className={classes.campos}>
              <FormLabel>¿Desea participar Movilizar a {seleccionado.Nombre+"?"}</FormLabel>
              <RadioGroup aria-label="activo" name="Activo"
                defaultValue={moviliza} onChange={onChangeMoviliza}                
              >
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <FormControlLabel value="si" control={<Radio />} label="Sí" style={{color:colorCheckR}} />
                  </Grid>
                  <Grid item xs={1}>
                    <FormControlLabel value="no" control={<Radio />} label="No" style={{color:colorCheckR}} />
                  </Grid>
                </Grid>
              </RadioGroup>
            </FormControl>
        </form>  
        </ModalGeneral>
    )
    

}