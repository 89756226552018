import React from 'react';
import {Table,TableBody,TableCell,TableContainer,TableHead,
    Tooltip,IconButton,Card} from '@material-ui/core';

export const vacia=(key) => <TableCell  align="center" key={key}> </TableCell> 

export const celda=(alinear,estilo,contenido,key,classes)=>{
    return (
    <TableCell align={alinear} key={key} className={classes} 
        padding={'none'} style={estilo} 
    >
      {contenido}
    </TableCell>
    )
}
export const celdaIconoFuncion=(leyenda,funcionModal,childrenIco,registro)=>{//incompleta
    let auxCont=<Tooltip title={leyenda}>
    <IconButton   aria-label="add circle" size="small" onClick={() => funcionModal(registro)} component="span">
      {childrenIco }
    </IconButton>                     
  </Tooltip>
    return(
      celda("center",{width:10+'px'}, auxCont)
    )
}

export const celdaIcono=(leyenda,childrenIco,key,clase)=>{
    let auxCont=<Tooltip title={leyenda}>{childrenIco }</Tooltip>
    return(
      celda("center",{width:10+'px'}, auxCont,key,clase)
    )
}

export const TablaGeneral = (cuerpo,cabeza,estiloCont) => {   
    return (
    <TableContainer className={estiloCont}>
        <Table stickyHeader aria-label="sticky table">
            <TableHead>
                {cabeza}
            </TableHead>
            <TableBody>
                {cuerpo}
            </TableBody>
        </Table>
    </TableContainer>
    );
};

export function celdaCard(auxColorStd,etiqueta ,estilo,alinear)
{  
  let renglon=(
    <TableCell  align={alinear} padding={'none'} style={estilo} >
      <Card style={{color:auxColorStd==="#EBF1F8"? "black":'white', fontWeight:"bold", backgroundColor:auxColorStd}}>
        {etiqueta}
      </Card>
    </TableCell>    )
  return renglon
} 