import React from 'react';
import { makeStyles } from "@material-ui/core/styles";
import {Card,CardContent,Box} from "@material-ui/core";
import NumberFormat from 'react-number-format';
const useStyles = makeStyles((theme) => ({
    card:{
      height:70,
      width:8.5+'rem',
     // padding:'1.1 rem 0px 1.12rem',
      marginRight:'.5em',
    },
    titulo:{
        //color:'#4a4a4a',
        textAlign:'center',
        marginTop:'-.4rem',
        fontSize:'1.2rem',
        fontWeight:600
    },
    tituloColor:{

        //color:'white',
        textAlign:'center',
        marginTop:'-.6rem',
        fontSize:'1.2rem',
        fontWeight:600
    },
    subtitulo:{
        //color:'#4a4a4a',
        textAlign:'center',
        marginTop:'-1.6rem',
        fontSize:'1rem',
    },
    subtituloCard:{
       // color:'#4a4a4a',
        textAlign:'center',
        marginTop:'.28rem',
        fontSize:'1rem',
    },
}));


export default function DatosCard({valor}){
    const classes = useStyles();
    //let auxAGlobal=valor.avance_global;
    //let auxEfe=valor.efectividad;

    function generarCards(mensaje,datos,auxColor){
      return( <CardContent>
            <Card className={classes.tituloColor} style={{backgroundColor:auxColor,color:'white'}} >                
                <NumberFormat value={datos} displayType={'text'}  thousandSeparator={true}/>
            </Card>
            <p className={classes.subtituloCard} >
                {mensaje}
            </p>
        </CardContent>)
    }

    /* function validandoColor(vEfecti) {
        var colorEfecti='';    
        if(vEfecti<60||vEfecti===0){
            colorEfecti= '#FF5733';        
        }
        else{
            if(vEfecti>=60 && vEfecti<80)
            {
                colorEfecti= '#FFC300';               
            }
            else{
                if(vEfecti>=80 )
                {
                    colorEfecti= '#1CC63D';
                }
            }
        }
        
        return(colorEfecti);
    }
     */
    return ( 
        
    <Box display="flex" flexDirection="row"   >   
        <Box display="flex" flexDirection="row">                                    
            <Card className={classes.card} style={{ width:12.8+'rem',margin:0,marginRight:.5+'rem'}}>
                <CardContent style={{margin:0,padding:0}}>
                    <p className={classes.titulo} style={{marginTop:.9+'rem'}}>                        
                        <NumberFormat value={valor.Valido+valor.Pendiente } 
                            displayType={'text'}  thousandSeparator={true}
                        />{' / '}
                        <NumberFormat value={valor.Meta} 
                            displayType={'text'}  thousandSeparator={true}
                        />
                    </p>
                    <p className={classes.subtitulo}>
                        Total
                    </p>
                </CardContent>
            </Card> 
            <Card className={classes.card}>
                {generarCards('Valido',valor.Valido,'#19A11E')}
            </Card>
            <Card className={classes.card}>
                {generarCards('Pendiente',valor.Pendiente ,'#FBBE06')}
            </Card>
            <Card className={classes.card}>
                {generarCards('Rechazado', valor.Rechazado,'#E91E63')}
            </Card>            
        </Box>   
    </Box>
    );
}
 