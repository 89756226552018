import React,{useEffect,useState} from "react";
import Modal from "../generales/ModalInformativo";
import { celda, TablaGeneral,celdaIcono, } from "../generales/TablaGeneral";
import { makeStyles } from "@material-ui/core/styles";
import {Box, TableRow,Card, LinearProgress, Button,IconButton,
  Tooltip,TablePagination} from '@material-ui/core';
//import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord'; 
import { ErrorGeneral,llamaApiCargaToken } from '../funciones/ClienteAxios';
import axios from 'axios';
import qs from 'qs';
import { useHistory } from "react-router-dom";
import XLSX from 'xlsx';
import moment from 'moment';
import SaveIcon from '@material-ui/icons/Save';
import PaginacionTab from '../layout/PaginacionTab'
import { numeroPag } from "../funciones/Paginacion";
//import GraficasSeccion from "./GraficasSeccion";
const useStyles = makeStyles({  
  container: {
    maxHeight: window.innerHeight - 252,
    minHeight: 280,    
    width: "30 rem",
  },
  container2: {
    maxHeight: 550,
  },
  tableRow: {
    "&$selected, &$selected:hover": {
      backgroundColor: process.env.REACT_APP_Color_Second,
    },
  },
  tableCell: {
    "$selected &": {
      color: "white",
    },
  },
  hover: {},
  selected: {},
});

const columnas = [
  {
    id: 0, titulo: "Sección", alinear: "center",
  },
  {
    id: 1, titulo: "Avance. Comprometido", alinear: "center",
  },
  {
    id: 6, titulo: "", alinear: "center",
  },
  {
    id: 2, titulo: "Nombre", alinear: "center",
  } 
  ,
  {
    id: 3, titulo: "Usuario", alinear: "center",
  } 
  ,
  {
    id: 4, titulo: "Teléfono", alinear: "center",
  } ,
  {
    id: 5, titulo: "Avance Estructura", alinear: "center",
  } ,
  {
    id: 7, titulo: "", alinear: "center",
  },
];

const columnasReg = [
  {
    id: 0, titulo: "Municipio", alinear: "center",
  },
  {
    id: 1, titulo: "Avance. Comprometido", alinear: "center",
  },
  {
    id: 6, titulo: "", alinear: "center",
  },
  {
    id: 2, titulo: "Nombre", alinear: "center",
  } 
  ,
  {
    id: 3, titulo: "Usuario", alinear: "center",
  } 
  ,
  {
    id: 4, titulo: "Teléfono", alinear: "center",
  } ,
  {
    id: 5, titulo: "Avance Estructura", alinear: "center",
  } ,
  {
    id: 7, titulo: "", alinear: "center",
  },
];
const columnasZon = [
  {
    id: 0, titulo: "Zona", alinear: "center",
  },
  {
    id: 1, titulo: "Avance. Comprometido", alinear: "center",
  },
  {
    id: 6, titulo: "", alinear: "center",
  },
  {
    id: 2, titulo: "Nombre", alinear: "center",
  } 
  ,
  {
    id: 3, titulo: "Usuario", alinear: "center",
  } 
  ,
  {
    id: 4, titulo: "Teléfono", alinear: "center",
  } ,
  {
    id: 5, titulo: "Avance Estructura", alinear: "center",
  } ,
  {
    id: 7, titulo: "", alinear: "center",
  },
];

export default function ModalSeccionXMun({modalAbierto, setModalAbierto,nomMuni,muniSelect,nivel }) {
  const classes = useStyles();
  let altuTamMax = 600;
  let history = useHistory();
  const source = axios.CancelToken.source();
  const usuID = localStorage.getItem('UsuId');
  let Dispvo =localStorage.getItem('Dispvo') ;    
  let auxDispV =Dispvo.length >= 12 ? Dispvo:'w8rf51v21dsd2cs'  
  const [selectedID] = React.useState(null);
  const [listSecc,setListSecc] = React.useState([]);
  const [espera,setEspera]=React.useState(false);
  const [avTotal,setAvTotal]=React.useState([]);
  const [listaExcel, setListaExcel] = React.useState([]);
  //const libreriaG = [process.env.REACT_APP_GOOGLE_LIBRARIE];
  const [totalPaginas,setTotalPaginas]=useState(0);
  const [numPagina,setNumPagina]=useState(0);
  const [listPagina,seLlistPagina]=useState([]);
  let tamMax=300
  useEffect(() => {
    const llenaListMeta=()=>{
      setListaExcel([])
      let data = qs.stringify({
        usuario:usuID,                                                  
        municipio:muniSelect.Id,          
        idDispositivo: auxDispV,
      });
      function respuesta(auxiliar){
        if (auxiliar[0].SeccId !== -1) 
        { console.log(auxiliar);  
          
          let auxTotal={avCompro:0,totCompro:0,avEst:0,totEst:0}  
          let arrM=[]
          arrM.push(["Sección","Avance Comprometido","","Nombre","Usuario","Teléfono","Avance Estructura",""]) 
          auxiliar.forEach(element => {                
              auxTotal={avCompro:auxTotal.avCompro + element.SeccAvance
                  ,totCompro:auxTotal.totCompro + element.SeccMeta
                  ,avEst:auxTotal.avEst + element.PromotorAvance
                  ,totEst: auxTotal.totEst + element.PromotorTotal
              }
            arrM.push([element.SeccNum,`${element.SeccAvance}/${element.SeccMeta}`,`${element.SeccAvance/element.SeccMeta*100}%`,
              element.UserNom,element.UserUser,element.UserTel,
              `${element.PromotorAvance}/${element.PromotorTotal}`,`${element.PromotorAvance/element.PromotorTotal*100}%`])
          }); 
          setAvTotal(auxTotal)
          setListaExcel(arrM)       
          numeroPag(auxiliar,tamMax,setTotalPaginas,setNumPagina,seLlistPagina)   
          setListSecc(auxiliar)
          //seLlistPagina()
        }
        else{
          setListaExcel([])
        }                               
      }
      let url = "perfil-lista-municipio-seccion";
      llamaApiCargaToken(data, url, respuesta,ErrorGeneral,setEspera,history,source.token);
    } 
    const llenaListZon=()=>{
      setListaExcel([])
      let data = qs.stringify({
        usuario:usuID,                                                  
        zona:muniSelect.Id,          
        idDispositivo: auxDispV,
      });
      function respuesta(auxiliar){
        if (auxiliar[0].SeccId !== -1) 
        { console.log(auxiliar);  
          
          let auxTotal={avCompro:0,totCompro:0,avEst:0,totEst:0}  
          let arrM=[]
          arrM.push(["Zona","Avance Comprometido","","Nombre","Usuario","Teléfono","Avance Estructura",""]) 
          auxiliar.forEach(element => {                
            auxTotal={avCompro:auxTotal.avCompro + element.CRegAvance
              ,totCompro:auxTotal.totCompro + element.RegMeta
              ,avEst:auxTotal.avEst + element.MunicipioAvance
              ,totEst: auxTotal.totEst + element.MunicipioTotal
            }
            arrM.push([element.CRegNom,`${element.CRegAvance}/${element.RegMeta}`,`${element.CRegAvance/element.RegMeta*100}%`,
              element.UserNom,element.UserUser,element.UserTel,
              `${element.MunicipioAvance}/${element.MunicipioTotal}`,`${element.MunicipioAvance/element.MunicipioTotal*100}%`])
          }); 
          setAvTotal(auxTotal)
          setListaExcel(arrM)       
          numeroPag(auxiliar,tamMax,setTotalPaginas,setNumPagina,seLlistPagina)   
          setListSecc(auxiliar)
          //seLlistPagina()
        }
        else{
          setListaExcel([])
        }                               
      }
      let url = "perfil-lista-zona-region-municipio-seccion";
      llamaApiCargaToken(data, url, respuesta,ErrorGeneral,setEspera,history,source.token);
    }   
    const llenaListReg=()=>{
      setListaExcel([])
      let data = qs.stringify({
        usuario:usuID,                                                  
        region:muniSelect.Id,          
        idDispositivo: auxDispV,
      });
      function respuesta(auxiliar){
        if (auxiliar[0].SeccId !== -1) 
        { console.log(auxiliar);  
          
          let auxTotal={avCompro:0,totCompro:0,avEst:0,totEst:0}  
          let arrM=[]
          arrM.push(["Municipio","Avance Comprometido","","Nombre","Usuario","Teléfono","Avance Estructura",""]) 
          auxiliar.forEach(element => {                
            auxTotal={
              avCompro:auxTotal.avCompro + element.MunAvance
              ,totCompro:auxTotal.totCompro + element.MunMeta
              ,avEst:auxTotal.avEst + element.SeccionalAvance
              ,totEst: auxTotal.totEst + element.SeccionalTotal
            }
            arrM.push([element.MunNom,`${element.MunAvance}/${element.MunMeta}`,`${element.MunAvance/element.MunMeta*100}%`,
              element.UserNom,element.UserUser,element.UserTel,
              `${element.SeccionalAvance}/${element.SeccionalTotal}`,`${element.SeccionalAvance/element.SeccionalTotal*100}%`])
          }); 
          setAvTotal(auxTotal)
          setListaExcel(arrM)       
          numeroPag(auxiliar,tamMax,setTotalPaginas,setNumPagina,seLlistPagina)   
          setListSecc(auxiliar)
          //seLlistPagina()
        }
        else{
          setListaExcel([])
        }                               
      }
      let url = "perfil-lista-region-municipio-seccion";
      llamaApiCargaToken(data, url, respuesta,ErrorGeneral,setEspera,history,source.token);
    }  
    if (nivel===3) {
      llenaListMeta()  
    }
    if (nivel===2) {
      llenaListReg()  
    }      
    if (nivel===1) {
      llenaListZon()  
    }
    return ()=> {
      source.cancel();
  }                
}, []);

  function onChangePagina(event, newPage){
    event.preventDefault();
    if (newPage ===0) 
    {
      seLlistPagina(listSecc.slice(0,tamMax))   
    }
    else
    {
      seLlistPagina(listSecc.slice(newPage * tamMax, newPage * tamMax + tamMax))   
    }
    setNumPagina(newPage)
}

function generarFooter(){
    return(
    <TablePagination
      rowsPerPageOptions={false} colSpan={3}
      rowsPerPage={tamMax} count={listSecc.length}
      page={numPagina} lang='es'
      labelDisplayedRows={
          ({ from, to, count }) => {
            return '' + from + '-' + to + ' de ' + count
          }
        }
      onPageChange={onChangePagina}
      ActionsComponent={PaginacionTab}/>)
}

  const exportarArch = () => {
    let today = new Date();                    
    let fec = moment(today);        
    console.log(listaExcel);
    const ws = XLSX.utils.aoa_to_sheet(listaExcel);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Reporte");
    XLSX.writeFile(wb, fec.format("YYMMDDHHmmss")+" Lista Meta "+nomMuni+".xlsx")
  };

  function Renglon(props) {
    const { row } = props;
    //let icono=<FiberManualRecordIcon fontSize="inherit"  style={{color:row.color }} />
    //let auxIcon=<span>{icono} {row.nombre}</span>
    return (
      <TableRow
        //onClick={() => { seleccionado(row.Id, row); }}
        className={classes.tableRow} selected={selectedID === row.Id}
        classes={{ hover: classes.hover, selected: classes.selected }}
      >
        {/* celdaIcono(row.avance+"%",icono,0,classes.tableCell) */}
        {celda( "center", { width: 60+ "px", fontSize: 12 + "px"},
          row.SeccNum ,1,classes.tableCell
        )}
        {celda( "center", { width: 150+ "px", fontSize: 12 + "px"},
          `${row.SeccAvance}/${row.SeccMeta}` ,2,classes.tableCell
        )}    
        {celda( "center", { width: 30+ "px", fontSize: 12 + "px"},
          `${Math.round(row.SeccAvance/row.SeccMeta*100)}%` ,2,classes.tableCell
        )}    
        {celda( "left", { width: 200 + "px", fontSize: 12 + "px"},
          `${row.UserNom} ` ,3,classes.tableCell
        )}        
        {celda( "center", { width: 90 + "px", fontSize: 12 + "px"},
          `${row.UserUser} ` ,3,classes.tableCell
        )}   
        {celda( "center", { width: 80 + "px", fontSize: 12 + "px"},
          `${row.UserTel} ` ,3,classes.tableCell
        )}        
        {celda( "center", { width: 130 + "px", fontSize: 12 + "px"},
          `${row.PromotorAvance}/${row.PromotorTotal}` ,3,classes.tableCell
        )}        
        {celda( "center", { width: 30 + "px", fontSize: 12 + "px"},
          `${Math.round(row.PromotorAvance/row.PromotorTotal*100)}%` ,3,classes.tableCell
        )}        
      </TableRow>
    );
  }

  function RenglonReg(props) {
    const { row } = props;    
    return (
      <TableRow        
        className={classes.tableRow} selected={selectedID === row.Id}
        classes={{ hover: classes.hover, selected: classes.selected }}
      >        
        {celda( "center", { width: 150+ "px", fontSize: 12 + "px"},
          row.MunNom ,1,classes.tableCell
        )}
        {celda( "center", { width: 150+ "px", fontSize: 12 + "px"},
          `${row.MunAvance}/${row.MunMeta}` ,2,classes.tableCell
        )}    
        {celda( "center", { width: 30+ "px", fontSize: 12 + "px"},
          `${Math.round(row.MunAvance/row.MunMeta*100)}%` ,2,classes.tableCell
        )}    
        {celda( "left", { width: 220 + "px", fontSize: 12 + "px"},
          `${row.UserNom} ` ,3,classes.tableCell
        )}        
        {celda( "center", { width: 90 + "px", fontSize: 12 + "px"},
          `${row.UserUser} ` ,3,classes.tableCell
        )}   
        {celda( "center", { width: 80 + "px", fontSize: 12 + "px"},
          `${row.UserTel} ` ,3,classes.tableCell
        )}        
        {celda( "center", { width: 130 + "px", fontSize: 12 + "px"},
          `${row.SeccionalAvance}/${row.SeccionalTotal}` ,3,classes.tableCell
        )}        
        {celda( "center", { width: 30 + "px", fontSize: 12 + "px"},
          `${Math.round(row.SeccionalAvance/row.SeccionalTotal*100)}%` ,3,classes.tableCell
        )}        
      </TableRow>
    );
  }

  function RenglonZon(props) {
    const { row } = props;    
    return (
      <TableRow      
        className={classes.tableRow} selected={selectedID === row.Id}
        classes={{ hover: classes.hover, selected: classes.selected }}
      >        
        {celda( "center", { width: 150+ "px", fontSize: 12 + "px"},
          row.CRegNom ,1,classes.tableCell
        )}
        {celda( "center", { width: 150+ "px", fontSize: 12 + "px"},
          `${row.CRegAvance}/${row.RegMeta}` ,2,classes.tableCell
        )}    
        {celda( "center", { width: 30+ "px", fontSize: 12 + "px"},
          `${Math.round(row.CRegAvance/row.RegMeta*100)}%` ,2,classes.tableCell
        )}    
        {celda( "left", { width: 220 + "px", fontSize: 12 + "px"},
          `${row.UserNom} ` ,3,classes.tableCell
        )}        
        {celda( "center", { width: 90 + "px", fontSize: 12 + "px"},
          `${row.UserUser} ` ,3,classes.tableCell
        )}   
        {celda( "center", { width: 80 + "px", fontSize: 12 + "px"},
          `${row.UserTel} ` ,3,classes.tableCell
        )}        
        {celda( "center", { width: 130 + "px", fontSize: 12 + "px"},
          `${row.MunicipioAvance}/${row.MunicipioTotal}` ,3,classes.tableCell
        )}        
        {celda( "center", { width: 30 + "px", fontSize: 12 + "px"},
          `${Math.round(row.MunicipioAvance/row.MunicipioTotal*100)}%` ,3,classes.tableCell
        )}        
      </TableRow>
    );
  }

  function cabeza() {
    let estiloCol = { backgroundColor: process.env.REACT_APP_Fondo_Color, color: "white" };
    return (
      <TableRow>
        { nivel===3?columnas.map((row) => {
            let auxTitulo= row.id === 1 ? row.titulo +( avTotal.length !== 0 ? " "+avTotal.avCompro +"/"+avTotal.totCompro :"")  :row.titulo
            auxTitulo= row.id === 6 ? row.titulo +( avTotal.length !== 0 ? `${Math.round(avTotal.avCompro /avTotal.totCompro *100)}%`:"")  :row.titulo
            auxTitulo=  row.id === 5 &&  avTotal.length !== 0 ?row.titulo+ " "+avTotal.avEst +"/"+avTotal.totEst :auxTitulo
            auxTitulo=  row.id === 7 &&  avTotal.length !== 0 ?row.titulo+ `${Math.round(avTotal.avEst/avTotal.totEst*100)}%`:auxTitulo
          return celda(row.alinear, estiloCol, auxTitulo , row.id,{});
        })
        :nivel===2?columnasReg.map((row) => {
          let auxTitulo= row.id === 1 ? row.titulo +( avTotal.length !== 0 ? " "+avTotal.avCompro +"/"+avTotal.totCompro :"")  :row.titulo
          auxTitulo= row.id === 6 ? row.titulo +( avTotal.length !== 0 ? `${Math.round(avTotal.avCompro /avTotal.totCompro *100)}%`:"")  :row.titulo
          auxTitulo=  row.id === 5 &&  avTotal.length !== 0 ?row.titulo+ " "+avTotal.avEst +"/"+avTotal.totEst :auxTitulo
          auxTitulo=  row.id === 7 &&  avTotal.length !== 0 ?row.titulo+ `${Math.round(avTotal.avEst/avTotal.totEst*100)}%`:auxTitulo
        return celda(row.alinear, estiloCol, auxTitulo , row.id,{});
      }):
        columnasZon.map((row) => {
          let auxTitulo= row.id === 1 ? row.titulo +( avTotal.length !== 0 ? " "+avTotal.avCompro +"/"+avTotal.totCompro :"")  :row.titulo
          auxTitulo= row.id === 6 ? row.titulo +( avTotal.length !== 0 ? `${Math.round(avTotal.avCompro /avTotal.totCompro *100)}%`:"")  :row.titulo
          auxTitulo=  row.id === 5 &&  avTotal.length !== 0 ?row.titulo+ " "+avTotal.avEst +"/"+avTotal.totEst :auxTitulo
          auxTitulo=  row.id === 7 &&  avTotal.length !== 0 ?row.titulo+ `${Math.round(avTotal.avEst/avTotal.totEst*100)}%`:auxTitulo
        return celda(row.alinear, estiloCol, auxTitulo , row.id,{});
      })
        }
      </TableRow>
    );
  }

  function Cuerpo(auxlista) {
    return auxlista.length !== 0
      ? auxlista.map((row) => <Renglon key={row.Id} row={row} />)
      : null;       
  }

  function CuerpoReg(auxlista) {
    return auxlista.length !== 0
      ? auxlista.map((row) => <RenglonReg key={row.Id} row={row} />)
      : null;       
  }
  function CuerpoZon(auxlista) {
    return auxlista.length !== 0
      ? auxlista.map((row) => <RenglonZon key={row.Id} row={row} />)
      : null;       
  }

  function tablaCompleta(auxlista) {      
    return (
    <Card>
     { TablaGeneral(Cuerpo(auxlista),cabeza(),classes.container)}
    </Card>
    );
  }

  function tablaCompletaReg(auxlista) {      
    return (
    <Card>
     { TablaGeneral(CuerpoReg(auxlista),cabeza(),classes.container)}
    </Card>
    );
  }

  function tablaCompletaZon(auxlista) {      
    return (
    <Card>
     { TablaGeneral(CuerpoZon(auxlista),cabeza(),classes.container)}
    </Card>
    );
  }
  const tamanioH =
  window.innerHeight < altuTamMax
    ? window.innerHeight - 100
    : window.innerHeight - 180;

  const paginacion = totalPaginas>1 ? generarFooter() : null;
  const tabla3= listPagina.length!==0 &&nivel===1 ?tablaCompletaZon(listPagina) :null
  const tabla2= listPagina.length!==0 &&nivel===2 ?tablaCompletaReg(listPagina) :tabla3
  const tabla=listPagina.length!==0 && nivel===3 ? tablaCompleta(listPagina)  : tabla2
  return (
    <Modal
      titulo={nomMuni} modalAbierto={modalAbierto}
      setModalAbierto={setModalAbierto} tamanio={nivel===2 ?"lg":"md"}
      //maxTam={true}
    >
      <Box display={"flex"} flexDirection="column">      
        { usuID && parseInt(usuID) === 1 && listaExcel.length!==0 ?
        <Box mp={1}>
          <IconButton onClick={()=>{}} size="small" >
            <Tooltip title="Exportar a Excel">
              <SaveIcon color="primary" size="small" />
            </Tooltip>
          </IconButton>
          </Box>
        :null}              
        {tabla}  
        {paginacion}                      
      </Box>
      {espera?<Box m={1} style={{width:25+'rem'}}><LinearProgress/></Box>:null}
    </Modal>
  )
}
