import { Box, Card, makeStyles, Typography ,Paper} from '@material-ui/core'
import React from 'react'
import ModalInformativo from '../generales/ModalInformativo'
import MapaAlerta  from './MapaAlerta'

const useStyles=makeStyles({
    cardTexto:{
        paddingTop:.125+'rem',
        paddingBottom:.125+'rem',
        textAlign:'center',  
        marginBottom:.5+'rem'      
    },
    texto:{
        fontWeight:700
    },
    cardMapa:{
        display:'flex',
        justifyContent:'center'
    },        

})
const ModalMapa = ({setModalAbierto,modalAbierto,titulo,seleccionado,subtitulo}) => {
    const miClase=useStyles()
    return (
        <ModalInformativo
            setModalAbierto={setModalAbierto} nvoTitulo={seleccionado}
            modalAbierto={modalAbierto} titulo={titulo}
            tamanio='md' colorFondo='#f5f5f5' subtitulo={subtitulo}
        >
            <Box // style={{width:50+'rem',height:50+'rem'}}
            >
    

                <Card className={miClase.cardMapa}>
                    <MapaAlerta Seleccionado={seleccionado}/>
                </Card>
                
            </Box>
            
        </ModalInformativo>
    )
}

export default ModalMapa
