import React,{useState,useEffect} from 'react';
import {Table,TableBody,TableCell,TableContainer, IconButton,Tooltip,
        TableHead,TableRow,Card,TextField,Box} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import SaveIcon from '@material-ui/icons/Save';
import RefreshIcon from '@material-ui/icons/Refresh';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import AsignarVotos from './PrepAsignarVotacion';
import NumberFormat from 'react-number-format';
import ModalGaleria from './PrepModGaleria';
import { useHistory } from "react-router-dom";
import XLSX from 'xlsx';
import moment from 'moment';
import axios from 'axios';
import qs from 'qs';
import PhotoLibraryIcon from '@material-ui/icons/PhotoLibrary';
import ModalEvidencia from './ModalFotografia'
const useStyles = makeStyles({
    root: {
      width: '100%',
    },
    estiloCard:{
        minWidth: '400px',
        maxWidth:'100%',
        boxShadow:'0 0.5em 1em -0.125em rgb(10 10 10 / 10%), 0 0 0 1px rgb(10 10 10 / 2%)',
        color:'#4a4a4a',
        display:'block',
        padding:'1rem',
        minHeight: 280,
        marginRight:'.5em'
    },
    estiloCardDatos:{
        
        textAlign:'center',
        fontWeight:600,
        fontSize:.8+'rem'
    },
    formControl:{
        boxSizing:'border-box',
        fontSize:'1rem',
        position:'relative',
        marginBottom:'1em'
    },
    tablaContainer: {
        minWidth: '380px',
        maxWidth:'100%',
        minHeight: 150,
        //minWidth:'100%',
      },
      tableCellHeader: {
        minHeight:30,
        maxHeight: 30,
      },
    unionHeaders:{
      top: 25
    },
    totalEstilo:{
        fontSize:'1.25rem',
        fontWeight:'bold'
    },
    tituloEstilo:{
        paddingBottom:'.25rem',
        fontWeight:'bold'
    },
    tableRow: {
      "&$selected, &$selected:hover": {
        backgroundColor: process.env.REACT_APP_Color_Second 
      }
    },
    tableCellNominal:{
        color:process.env.REACT_APP_Fondo_Color ,
        "$selected &": {
            color: "white"
          }
      },
    tableCell: {
      "$selected &": {
        color: "white"
      }
    },
    hover: {},
    selected: {}
  });

  const columnas=[
    {
        id:4,
        titulo:"Secc",
        alinea:'center'
    },  
    {
        id:2,
        titulo:"LNom",
        alinea:'left'
    },
    {
        id:3,
        titulo:"Total",
        alinea:'center'
    },
    {
        id:6,
        titulo:"Votos",
        alinea:'center'
    },
    {
        id:5,
        titulo:"Part",
        alinea:'center'
    },

];

  //const alturaCont = window.innerHeight<937 ? window.innerHeight-520 : window.innerHeight-570
  const alturaCont= window.innerHeight<937 ? `calc(100vh - 28rem)`:`calc(100vh - 30rem)` // "24rem"
  const alturaCont2= window.innerHeight<937 ? `calc(100vh - 33rem)`:`calc(100vh - 35rem)` // "24rem"
export default function TablaSeccion({datos,setListaXSecCasilla,setActualizar,actualizar,setTituloSeccion, tituloMunicipio,procesoSeleccionado,municipio}){
    let history = useHistory();
    let auxArreglo=[]
    const classes = useStyles();    
    const auxCol=process.env.REACT_APP_Fondo_Color
    const [listaSecc, setListaSecc] = useState(datos) 
    const [secc,guardarSecc] = useState({ nombre:''});
    const [idSelec,setIdSelec] = useState(-1)
    const [seleccionado,setSeleccionado] = useState([])
    const [modalFotos,setModalFotos] = useState(false)
    const [modalVotos,setModalVotos] =useState(false)
    const [modalEvidencia,setModalEvidencia] =useState(false)
    
	const {nombre} = secc;
    
    useEffect(()=>{
        setListaSecc(datos)
    },[datos])
    
    function getDatos(num){
        const source = axios.CancelToken.source(); 
        async function getResultados(){
            let auxU=process.env.REACT_APP_LINK +`/votacion-movil-seccion-casilla`;               
            let token =localStorage.getItem('token20') ;                
            let dataInfo = qs.stringify({                      
                'periodo':procesoSeleccionado,                                
                'seccion':num,
                'idDispositivo':'7y8dyguwjhds'
                });
            let config = {
                url:auxU ,
                method: 'POST',
                headers: { 'access-token' : token, 'Content-Type': 'application/x-www-form-urlencoded' },
                data: dataInfo,
                cancelToken: source.token
            };
            let aux ={};
            await axios(config)
            .then(function (response) {
                aux=  response.data;                                                        
                                                                                           
                   if (aux) 
                   {//console.log(aux);                       
                    setListaXSecCasilla(aux);
                   }              
            })
            .catch(function (error) {                
                console.log(error);
                if ( ( error && error.response  && error.response.data && error.response.data.error && error.response.data.error.name)
                     && ( error.response.data.error.name === "JsonWebTokenError" || error.response.data.error.name === "TokenExpiredError" )) 
                {   localStorage.clear();                    
                    history.push("/");                                          
                }
                if ( error && error.response  && error.response.data && error.response.data.mensaje 
                    && error.response.data.mensaje=== "Datos Incorrectos") 
                {
                    console.log('sin datos' );                    
                }
            });                
        }
        getResultados()
    }

    const exportarArch = () => {
        let today = new Date();                    
        let fec = moment(today);
        const ws = XLSX.utils.aoa_to_sheet(auxArreglo);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "Reporte");
        XLSX.writeFile(wb, fec.format("YYMMDDHHmmss")+" Votacion de secciones en "+tituloMunicipio +".xlsx")
    };

    function selecFila(secc){     
        setSeleccionado(secc)           
        setIdSelec(secc.seccId);
        setTituloSeccion(secc.seccId)
        getDatos(secc.seccId)
    }

    function filtro(){        
        let auxCadena=nombre.trim();         
        let info=listaSecc.filter((dat)=>dat.seccNom.toUpperCase().search(auxCadena.toUpperCase() ) !== -1)              
        return generarTabla(info);
    }

    function abrirModalFotos(secc){
        setSeleccionado(secc)
        setModalFotos(!modalFotos);
    }

    function abrirModalVotos(){        
        setModalVotos(!modalVotos);
    }

    const onChange = e =>
    {   const expNopermitida = new RegExp('[A-Za-z._:$!%-({})?/*|]');
        const expMenos = new RegExp('-');
        const expMas = new RegExp('[+]');     
        const expCadena = new RegExp('[0-9]');
        if ((expCadena.test(e.target.value) && !expNopermitida.test(e.target.value) 
            && !expMenos.test(e.target.value) &&!expMas.test(e.target.value))
            ||e.target.value===' '||e.target.value==='') 
        {
            guardarSecc({ ...secc, [e.target.name] : e.target.value })
        }        
    };

    const celda = (alinear, estilo, contenido,index) => {
        return (
        <TableCell align={alinear} key={index} className={classes.tableCell} 
            padding={'none'} style={estilo} >
            {contenido}
        </TableCell>)
    }
    const CelTitl=({index,titulo,aline})=> celda(aline?aline:"center", {backgroundColor:auxCol,color:'white',}, titulo?titulo:"",index) 

    function sinDatos(){
        return(
        <TableContainer className={classes.tablaContainer} id="tablaPromotor" style={{height:alturaCont2}}>
        <Table stickyHeader aria-label="sticky table">
            <TableHead >    
                <TableRow>   
                    <CelTitl index={1} titulo={listaSecc.length} aline="center" />                          
                    {columnas.map(row=>{
                        return <CelTitl index={row.id+1} titulo={row.titulo} aline={row.alinea} /> 
                    })}         
                </TableRow>   
            </TableHead>
            <TableBody>
                <TableRow>
                    {celda("center", {}, "Sin",1)}
                    {celda("center", {}, "Resultados",2)}    
                    {celda("center", {}, "Disponibles",3)}                                                            
                </TableRow>
            </TableBody>
        </Table>
        </TableContainer>
        )
    }    

    function generarColor(valor,votos){
        var colorEfecti='';    
        if(valor<30)
        {   colorEfecti= '#FF0505E8';        
        }
        else
        {   if(valor>=30 && valor<60)
            {   colorEfecti= '#FFC300';               
            }
            else
            {   if(valor>=60 )
                {   colorEfecti= '#1CC63D';
                }
            }
        }
        return(
        <Card className={classes.estiloCardDatos} 
            style={{backgroundColor:colorEfecti,color:votos===0?"":"white"}}>
            <NumberFormat value= {votos} displayType={'text'}  thousandSeparator={true}/>
        </Card>
        )
    }

    function generarTabla(lista) {
        const auxi2=["#","Municipio","LNominal","Votos","Partido","Votación"];
        auxArreglo.push(auxi2);
        let tot= Object.keys(lista).length;
        if(tot===0){
            let auxi3=["","Sin","Tipos"]
            auxArreglo.push(auxi3);            
        }       
        return(
        <TableContainer className={classes.tablaContainer} id="tablaSecc" style={{height:alturaCont2}}>
        <Table stickyHeader aria-label="sticky table">
            <TableHead >    
                <TableRow>  
                    <CelTitl index={1} titulo={lista.length} aline="center" />                        
                    {columnas.map(row=>{
                        return <CelTitl index={row.id+1} titulo={row.titulo} aline={row.alinea} />
                    })}       
                </TableRow>  
            </TableHead>
            <TableBody>
            {lista.map((seccion,index) => {
                let auxNombre="";
                auxNombre= seccion.seccNom;                    
                const auxi=[index+1,auxNombre,seccion.lNominal, seccion.votacion,seccion.partNom,seccion.partVoto]
                auxArreglo.push(auxi);                     
                return (                  
                <TableRow className={classes.tableRow} selected={idSelec === seccion.seccId}  key={index}
                    classes={{ hover: classes.hover, selected: classes.selected }} onClick={ () => selecFila(seccion) }
                >   
                    {celda("center", {width:20}, 
                        <Tooltip title="Galería de Fotos">
                            <IconButton size="small" component="span" onClick={() => abrirModalFotos(seccion)}> 
                                <PhotoLibraryIcon style={{color:idSelec === seccion.seccId ?"white":""}} 
                                    color={idSelec === seccion.seccId ? "inherit":"primary"} />                                            
                            </IconButton>
                        </Tooltip>  ,index+1)}                             
                    {celda("center", {width:3+'rem'}, <b>{auxNombre}</b>, index+2)}
                    {celda("left", {color:idSelec === seccion.seccId ?"":auxCol,fontSize:'13px',width:1.5+'rem',fontWeight:600,paddingLeft:'.2em'}, 
                        seccion.lNominal, index+3)}                                                                                                                                  
                    {celda("center", {width:'3rem'}, generarColor((seccion.votacion/seccion.lNominal)*100 , seccion.votacion), index+4)}                                    
                    {celda("center", {width:3+'rem'}, <b>{seccion.partVoto}</b>, index+5)}
                    {celda("center", {width:30}, <img src= {`./partidos/${seccion.partImgWeb}`}  alt="" border="0" height="25" width="25"/>, index+6)}                                                
                </TableRow>              
                );
            })} 
            </TableBody>
        </Table>
        </TableContainer>
        )                   
    }        

    function actualizarDat(e){
        e.preventDefault();
        setActualizar(actualizar+1)
    }
    
    const modEditImg=modalEvidencia ? <ModalEvidencia modalAbierto={modalEvidencia} setModalAbierto={setModalEvidencia} seleccionado={seleccionado} 
                                        titulo={"Evidencia Sección " + seleccionado.seccNom}  getDatos={()=>{}} municipio={municipio} 
                                        procSel={procesoSeleccionado} setModalFotos={setModalFotos} />:null
    const modFot = modalFotos ? <ModalGaleria seleccionado={seleccionado} setModalAbierto={setModalFotos} modalAbierto={modalFotos}
                                    setModalEvidencia={setModalEvidencia}  procSel={procesoSeleccionado}
                                /> : null;
    const modVot = modalVotos ? <AsignarVotos setModalAbierto={setModalVotos} modalAbierto={modalVotos} municipio={municipio} procSel={procesoSeleccionado}/> : null;
    const contenido = secc? (filtro()):(generarTabla(listaSecc));
    const validaCont = listaSecc.length!==0 ? contenido : sinDatos();
    const auxEstil={marginLeft:'.3em',marginTop:'.5em',width:'20px', height:'20px'}
    return (
    <Box display={'flex'} flexDirection="row">
    <Card className={classes.estiloCard} style={{paddingTop:1+'rem',height: alturaCont}}>     
        <Box display="flex" flexDirection="row">
            <TextField className={classes.formControl}
                name="nombre" placeholder="Buscar Sección"
                value={nombre} onChange={onChange}/>
            <IconButton  onClick={actualizarDat} style={auxEstil}>
                <Tooltip title="Recuperar Secciones">
                    <RefreshIcon color="primary"/>
                </Tooltip>
            </IconButton>
            {listaSecc.length!==0 ? 
            <IconButton  onClick={()=>{}} style={auxEstil}>
                <Tooltip title="Exportar Secciones">
                    <SaveIcon color="primary"/>
                </Tooltip>
            </IconButton>
            : null}
            <IconButton  onClick={abrirModalVotos} style={auxEstil}>
                <Tooltip title="Asignar Votos">
                    <AddCircleOutlineIcon color="primary"/>
                </Tooltip>
            </IconButton>
        </Box>                   	            
        {validaCont}
    </Card>
    {modFot} 
    {modEditImg}
    {modVot}
    </Box>               
  );
}