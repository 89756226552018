import React,{createContext,useState} from 'react';

export const ClienteContext = createContext();

const ClienteProvider = (props) => {
    const [clientSeleccionado,setClientSeleccionado]=useState([])
    return (
        <ClienteContext.Provider
            value={ { clientSeleccionado,setClientSeleccionado} }
        >
            { props.children }
       </ClienteContext.Provider> 
    )
}

export default ClienteProvider
