export const arreColores = [
    "#038545",
    "#EF8012",
    "#347AF4",
    "#FFC107",
    "#119A4F",
    "#B406D8",
    "#FFA318",
    "#DC4A3D",
    "#32CA81",
    "#F9B546",
    "#ED0808",
    "#0097A7",
    "#880E4F",
    "#4BAC7D",
    "#C52363",
    "#F57F06",
    "#7C594C",
    "#DBE772",
  ];
  
export function obtenColor(valor) {
  let color="#c21214"
  let auxiliar=true
  if (auxiliar&& valor <= 25) 
  {
    color="#c21214"
    auxiliar=false
  }
  if (auxiliar&& valor <= 50) 
  {
    color="#ea811d"
    auxiliar=false
  }
  if (auxiliar&& valor <= 75) 
  {
    color="#ead71d"
    auxiliar=false
  }
  if (auxiliar&& valor <= 100) 
  {
    color="#7bc212"
    auxiliar=false
  }
  if (auxiliar&& valor > 100) 
  {
    color="#127bc2"        
  }
  return color
}  