import React,{useState,useEffect} from 'react';
import {Bar} from 'react-chartjs-2';
import { Card, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({

    card:{
      boxShadow:'0 0.5em 1em -0.125em rgb(10 10 10 / 10%), 0 0 0 1px rgb(10 10 10 / 2%)',
    },

  });

export default function GraficaMunicipio({valor,descripcion}){
    let altuTamMax=923
    const [alturaContenedor, setAlturaContenedor] = useState(window.innerHeight<altuTamMax ?(window.innerHeight>700? window.innerHeight-550 : window.innerHeight/2.5): window.innerHeight - 725)
    const [anchoContenedor, setAnchoContenedor] = useState(window.innerWidth>1300 ? (window.innerWidth>=1490 && window.innerWidth<1700 ? window.innerWidth-930 : window.innerWidth-1000) : window.innerWidth/2)
    const classes = useStyles();
    const auxCoordi = valor;
    const [miLabel,setMiLabel]=  useState([]);      
    const [midata,setMidata]=  useState([]);      
    const [miColor,setMiColor]=  useState([]);  
    

    useEffect(()=>{
      window.addEventListener('resize', function() {
            if(window.innerHeight<altuTamMax){
              if(window.innerHeight<700){

                setAlturaContenedor(window.innerHeight/2.5)
              }else{

                setAlturaContenedor(window.innerHeight-520)
              }
              }else{
                  setAlturaContenedor(window.innerHeight-725)
            }
            if(window.innerWidth>1300){
              if(window.innerWidth>=1490 && window.innerWidth<1700){
                setAnchoContenedor(window.innerWidth-930)
              }else{
                setAnchoContenedor(window.innerWidth-1000)
              }
            }else{
                setAnchoContenedor(window.innerWidth/3)
            }
        
    });
  },[alturaContenedor,anchoContenedor,altuTamMax])

    useEffect(()=>
    {  let contador=0;
        let auxArreglo=[];
        let auxmiLabel=[]
        let auxmidata=[]
        let auxmiColor=[]
        auxArreglo.push({ value : -1 , label : "Excel" })
        auxArreglo.push({ value : 0 , label : "Secc" })
        function SumaPartidos() 
        {          
          auxCoordi.forEach( secc =>
          {             
              if (contador<5) {
                const voto=secc.Voto>0?secc.Voto:""
                auxmidata[contador]=secc.Voto;
                auxmiLabel[contador]=secc.ParNom+" "+voto;                
                auxmiColor[contador]=secc.ParColor.substring(0, 7);    
                contador++; 
              }            
          });  
        }
        SumaPartidos();        
        setMiLabel(auxmiLabel);
        setMidata(auxmidata);
        setMiColor(auxmiColor);

    },[valor,auxCoordi]);

    const options = {
        indexAxis:'y',
          plugins: {
              legend: {
                  display: false
              }
          },
        tooltips: {
          enabled: true,
        },
        layout: {
          padding: {
              left: 0,
              right: 0,
              top: 10,
              bottom: 5
          }
        },
        
        title:{
          fontStyle: 'bold',
        },
       scales: {
          yAxes: [
            {
              stacked: true,
              ticks: {
                beginAtZero: true,
              },
            },
          ],
          xAxes: [
            {
              stacked: true,
              scaleLabel :{
                fontSize:6,
              },
            },
          ],
        },
      }  
    const horizontalRuta = {        
        labels: miLabel,
        datasets: [
          {
            label: 'Votos',//
            backgroundColor: miColor,
            borderColor: '#00001a',
            borderWidth: 0,
            height: 0,
            hoverBackgroundColor: '#F0D7D7',
            hoverBorderColor: '#00001a',
            data: midata 
          }
        ]
      };    
  
    return ( 
        <Card className={classes.card} style={{height:alturaContenedor+20,marginLeft:'1em',width:anchoContenedor}}>    
        <Typography id="ajustarTitulo" style={{textAlign:'center'}}>{descripcion}</Typography>
            <Bar
                id="graficaMuni"
                data={horizontalRuta}
                width={anchoContenedor}
                height={alturaContenedor}
                options={options}
              />                   
          </Card>
     );

}