import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import { Box,TextField,Tooltip,FormControl,//Select as MaterialSelect ,
        FormLabel,FormControlLabel,RadioGroup,Radio,
        InputLabel,Select,MenuItem,Typography} from '@material-ui/core'  
import axios from 'axios';
import qs from 'qs';
import { useHistory } from "react-router-dom";
import Modal from '../generales/ModalCerrarActualiza';

const useStyles = makeStyles((theme) => ({
    root: {
      '& > *': {
        margin: theme.spacing(1),
        width: '20ch',
      }, 
      formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
      },
      selectEmpty: {
        marginTop: theme.spacing(2),
      },
      Textnumero: {
        width: 5+"px",
      },
      formControlFull: {
        //margin: theme.spacing(1),
        //minWidth: 120,
        width:5+"rem"
      },
      margin: {
        margin: theme.spacing(1),
      },
    },
  }));
  
const EditarStatusUsu = ({modalAbierto,setModalAbierto ,titulo,setActualizar,actualizar,listTipoUsu,usrSelecc }) => {
    let history = useHistory();
    const classes = useStyles();
  
    const[nvoCliente,setNvoCliente]=React.useState({idUsu:'',stdUsu:'' });                                                    
    const [espera,setEspera]=React.useState(false);
    const [mensaje, setMensaje] = React.useState("");
    const [tipoAdvertencia, setTipoAdvertencia] = React.useState("success");
    const [btnDeshabilitado,setBtnDeshabilitado]= React.useState(false)
    const [aviso, setAviso] = React.useState(false);
    const[cambioEfectivo,serCambioEfectivo] =React.useState(false)    
    const{idUsu,stdUsu}=nvoCliente
    //let colorCheckR='#0EA7E0'
    const source = axios.CancelToken.source();  
    React.useEffect(()=>{
        setNvoCliente({
            ...nvoCliente,
            idUsu:usrSelecc.Id ,stdUsu:usrSelecc.Status
        })
    },[])
                  
    async function llamadaEditStd( auxStatus)  {       
        setEspera(true);
        let auxU=process.env.REACT_APP_LINK +`/usuario-editar-status`;                               
        let token =localStorage.getItem('token20') ;               
        let usuId =localStorage.getItem('UsuId') ;                                      
        let dataInfo = qs.stringify({     
            'idUsuario':usrSelecc.Id,
            'statusUsuario':auxStatus,        
            'idDispositivo':'451224452121'
            });
        let config = {
            url:auxU ,
            method: 'POST',
            headers: { 'access-token' : token, 'Content-Type': 'application/x-www-form-urlencoded' },
            data: dataInfo,
            cancelToken: source.token
        };
        let aux ={};           
        await axios(config)
        .then(function (response) {              
          aux=  response.data;                                          
            console.log(aux)
          if (aux.length!==0) 
          {                
            //setNvoRegistro(aux[0]);  
            //setActualizar( !actualizar)              
            setBtnDeshabilitado(true)                                             
            let mensaje="Se edito correctamente el Usuario."
            setTipoAdvertencia("success")      
            setMensaje(mensaje);
            setAviso(true)     
            serCambioEfectivo(true)
          }                                            
        })
        .catch(function (error) {                  
            console.log(`Error: ${error}`);              
            if( error && error.response && error.response.data && error.response.data.mensaje==="Datos Incorrectos")
            {
                console.log('datos incorrectos' );  
            }else
            {
                if ( ( error && error.response && error.response.data && error.response.data.error.name==="JsonWebTokenError" )||
                    ( error && error.response && error.response.data && error.response.data.error.name==="TokenExpiredError" ) ) 
                {
                console.log('loguearse de nuevo');
                localStorage.clear();                      
                history.push("/");                              
                } 
            }
        });             
        setEspera(false);         
    };

    const mandaAlerta=(mensaje,tipoAdvert)=>
    {
        setMensaje(mensaje);
        setTipoAdvertencia(tipoAdvert)  
        setAviso(true);      
    }

    function guardarDatos(e)
    {   e.preventDefault()
        console.log("guardando  modal");    
        let auxStatus=0
        if (stdUsu===0) 
        {
            auxStatus=1    
        }          
        else
        {
            if (stdUsu===1) 
            {
                auxStatus=0    
            }   
        }
        llamadaEditStd(auxStatus)                                                   
    }   

    const DatosGenerales=(
        <div>            
            <Typography  variant="h5" id="Nombre-input"   style={{paddingBottom:.5+'rem',width:35+'rem'}}
                  size="small"             
            >
                {usrSelecc.Status===0 ? '¿Activar Usuario '+usrSelecc.Nombre+' ?': '¿Desactivar Usuario '+usrSelecc.Nombre+' ?'}  
            </Typography>                        
        </div> 
    )       
    const cerrarBorrar = () => {    
      //setOpen(false);        
      setAviso(false);
      setModalAbierto(false);
      if (cambioEfectivo) 
      {
          setActualizar(!actualizar)
      }
  };
  return (
  <>           
    <Modal titulo={titulo} modalAbierto={modalAbierto}
      setModalAbierto={setModalAbierto} guardarDatos={guardarDatos}
      mensaje={mensaje} tipoAdvertencia={tipoAdvertencia}
      aviso={aviso} btnDeshabilitado={btnDeshabilitado}
      setAviso={setAviso} tamanio={'sm'} cargando={espera} 
      cerrarBorrar={cerrarBorrar}       
    >
      {DatosGenerales}
    </Modal>         
  </>
  )
}

export default EditarStatusUsu
