import React,{useState,useEffect} from 'react';
import ModalGeneral from '../generales/Modal';
import { Box,TextField,FormControl,Grid,
    InputLabel,Select,MenuItem} from '@material-ui/core' 
import { makeStyles} from '@material-ui/core/styles';
import { useHistory } from "react-router-dom";
import axios from 'axios';
import qs from 'qs';
import {llamaApiCargaToken,ErrorGeneral } from '../funciones/ClienteAxios';
const useStyles = makeStyles((theme) => ({
    root: {
      margin: {
        margin: theme.spacing(1),
      },
    },
    campos:{
      width: '200px',
      marginBottom: '1em',
      marginLeft:'1em'
    },
    camposCasilla:{
        width: '200px',
        marginBottom: '1em',
      },
    camposPartidos:{
        width:'200px'
    }
  
  }));

export default function AsignarVotos({setModalAbierto,modalAbierto,municipio,procSel}){
    const classes = useStyles();
    let miArrelo=[]     
    const history = useHistory();
    const source = axios.CancelToken.source();  
    const Usuid =localStorage.getItem('UsuId') ;       
    const Dispvo =localStorage.getItem('Dispvo') ;    
    const auxDispV =Dispvo && Dispvo.length >= 12 ? Dispvo:'w8rf51v21dsd2cs'      
    const [listaPartidos,setListaPartidos]=  useState([]);    
    const [listaCasillas,setListaCasillas]=  useState([]);            
    const [nuevaIncidencia,setNuevaIncidencia] = useState({ Tipo:0,Usuario:0,Casilla:0,Descripcion:"",Nota:""});
    const [nuevaVotacion,setNuevaVotacion] = useState({
                                     partido1:"",partido2:"",partido3:"",partido4:"",partido5:"",partido6:"",partido7:"",partido8:"",partido9:"",partido10:""
                                     ,partido11:"",partido12:"",partido13:"",partido14:"",partido15:"",partido16:"",partido17:"",partido18:"",partido19:"",partido20:""});
    const [listaSecc,setListaSecc]=  useState([]);  
    const [listaSeccion,setListaSeccion]=  useState([]);    
    const[espera,setEspera]=useState(false);
    const[espera2,setEspera2]=useState(false);
    const[espera3,setEspera3]=useState(false);
    const [seccion,setSeccion]=  useState(null);
    const [mensaje, setMensaje] = useState("");
    const [tipoAdvertencia, setTipoAdvertencia] = useState("success");
    const [btnDeshabilitado,setBtnDeshabilitado]= useState(false)
    const [aviso, setAviso] = useState(false);    
    const {Casilla} = nuevaIncidencia;     

    useEffect(()=>{     
         
        getSeccion()
        return ()=> {
            source.cancel();
        } 

    },[])

    const getSeccion = () => {          
        const data = qs.stringify({
            municipio:municipio.muniId,
            idDispositivo: auxDispV,
        });
        const url = "votacion-web-municipio-secccasi-lista";
        function respuesta(auxSecciones) {
            if (auxSecciones)
            {   let arreSecc=[], variable=0;                
                auxSecciones.forEach((seccion) => {
                    if (variable!==seccion.seccId)
                    {    variable=seccion.seccId;                        
                        arreSecc.push({ seccId : variable, seccNom :seccion.seccNom })                                               
                    }                                        
                })
                setListaSecc(auxSecciones);
                setListaSeccion(arreSecc);
            }
        }
        llamaApiCargaToken(data, url, respuesta,ErrorGeneral,setEspera,history,source);
    };    

    const getVotosCasilla = (casilla) => {          
        const data = qs.stringify({
            periodo:procSel,
            casilla:casilla,
            idDispositivo: auxDispV,
        });
        const url = "votacion-web-secccasi-lista";
        function respuesta(aux) {
            if (aux)
            {                        
              setListaPartidos(aux); 
            }
        }
        llamaApiCargaToken(data, url, respuesta,ErrorGeneral,setEspera2,history,source);
    };
    
    const llamarApi = (numCasilla,cadena) => {  
        setBtnDeshabilitado(true)                                    
        const data = qs.stringify({
            usuario:Usuid,
            periodo:procSel,
            casilla:numCasilla,
            valores:cadena,
            idDispositivo: auxDispV,
        });
        const url = "votacion-web-actualiza";
        function respuesta(aux) {
            if (aux) 
            {    
                const mensaje="Nuevos votos registrados"//aux.mensaje ;  
                setMensaje(mensaje)
                setTipoAdvertencia('success')
                setAviso(true)          
                // toast.success(mensaje, {  position: toast.POSITION.BOTTOM_RIGHT, autoClose: 3500, containerId:'ModalNvaVotacion'} );                                                   
            }   
            setNuevaVotacion({
                partido1:"",partido2:"",partido3:"",partido4:"",partido5:"",partido6:"",partido7:"",partido8:"",partido9:"",partido10:""
                ,partido11:"",partido12:"",partido13:"",partido14:"",partido15:"",partido16:"",partido17:"",partido18:"",partido19:"",partido20:""
            })       
            setNuevaIncidencia({Casilla:0})
            setListaPartidos([])
            setListaCasillas([]);
            setSeccion("")               
            setBtnDeshabilitado(false)         
        }
        llamaApiCargaToken(data, url, respuesta,ErrorGeneral,setEspera3,history,source);
    };

    function validar() {
        let cadena=""        
        let auxNuevaVotacion=nuevaVotacion       
        //console.log(auxNuevaVotacion);
        //console.log(miArrelo); 
        for (let index = 1; index < miArrelo.length; index++) {
            const partido="partido"+(index)
            const numValido=auxNuevaVotacion[partido] && auxNuevaVotacion[partido] !=="" ? auxNuevaVotacion[partido]:0
            //console.log(partido,auxNuevaVotacion[partido]);
            if (auxNuevaVotacion[partido] && auxNuevaVotacion[partido] !=="" ) {
                cadena=cadena+miArrelo[index-1].numSecc+","+numValido+"|";
               // console.log(cadena);    
            }
        }        
        return cadena
    }

    function validarDatos(){      
        if (seccion!=="")
        {
            if (Casilla!==0) 
            {   let auxDato=listaCasillas.filter(  (registro) => registro.casillaId === Casilla  )
                if (auxDato.length===1) 
                {                       
                    //llamarApi();             
                    let auxverifica=  validar()
                    //console.log(auxverifica)
                    if (auxverifica!=="") {
                        llamarApi(nuevaIncidencia.Casilla,auxverifica);      
                    }
                    else{
                        setMensaje('No se llenó ningún partido ');
                        setTipoAdvertencia("warning")  
                        setAviso(true);  
                    }
                }else 
                {   
                    setMensaje('Debe seleccionar la nueva casilla');
                    setTipoAdvertencia("warning")  
                    setAviso(true);        
                }
                
                
            }else
            {
                setMensaje('Seleccione una casilla válida');
                setTipoAdvertencia("warning")  
                setAviso(true);   
                   
            }    
        }else 
        {
            setMensaje('Seleccione una sección válida');
            setTipoAdvertencia("warning")  
            setAviso(true);    
        }	
	}

    function onChangeCasilla(e){                   
        setNuevaIncidencia({
            ...nuevaIncidencia, 
            Casilla:e.target.value
        })
        getVotosCasilla(e.target.value);
    }

    function onChangeSeccion(e){
        const dato= listaSecc.filter(  (registro) => registro.seccId === e.target.value )                    
        let arreCasilla=[];                      
        dato.forEach((seccion) => 
        {            
            arreCasilla.push({ casillaId : seccion.casillaId, 
                casillaNom : seccion.casillaNom })                                                                                                          
        })
        setListaCasillas(arreCasilla);
        setSeccion(e.target.value)
    }

    const onChange = (e) => {                              
        let expNopermitida = new RegExp('[A-Za-z._:$!%-({})?/*|]');
        let expMenos = new RegExp('-');
        let expMas = new RegExp('[+]');     
        let expCadena = new RegExp('[0-9]');
        if ( (expCadena.test(e.target.value) && !expNopermitida.test(e.target.value) 
            && !expMenos.test(e.target.value) && !expMas.test(e.target.value) )
            || e.target.value===' ' || e.target.value==='' ) 
        {
            setNuevaVotacion({
                ...nuevaVotacion, 
                [e.target.name] : e.target.value  
            })   
        }             
    }   

    function crearCampos(auxArr){
        return (
        <Box style={{display:'flex',flexWrap:'wrap',width:'100%',height:'320px',justifyContent:'center'}}>
        {auxArr.map((casilla,index) =>{
            const auxinom ="partido"+(index+1)
            const auxi={ numSecc :casilla.Id,numPart :index }
            miArrelo.push(auxi);
            return(
            <Box style={{display:'flex',flexDirection:'row'}} className={classes.camposPartidos}>
                <img src={`./partidos/${casilla.ParImgWeb}`} alt={"partido"+(index+1)} 
                    width="30" height="30" style={{marginTop:1.5+'em',marginRight:'.4em'}}/>
                <TextField required id="votos" onChange={onChange} style={{paddingRight:1+'rem',width:13+'rem',paddingBottom:1+'rem'}}
                    placeholder={casilla.Voto} label="Votos" value={nuevaVotacion[index]} name={auxinom} inputProps= {{ maxlength: 5 }}
                />  
            </Box>)
        })}
        </Box>
        )
    }   
    
    const generar = listaPartidos.length!==0? crearCampos(listaPartidos): null;

    return(
    <ModalGeneral titulo={"Asignar Votos"} tamanio={'md'} 
        modalAbierto={modalAbierto} setModalAbierto={setModalAbierto}
        guardarDatos={validarDatos} mensaje={mensaje}
        tipoAdvertencia={tipoAdvertencia} aviso={aviso}
        btnDeshabilitado={btnDeshabilitado} setAviso={setAviso}
        cargando={espera3}
    >
        <form className={classes.root} autoComplete="off">   
           <Grid container spacing={1} style={{justifyContent:'center'}}>
               <Grid item >
               {!espera ?
                listaSeccion.length!==0 ?                
                    <FormControl className={classes.campos}>
                        <InputLabel id="tipo">Sección</InputLabel>
                        <Select
                            label="Seccion" id="Seccion" name="seccion"
                            value={seccion} onChange={onChangeSeccion}
                        >
                            {listaSeccion.map((secc, index) => (
                            <MenuItem value={secc.seccId} key={secc.seccId}>
                                <em>{secc.seccNom}</em>
                            </MenuItem>
                            ))}
                        </Select>
                     </FormControl>
                    :   null
                :null}
               </Grid>
               <Grid item>
               {seccion > 0?
                    (!espera2 ? 
                        listaCasillas.length!==0 ? 
                        <FormControl className={classes.camposCasilla}>
                         <InputLabel id="tipo">Casilla</InputLabel>
                            <Select
                                label="casilla" id="casilla" name="casilla"
                                value={Casilla} onChange={onChangeCasilla}
                            >
                                {listaCasillas.map((cas, index) => (
                                <MenuItem value={cas.casillaId} key={cas.casillaId}>
                                    <em>{cas.casillaNom}</em>
                                </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        :null
                    :null)
                :null}
               </Grid>
            </Grid>
        {generar}
        </form>
    </ModalGeneral>
    )    
}