import React, { useState } from "react";
import {
  Box,
  IconButton,
  Table,
  TableBody,
  TableCell,
  LinearProgress,
  TableContainer,
  TableHead,
  TableRow,
  Card,
  Tooltip,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import axios from "axios";
import moment from "moment";
import InfoIcon from "@material-ui/icons/Info";
import ModalBitacora from "./BitacoraSug";
import ModalDetalles from "./ModalDetalles";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import EditIcon from "@material-ui/icons/Edit";
import ModalSugerencia from "./ModalSugerencia";

const useStyles = makeStyles({
  root: {
    //width: '100%',
  },
  container: {
    //maxHeight: 550,
    maxHeight: window.innerHeight - 250,
    minHeight: 280,
    width: "80 rem",
  },
  container2: {
    maxHeight: 650,
    //maxHeight: window.innerHeight-170,
    //minHeight: 280,
  },
  tableRow: {
    "&$selected, &$selected:hover": {
      backgroundColor: process.env.REACT_APP_Color_Second,
    },
  },
  tableCell: {
    "$selected &": {
      color: "white",
    },
  },
  hover: {},
  selected: {},
});

const columnas = [
  {
    id: 1,
    titulo: "Municipio",
    alinear: "center",
  },
  {
    id: 2,
    titulo: "Fecha de Registro",
    alinear: "center",
  },
  {/* 
    id: 9,
    titulo: "Dominio",
    alinear: "center", */
  },
  {
    id: 3,
    titulo: "Estatus",
    alinear: "center",
  },
  {
    id: 4,
    titulo: "Teléfono",
    alinear: "center",
  },
  {
    id: 5,
    titulo: "Nombre",
    alinear: "center",
  },
  {
    id: 6,
    titulo: "Email",
    alinear: "center",
  },
  {
    id: 7,
    titulo: "Genero",
    alinear: "center",
  },
  {
    id: 8,
    titulo: "Categoría",
    alinear: "center",
  },
];

const TablaSugePublico = ({
  listSuge,
  listTipo,
  listStatus,
  actualizar,
  setActualizar,
}) => {
  const classes = useStyles();
  let history = useHistory();
  let fondo =process.env.REACT_APP_Fondo_Color
  const [datos, guardarDatos] = React.useState([]);
  const [sinDatos, guardarSinDatos] = React.useState(true);
  const [espera, setEspera] = React.useState(false);
  const [selectedID, setSelectedID] = React.useState(null);
  const [regisSelect, setRegisSelect] = React.useState([]);
  const [tituloMapa, setTituloMapa] = useState("");
  const [modalBitacora, setModalBitacora] = useState(false);
  const [modalInfo, setModalInfo] = useState(false);
  const [modalEdit, setModalEdit] = useState(false);

  React.useEffect(() => {
    const source = axios.CancelToken.source();
    guardarDatos(listSuge);
    //console.log(listSuge);
    return () => {
      source.cancel();
    };
  }, [listSuge]);

  const seleccionado = (auxId, auxOrden) => {
    setSelectedID(auxId);
    setRegisSelect(auxOrden);
  };

  function tipoEdoProspecto(auxColorStd, etiqueta) {
    let renglon = (
      <TableCell
        align="center"
        padding={"none"}
        style={{
          color: "white",
          fontWeight: 700,
          width: 100 + "px",
          paddingLeft: 0.4 + "rem",
          paddingRight: 0.4 + "rem",
        }}
      >
        <Card
          style={{
            color: auxColorStd === "#EBF1F8" ? "black" : "white",
            fontWeight: "bold",
            backgroundColor: auxColorStd,
          }}
        >
          {etiqueta}
        </Card>
      </TableCell>
    );
    return renglon;
  }

  function abrirModalInfo(auxSelec) {
    setModalInfo(true);

    let titulo = `Registro ${auxSelec.Id} `;
    setTituloMapa(titulo);
    setRegisSelect(auxSelec);
  }

  function abrirModalBitacora(auxSelec) {
    setModalBitacora(true);
    //let titulo=`Registro ${auxSelec.Id} `;
    //setTituloMapa(titulo)
    setRegisSelect(auxSelec);
  }

  function abrirModalEditar(auxSelec) {
    setModalEdit(true);
    setRegisSelect(auxSelec);
  }

  const celda = (alinear, estilo, contenido) => {
    return (
      <TableCell
        align={alinear}
        className={classes.tableCell}
        padding={"none"}
        style={estilo}
      >
        {contenido}
      </TableCell>
    );
  };
  const celdaIco = (leyenda, funcionModal, childrenIco, registro) => {
    let auxCont = (
      <Tooltip title={leyenda}>
        <IconButton
          aria-label="add circle"
          size="small"
          onClick={() => funcionModal(registro)}
          component="span"
        >
          {childrenIco}
        </IconButton>
      </Tooltip>
    );
    return celda("center", { width: 10 + "px" }, auxCont);
  };

  function tipoEdoProspecto(auxColorStd, etiqueta) {
    let renglon = (
      <TableCell
        align="center"
        padding={"none"}
        style={{
          color: "white",
          fontWeight: 700,
          width: 100 + "px",
          paddingLeft: 0.4 + "rem",
          paddingRight: 0.4 + "rem",
        }}
      >
        <Card
          style={{
            color: auxColorStd === "#EBF1F8" ? "black" : "white",
            fontWeight: "bold",
            backgroundColor: auxColorStd,
          }}
        >
          {etiqueta}
        </Card>
      </TableCell>
    );
    return renglon;
  }

  function Renglon(props) {
    const { row } = props;
    let auxMuni = row.MunNom.charAt(0) + row.MunNom.slice(1).toLowerCase();
    let auxFReg =
      moment.utc(row.Fecha).format("YYYY-MM-DD HH:mm") === "1900-01-01 00:00"
        ? ""
        : moment.utc(row.Fecha).format("DD/MM/YYYY HH:mm");
    let btnBitacora =
      selectedID === row.Id ? (
        <AccessTimeIcon style={{ color: "white", width: 18 + "px" }} />
      ) : (
        <AccessTimeIcon style={{ width: 18 + "px" }} color="primary" />
      );
    let leyendaBitacora = `Bitácora de registro`;
    let btnInfo =
      selectedID === row.Id ? (
        <InfoIcon style={{ color: "white", width: 18 + "px" }} />
      ) : (
        <InfoIcon style={{ width: 18 + "px" }} color="primary" />
      );
    let leyendaInfo = `Información de registro`;
    let btnEditar =
      selectedID === row.Id ? (
        <EditIcon style={{ color: "white", width: 18 + "px" }} />
      ) : (
        <EditIcon style={{ width: 18 + "px" }} color="primary" />
      );
    let leyendaEditar = `Editar Estatus/Categoría`;
    return (
      <React.Fragment>
        <TableRow
          onClick={() => {
            seleccionado(row.Id, row);
          }}
          className={classes.tableRow}
          selected={selectedID === row.Id}
          classes={{ hover: classes.hover, selected: classes.selected }}
        >
          {celdaIco(leyendaEditar, abrirModalEditar, btnEditar, row)}
          {celdaIco(leyendaInfo, abrirModalInfo, btnInfo, row)}
          {celdaIco(leyendaBitacora, abrirModalBitacora, btnBitacora, row)}
          {celda(
            "center",
            { width: 160 + "px", fontSize: 12 + "px", paddingRight: 2 + "px" },
            auxMuni
          )}
          {celda(
            "center",
            { width: 120 + "px", fontSize: 12 + "px", paddingRight: 2 + "px" },
            auxFReg
          )}
          {/* celda(
            "center",
            { width: 120 + "px", fontSize: 12 + "px", paddingRight: 2 + "px" },
            row.Origen
          ) */}
          {tipoEdoProspecto(row.StatusColor, row.StatusNom)}
          {celda(
            "center",
            { fontSize: 12 + "px", paddingLeft: 8 + "px" },
            row.Tel
          )}
          {celda(
            "left",
            { width: 240, fontSize: 12 + "px", paddingLeft: 8 + "px" },
            row.Nombre
          )}
          {celda(
            "left",
            { fontSize: 12 + "px", paddingLeft: 8 + "px" },
            row.Email
          )}
          {celda("center", { fontSize: 12 + "px" }, row.Genero)}
          {tipoEdoProspecto(row.TipoColor, row.TipoNom)}
        </TableRow>
      </React.Fragment>
    );
  }

  const vacia = (
    <TableCell className={classes.tableCell} align="center">
      {" "}
    </TableCell>
  );
  const ColumnaVacia = (
    <TableCell
      className={classes.tableCell}
      align="center"
      style={{ backgroundColor: fondo, color: "white" }}
    >
      {" "}
    </TableCell>
  );

  function tablaCompleta(auxlista) {
    return (
      <TableContainer className={classes.container}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {ColumnaVacia}
              {ColumnaVacia}
              <TableCell
                align="center"
                padding={"none"}
                style={{ backgroundColor: fondo, color: "white" }}
              >
                {auxlista.length}
              </TableCell>
              {columnas.map((row) => {
                return (
                  <TableCell
                    style={{ backgroundColor: fondo, color: "white" }}
                    key={row.titulo + row.id}
                    align={row.alinear}
                    padding={"none"}
                  >
                    {row.titulo}
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {auxlista.length != 0
              ? auxlista.map((row) => (
                  <Renglon key={"regPerfilGen" + row.Id} row={row} />
                ))
              : null}
          </TableBody>
        </Table>
      </TableContainer>
    );
  }

  const tablaSinDatos = () => {
    return (
      <TableContainer className={classes.container2}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>              
              {columnas.map((row) => {
                return (
                  <TableCell
                    style={{ backgroundColor:fondo, color: "white" }}
                    key={row.titulo + row.id}
                    align={row.alinear}
                    padding={"none"}
                  >
                    {row.titulo}
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow className={classes.tableRow}>
              {vacia}
              {vacia}              
              <TableCell className={classes.tableCell} align="center">
                Sin{" "}
              </TableCell>
              {vacia}
              {vacia}
              <TableCell className={classes.tableCell} align="center">
                Registros
              </TableCell>
              {vacia}
              {vacia}
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    );
  };

  const contenido = datos.length !== 0 ? tablaCompleta(datos) : tablaSinDatos();
  //const tabla = espera ?  (<Box pt={3}><LinearProgress/> </Box>) :( contenido )
  const modEdit = modalEdit ? (
    <ModalSugerencia
      seleccionado={regisSelect}
      modalAbierto={modalEdit}
      setModalAbierto={setModalEdit}
      titulo={regisSelect.Nombre}
      listStatus={listStatus}
      listTipo={listTipo}
      setActualizar={setActualizar}
      actualizar={actualizar}
    />
  ) : null;

  const modBitacora = modalBitacora ? (
    <ModalBitacora
      seleccionado={regisSelect}
      modalAbierto={modalBitacora}
      setModalAbierto={setModalBitacora}
      titulo={regisSelect.Nombre}
    />
  ) : null;

  const modInfo = modalInfo ? (
    <ModalDetalles
      seleccionado={regisSelect}
      modalAbierto={modalInfo}
      setModalAbierto={setModalInfo}
      titulo={regisSelect.Nombre}
    />
  ) : null;

  return (
    <div style={{ minWidth: 80 + "rem", maxWidth: 80 + "rem" }}>
      {contenido}
      {modBitacora}
      {modInfo /*  */}
      {modEdit}
    </div>
  );
};

export default TablaSugePublico;
