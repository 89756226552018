export function numeroPag(lista,tamMax,setTotalPaginas,setNumPagina,setDatos) {
    if( lista.length > tamMax)
    {   let auxTam = lista.length;
        if( auxTam % tamMax === 0 )
        {   let pagina = Math.trunc( auxTam/tamMax )
            setTotalPaginas(pagina)   
        }
        else
        {   let pagina = Math.trunc( auxTam/tamMax )
            pagina = pagina + 1;
            setTotalPaginas(pagina)
        }
        setNumPagina(0) 
        setDatos(lista.slice(0,tamMax))
    }
    else
    {   setTotalPaginas(1)
        setNumPagina(0)
        setDatos(lista)
    }
}