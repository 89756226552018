import React,{useState,useEffect} from 'react';
import {Table,TableBody,TableCell,TableContainer,TableHead,TableRow,
        Card, Box,TextField,IconButton,Tooltip} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from "react-router-dom";
import axios from 'axios';
import qs from 'qs';
import NumberFormat from 'react-number-format';
import {llamaApiCargaToken,llamadaApiToken,ErrorGeneral } from '../funciones/ClienteAxios';
import XLSX from 'xlsx';
import moment from 'moment';
import SaveIcon from '@material-ui/icons/Save';
const useStyles = makeStyles({
    root: {
      width: '100%',
    },
    container: {
      minWidth: '550px',
      maxWidth:'100%',
      minHeight: 150,
      //minHeight: window.innerHeight-480,
     // backgroundColor:'white',
      boxShadow:'0 0.5em 1em -0.125em rgb(10 10 10 / 10%), 0 0 0 1px rgb(10 10 10 / 2%)',
    },
    formControl:{
        boxSizing:'border-box',
        fontSize:'1rem',
        position:'relative',
        marginBottom:'1em'
    },
    buscador:{
    width:'30em'
    ,marginLeft:'.8em'
    },
    estiloCardDatos:{        
        textAlign:'center',
        fontWeight:600,
        fontSize:.8+'rem'
    },
    titulos:{
        padding: 0.2 +'rem', 
        color:process.env.REACT_APP_Fondo_Color,
        fontWeight:600
    },
    elementos:{
        alignContent:'center',
        padding: 0.2 +'rem'
    },
    tableRow: {
      "&$selected, &$selected:hover": {
        backgroundColor: process.env.REACT_APP_Color_Second 
      }
    },
    tableCellNominal:{
        color:process.env.REACT_APP_Fondo_Color,
        "$selected &": {
            color: "white"
          }
      },
    tableCell: {
      "$selected &": {
        color: "white"
      }
    },
    hover: {},
    selected: {}
  });

  const columnas=[{
        id:4,
        titulo:"Municipio",
        alinea:'center'
      },
    {
        id:2,
        titulo:"LNom",
        alinea:'left'
    },
    {
        id:3,
        titulo:"Votos",
        alinea:'center'
    },
    {
        id:6,
        titulo:"Mayoría",
        alinea:'center'
    },
    {
        id:5,
        titulo:"Part",
        alinea:'center'
    },
];

//const alturaTabla = window.innerHeight<937 ? window.innerHeight-540 : window.innerHeight-720
const alturaTabla=window.innerHeight<937 ?  `calc(100vh - 29rem)`: `calc(100vh - 39rem)` // "24rem"
const alturaTabla2=window.innerHeight<937 ?  `calc(100vh - 33rem)`: `calc(100vh - 43rem)` // "24rem"
export default function TablaVotosGeneral({lista,setListaXMunicipio,setListaXSeccion,setTituloMunicipio,
                                            procesoSeleccionado,setMunicipioSeleccionado,setEspera,setMostrarTab2}){
    const classes = useStyles();
    let history = useHistory();
    const Dispvo =localStorage.getItem('Dispvo') ;    
    const auxDispV =Dispvo && Dispvo.length >= 12 ? Dispvo:'w8rf51v21dsd2cs'      
    const auxCol =process.env.REACT_APP_Fondo_Color
    const [municipio,setMunicipio] = useState({ nombre:''});
    const source = axios.CancelToken.source(); 
    const [datos,setDatos] = useState(lista)
    const [idSelec,setIdSelec]=  useState(-1);      
	const {nombre} = municipio;

    useEffect(()=>{
        setDatos(lista)
    },[lista])

    function getDatos(muni){
        
        const getTotal = () => {  
            setListaXMunicipio([])       
            const data = qs.stringify({
                periodo:procesoSeleccionado,
                municipio:muni,
                idDispositivo: auxDispV,
            });
            const url = "votacion-movil-municipio-total";
            function respuesta(aux) {
                if (aux) 
                {   setListaXMunicipio(aux);
                }       
            }
            llamaApiCargaToken(data, url, respuesta,ErrorGeneral,setEspera,history,source);
        };    

        const getVotos = () => {    
            setListaXSeccion([])      
            const data = qs.stringify({
                periodo:procesoSeleccionado,
                municipio:muni,
                idDispositivo: auxDispV,
            });
            const url = "votacion-web-municipio-seccion";
            function respuesta(aux) {
                if (aux) 
                {   setListaXSeccion(aux);
                }
            }
            llamadaApiToken(data, url, respuesta,ErrorGeneral,setEspera,history,source);
        };   
       // setEspera(true)
        getTotal()
        getVotos()
        //setEspera(false)
        setMostrarTab2(true)
    }

    function selecFila(reg,promNom){                
        setIdSelec(reg.muniId);
        setTituloMunicipio(promNom);
        setMunicipioSeleccionado(reg);
        getDatos(reg.muniId)
    }
    
    function generarColor(valor,votos){
        let colorEfecti='';    
        if(valor<30)
        {   colorEfecti= '#FF0505E8';        
        }
        else
        {   if(valor>=30 && valor<60)
            {   colorEfecti= '#FFC300';               
            }
            else
            {   if(valor>=60 )
                {   colorEfecti= '#1CC63D';
                }
            }
        }
        return(
        <Card className={classes.estiloCardDatos} 
            style={{backgroundColor:colorEfecti,padding:0,color:votos===0?"":"white"}}>
            <NumberFormat value= {votos} displayType={'text'}  thousandSeparator={true}/>
        </Card>)
    }
      
    const celda = (alinear, estilo, contenido,index) => {
        return (
        <TableCell align={alinear} key={index} className={classes.tableCell} 
            padding={'none'} style={estilo} >
            {contenido}
        </TableCell>)
    }
    const CelTitl=({index,titulo,aline})=> celda(aline?aline:"center", {backgroundColor:auxCol,color:'white',}, titulo?titulo:"",index) 

    const exportarArch = () => {
        let today = new Date();                    
        let fec = moment(today);
        let miArr=[]
        const info=datos.filter((dat)=>dat.muniNom.toUpperCase().search(nombre.trim().toUpperCase() ) !== -1) 
        miArr.push(["#","Municipio","LNominal","Votos","Mayoria","Partido"]);
        if (info.length!==0) 
        {            
            info.forEach((filtro , index)=> {
                const auxi=[index+1,filtro.muniNom,filtro.lNominal,filtro.votacion,filtro.partVoto,filtro.partNom]
                miArr.push(auxi)    
            }); 
        }
        else
        {   
            if (datos.length!==0) {
                info.forEach((filtro , index)=> {
                    const auxi=[index+1,filtro.muniNom,filtro.lNominal,filtro.votacion,filtro.partVoto,filtro.partNom]
                    miArr.push(auxi)    
                }); 
            }else{
                miArr.push(["","Sin","Tipos"])
            }
            
        }
        const ws = XLSX.utils.aoa_to_sheet(miArr);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "Reporte");
        XLSX.writeFile(wb, fec.format("YYMMDDHHmmss")+" Votacion de Municipios.xlsx")
    };

    const onChange = e =>
    {   const expNopermitida = new RegExp('[0-9._:$!%-({})?/*|]');
        const expMenos = new RegExp('-');
        const expMas = new RegExp('[+]');     
        const expCadena = new RegExp('[A-Za-z]');
        if ((expCadena.test(e.target.value) && !expNopermitida.test(e.target.value) 
            && !expMenos.test(e.target.value) &&!expMas.test(e.target.value))
            ||e.target.value===' '||e.target.value==='') 
        {
            setMunicipio({ ...municipio, [e.target.name] : e.target.value })
        }        
    };

    function sinDatos(){
        return(
        <TableContainer className={classes.container} style={{height:150}}>
        <Table stickyHeader aria-label="sticky table">
            <TableHead>
                <TableRow >                                                                        
                    {columnas.map(row=>{
                        return <CelTitl index={row.id} titulo={row.titulo} aline={row.alinea} />  
                    })}                                    
                </TableRow>                        
            </TableHead>  
            <TableBody> 
                {celda("center", {}, "Sin",1)}
                {celda("center", {}, "",2)}
                {celda("center", {}, "Resultados",3)}                
                {celda("center", {}, "",4)}                                
                {celda("center", {}, "Disponibles",5)}                                                                
            </TableBody>
        </Table>
        </TableContainer>
        )
    }
    
    function filtro(){        
        let auxCadena=nombre.trim();         
        let info=datos.filter((dat)=>dat.muniNom.toUpperCase().search(auxCadena.toUpperCase() ) !== -1)              
        return generarTabla(info);
    }

    function generarTabla(list) {
        
        return(
        <TableContainer className={classes.container} style={{height: alturaTabla2}}>
        <Table stickyHeader aria-label="sticky table">
            <TableHead>
                <TableRow >                                                                          
                    {columnas.map(row=>
                        <CelTitl index={row.id} titulo={row.titulo} aline={row.alinea} />  
                    )}                                    
                </TableRow>                        
            </TableHead>  
            <TableBody> 
            {list.map((municipio,index) => {  
                let auxNombre="";
                auxNombre= municipio.muniNom;                
                return(    
                <TableRow className={classes.tableRow} selected={idSelec === municipio.muniId}  key={index}
                    classes={{ hover: classes.hover, selected: classes.selected }} onClick={ () => selecFila(municipio) }
                > 
                    {celda("left", {fontSize:'12px',width:9+'rem',paddingLeft:'.8em'}, auxNombre,1)}
                    {celda("left", {color:idSelec === municipio.muniId ?"":auxCol,fontSize:'13px',width:1.5+'rem',fontWeight:600,paddingLeft:'.5em'}, 
                        <NumberFormat value= {municipio.lNominal} displayType={'text'}  thousandSeparator={true}/>
                    ,2)}
                    {celda("center", {width:2.5+'rem'}, 
                        generarColor((municipio.votacion/municipio.lNominal)*100 , municipio.votacion),3)}
                    {celda("center", {fontSize:'14px',width:1.8+'rem'}, <b>{municipio.partVoto}</b>,4)}
                    {celda("center", {width:25}, 
                        <img src= {`./partidos/${municipio.partImgWeb}`}  alt="" border="0" height="25" width="25"/>
                    ,5)}                                                
                </TableRow>)
            })} 
            </TableBody>
        </Table>
        </TableContainer>
        )   
    }
    const auxEstil={marginLeft:'.3em',marginTop:'.5em',width:'20px', height:'20px'}
    const contenido = nombre.length? (filtro()):(generarTabla(datos));
    const validaCont = datos.length!==0 ? contenido : sinDatos();
    return (
    <Box display={'flex'} flexDirection="row">
    
        <Card style={{marginRight:'.7em', height: lista.length>0 ? alturaTabla : 150}}>
            <Box style={{marginLeft:".5rem",marginTop:".5rem"}}>            
            <TextField className={classes.formControl}
                name="nombre" placeholder="Buscar Municipio"
                value={nombre} onChange={onChange}/>
            <IconButton  onClick={()=>{}} style={auxEstil}>
                <Tooltip title="Exportar Municipio">
                    <SaveIcon color="primary"/>
                </Tooltip>
            </IconButton>
            </Box>
            {validaCont}
        </Card>       
    
    </Box>
    );
}
 