import React,{useState} from 'react';
import {Box} from "@material-ui/core";
import MGraficaTotal from './MGraficaTotal';
import TablaMunicipios from './MTablaMunicipios';
export default function PanelMovilizacion({datos,setActualizar,actualizar}){
    const [promvSeccion,setPromvSeccion] =  useState(0);
    return(
        <Box display="flex" flexDirection="row" style={{marginTop:'1em',marginLeft:'3em'}}>
            <MGraficaTotal valor={datos} descripcion="Movilización" seccion={promvSeccion}/>
            <TablaMunicipios datos={datos} setActualizar={setActualizar} actualizar={actualizar} setPromovSec={setPromvSeccion}/>
        </Box>
    )
}