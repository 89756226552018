import React from 'react'
import {Table,TableBody,TableCell,TableContainer,TableHead,TableRow
        ,Box, Paper,Card,Tooltip} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';        
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord'; 
import NumberFormat from 'react-number-format';
const useStyles = makeStyles({
    root: {
        width: '100%',
    },     
    estiloCard:{
        width: '28.5rem',
        boxShadow:'0 0.5em 1em -0.125em rgb(10 10 10 / 10%), 0 0 0 1px rgb(10 10 10 / 2%)',
        color:'#4a4a4a',
        display:'block',
        
        maxHeight:'100%',
        minHeight: 280,
        marginRight:'1rem'
    },        
    tablaContainer: {
        width: '28.5rem',
        maxHeight: window.innerHeight-170,
        minHeight: 280,
        //minWidth:'100%',
        }, 
    tableRow: {
        "&$selected, &$selected:hover": {
        backgroundColor:process.env.REACT_APP_Color_Second
        }
    },
    tableCell: {
        "$selected &": {
        color: "white"
        }
    },
    hover: {},
    selected: {}        
});
const TablaMunxReg = ({seleccionado,setMuniSelecc,listPart}) => {
    const classes = useStyles();
    const [listMuni,setListMuni]=React.useState([])
    const [totales,setTotales]=React.useState({totalMeta:0,totalVal:0,totalPend:0})
    const {totalMeta,totalVal,totalPend}=totales
   // const [auxSeleccionado,setAuxSeleccionado] = React.useState([]);
    const [idSelec,setIdSelec] = React.useState(-1)

    React.useEffect(()=>{
       
       if (seleccionado.regionId!==0) {
            setListMuni(seleccionado.municipios)
            let  totMeta=0,totVal=0,totPend=0
            seleccionado.municipios.forEach(element => {
                totMeta=totMeta+element.Meta
                totVal=totVal+element.Valido
                totPend=totPend+element.Pendiente
                
            });            
            setTotales({totalMeta:totMeta,totalVal:totVal,totalPend:totPend})
       }

        //console.log(seleccionado);
        //console.log(listPart);
    },[seleccionado])

    const RenglonNum =(valorId,regis)=>{        
        //console.log(regis);     
        setIdSelec(valorId);        
        setMuniSelecc(regis);
    }

    const CeldaTitulo=(contenido,alinear,estilos)=>{
        return(
        <TableCell padding='none' align={alinear} style={estilos} >
            {contenido} 
        </TableCell> 
        )
    }
    const CeldaTitulo2=(contenido,total,alinear,estilos)=>{
        return(
        <TableCell padding='none' align={alinear} style={estilos} >
            {contenido} {numeroFormato(total)}
        </TableCell> 
        )
    }

    const CeldaContenido=(contenido,alinear,estilos)=>{
        return(
        <TableCell className={classes.tableCell} padding='none' align={alinear} 
            style={estilos} size="small"
        >
            {contenido} 
        </TableCell> 
        )
    }
    const colorPartido=(auxColor)=>{
        let auxNom=""
        let arre=listPart.length >0 ?listPart:[]
        if (arre.length>0) 
        {
            let auxFiltrado=arre.filter((partido)=>partido.PartColor===auxColor)    
            auxNom= auxFiltrado[0].PartNom
        }
        return(
        <Tooltip title={auxNom}>
            <FiberManualRecordIcon fontSize="inherit"  style={{color:auxColor }} />
        </Tooltip>
        )
    }
    const numeroFormato=(datos)=>{
        return(
            <NumberFormat value={datos} displayType={'text'}  thousandSeparator={true}/>
        )
    }
    
    function TablaMunicipios(auxDatos,total) {        
        const estiloGeneral={backgroundColor:process.env.REACT_APP_Fondo_Color,color:'white'
                            , borderBottom:"1px solid #2A3345"}                
        return(        
        <TableContainer className={classes.tablaContainer} id="tamTablaMunicipios" 
            style={{height:8+'rem'}}
        >
        <Table stickyHeader aria-label="sticky table">
            <TableHead >    
                <TableRow> 
                    {CeldaTitulo("Gob","center",estiloGeneral)}  
                    {CeldaTitulo(total+" Municipios","center",estiloGeneral)}                    
                    {CeldaTitulo2("Meta",totalMeta,"center",estiloGeneral)}                               
                    {CeldaTitulo2("Valido",totalVal,"center",estiloGeneral)}
                    {CeldaTitulo2("Pendiente",totalPend,"center",estiloGeneral)}  
                </TableRow>                        
            </TableHead>  
            <TableBody>                                  
            {auxDatos.map((municipio,index) => {                                                    
                return (                  
                <TableRow className={classes.tableRow} selected={ idSelec === municipio.MuniId }  
                    classes={{ hover: classes.hover, selected: classes.selected }} 
                    key={index} onClick={ () => RenglonNum(municipio.MuniId,municipio) }  
                >   
                    {CeldaContenido(colorPartido(municipio.PartColor),"center",{})}                                               
                    {CeldaContenido(municipio.MuniNom.charAt(0)+ municipio.MuniNom.slice(1).toLowerCase(),"left",{paddingLeft:0.5+'rem'})}                           
                    {CeldaContenido(numeroFormato(municipio.Meta),"center",{paddingLeft:0.5+'rem'})}                                                                   
                    {CeldaContenido(numeroFormato(municipio.Valido),"center",{paddingLeft:0.5+'rem'})}                                               
                    {CeldaContenido(numeroFormato(municipio.Pendiente),"center",{paddingLeft:0.5+'rem'})}                                                                                                                                                     
                </TableRow>              
                );                        
            })}                                                
            </TableBody>
        </Table>
        </TableContainer>  
        ); 
    }

    const tabla = listMuni.length !== 0 ? TablaMunicipios(listMuni,listMuni.length):null
    return (
    <Box mt={3} pl={2}>
        <Card className={classes.estiloCard} >
       <Paper className={classes.table}>
        {tabla}
       </Paper>
       </Card>
    </Box>
    )
}

export default TablaMunxReg