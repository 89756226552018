import React,{useState,useEffect} from 'react'
import {Box, TextField,FormControl, Tooltip,LinearProgress,
        Select,MenuItem,InputLabel, Card,Typography,FormLabel,
        RadioGroup,Radio,FormControlLabel} from '@material-ui/core' 
import Autocomplete from "@material-ui/lab/Autocomplete";
import axios from 'axios';
import qs from 'qs';
import { useHistory } from "react-router-dom";
import Modal from '../generales/ModalCerrarActualiza';
import moment from 'moment'
import {llamaApiCargaToken,ErrorGeneral,llamadaApiCarga } from '../funciones/ClienteAxios';
import { toast } from 'react-toastify'; 


const ModalNvoEdit = ({modalAbierto,setModalAbierto ,titulo,listTipo,auxReg,getDatos}) => {
    let history = useHistory();    
    const fechaMax = moment(new Date()).subtract(18,'years')  
    const usuId =localStorage.getItem('UsuId') ;                
    const [espera,setEspera]= useState(false);
    const [mensaje, setMensaje] = useState("");
    const [tipoAdvertencia, setTipoAdvertencia] = useState("success");
    const [btnDeshabilitado,setBtnDeshabilitado]= useState(false)
    const [aviso, setAviso] = useState(false);      
    const[cambioEfectivo,serCambioEfectivo] = useState(false)    
    const[nvoRegistro, setNvoRegistro] = useState([])      
    
    const[registro, setRegistro] = useState({Nombre:'', Email:'' ,OrdenReg:0,
        Tel:'', Llave:0,Tipo:null, NvlSuper:null, Suplente:"", SuplenteTel:"",SuplenteEmail:""})  
    const{Llave,  Nombre,Email,Tel, Tipo, NvlSuper, Suplente, OrdenReg,
            SuplenteTel, SuplenteEmail}=registro    
    const source = axios.CancelToken.source();  
    let Dispvo =localStorage.getItem('Dispvo') ;    
    let auxDispV =Dispvo.length >= 12 ? Dispvo:'451224452121'  
    
    useEffect(()=>{    
        console.log(auxReg);
        if (auxReg) 
        {   const{Freg, Id, Jefe, JefeEmail, JefeImg, JefeImgMini,             
            JefeTel, Orden, Padre, Supl, SuplEmail, SuplImg, SuplImgMini,
            SuplTel, TpId, TpNom}=auxReg.original
            setRegistro({...registro,Nombre:Jefe, Email:JefeEmail ,
                Tel:JefeTel, Llave:Id, Tipo:TpId, NvlSuper:Padre, Suplente:Supl, 
                SuplenteTel:SuplTel, SuplenteEmail:SuplEmail,OrdenReg:Orden })    
        }
        
    },[auxReg])

    const onChange = e =>
	{   
        let expNopermitida = new RegExp('[%{}*|`]');  
        let expMenos = new RegExp("'");
        let expMas = new RegExp('"');             
        let expCadena = new RegExp('[A-Za-z0-9$.@#_&-+()/:;!?,<>=]');  
        if ( ( expCadena.test(e.target.value) && !expNopermitida.test(e.target.value) 
                && !expMenos.test(e.target.value) && !expMas.test(e.target.value) ) 
                || e.target.value===' '|| e.target.value==='') 
        {
            setRegistro({
                ...registro, 
                [e.target.name] : e.target.value//.toUpperCase()  
            })            
        }		
	};             

    const onChangeNumTel = e =>
	{   let expNopermitida= new RegExp('[A-Za-z$@#_&-+()/:;!?,<>=]');          
        let expMenos = new RegExp("'");
        let expMas = new RegExp('-');     
        let expCadena = new RegExp('[.0-9$]');             
        if (  e.target.value.length<=10 &&( expCadena.test(e.target.value) && !expNopermitida.test(e.target.value) 
            && !expMenos.test(e.target.value) && !expMas.test(e.target.value) ) 
            || e.target.value===' '|| e.target.value==='') 
        {
            setRegistro({
                ...registro, 
                [e.target.name] : e.target.value//.toUpperCase()  
            })                       
        }		    

	}; 
       
    const llamadaNvoPerfil = () => {                    
        const data = qs.stringify({
            Usuario:usuId,
            Llave:Llave,
            Tipo:Tipo,                                                                            
            Status:1,
            Padre:NvlSuper,
            Orden:OrdenReg,
            Jefe:Nombre,
            JefeTel:Tel,
            JefeEmail:Email,
            Suplente:Suplente,
            SuplenteTel:SuplenteTel,
            SuplenteEmail: SuplenteEmail ,
            idDispositivo: auxDispV,
        });
        const url = "comite-new-edit";
        function respuesta(aux) {
            if (aux.length!==0)
            {   setBtnDeshabilitado(true)                                             
                const mensaje=auxReg ?"Se edito correctamente":"Se registro correctamente"
                setTipoAdvertencia("success")      
                setMensaje(mensaje);                
                setAviso(false);
                toast.success(mensaje,{
                    pauseOnFocusLoss: false,                    
                    toastId: `edit-comit${1}`
                })          
                getDatos()      
                setModalAbierto(false);
                setNvoRegistro(aux)
                serCambioEfectivo(true) 
            }
        }
        llamaApiCargaToken(data, url, respuesta,ErrorGeneral,setEspera,history,source);
    };

    const onChangeCorreo = e =>
	{   let expNopermitida = new RegExp('[%{}*|`]');  
        let expMenos = new RegExp("'");
        let expMas = new RegExp('"');             
        let expCadena = new RegExp('[A-Za-z0-9$@#_&-+()/.;!?,<>=]');  
        if ( ( expCadena.test(e.target.value) && !expNopermitida.test(e.target.value) && !expMenos.test(e.target.value) &&
            !expMas.test(e.target.value) ) || e.target.value===' '|| e.target.value==='') 
        { 
            setRegistro({
                    ...registro, 
                    [e.target.name] : e.target.value  
                })
            if (aviso) 
            {   setMensaje('');
                setAviso(false);
            }
        }		
	};    

    const verificaNulos = (campo,descripcion)=>{
        let respuesta =false;                  
        if( campo===null || (typeof campo !== 'number' && typeof campo === 'string' 
            && (campo.trim() === '' || campo.trim() === null) )
        ) 
        {   respuesta=true;                                   
            setMensaje('No se lleno el campo '+descripcion);
            setTipoAdvertencia("warning")  
            setAviso(respuesta);
        }
        return(respuesta);
    }     

    const verificaNulos2 = (campo,descripcion)=>{
        let respuesta =false;        
          
        if( (typeof campo !== 'number' && typeof campo === 'string' 
            && (campo.trim() === '' || campo.trim() === null) )
        ) 
        {   respuesta=true;                                   
            setMensaje('Debe llenarse el campo '+descripcion);
            setTipoAdvertencia("warning")  
            setAviso(respuesta);
        }
        return(respuesta);
    }    

    const mandaAlerta=(mensaje,tipoAdvert)=>
    {   setMensaje(mensaje);
        setTipoAdvertencia(tipoAdvert)  
        setAviso(true);      
    }
      
    function validaVariable(auxCampo,auxMensaje,auxTamanio) 
    {   let respuesta=false
        if ( !verificaNulos(auxCampo,auxMensaje) ) 
        {   if (auxCampo.length>=auxTamanio ) 
            {                
                respuesta=true                    
            }else
            {            
                mandaAlerta('Campo '+auxMensaje +' incompleto, debe escribir un '+ auxMensaje+' valido',"warning") 
            }
        }        
        return respuesta;
    }
 
    function guardarDatos(e)
    {   e.preventDefault()                        
        if (!verificaNulos(NvlSuper,"Nivel Superior")  && validaVariable(Nombre,'Titular',5)                         
            && !verificaNulos(Tipo,"Tipo")   //&& validaVariable(Tel,'Telefono',10) ) 
        )
        {   setMensaje('');                
            setAviso(false);                                                      
            llamadaNvoPerfil()                               
        }                                  
    } 

    const onChangeSel=(e)=>{
        if (e!== null && e!== undefined) 
        {  setRegistro({...registro,[e.target.name] : e.target.value})
        }
        else
        {setRegistro({...registro,[e.target.name] : 0})
        }         
    }

    const DatosGenerales=(
        <div>            
            <Box   pt={1} mb={1} display="flex" flexDirection={"column"}>
                {listTipo.length!==0 ?                
                <Box display={"flex"} mb={2}>
                <FormControl size='small'  style={{ minWidth:30+'rem'}} >
                    <InputLabel size='small' variant="outlined" id="NvlSuper-label-nvo">Nivel Superior</InputLabel>
                    <Select  size='small' variant="outlined"
                        label="Nivel Superior" id="NvlSuper-select2" name="NvlSuper"
                        value={NvlSuper} onChange={onChangeSel}
                    >
                        {listTipo.map((tipo, ) => {
                            if(tipo.Id>0) {
                            return(
                            <MenuItem size='small' value={tipo.Id} key={tipo.Id}>
                                <em>{tipo.Nom }</em>
                            </MenuItem>
                            )}
                        } )}                    
                    </Select>
                    </FormControl>
                    </Box>
                :   null
                }
               
                {listTipo.length!==0 ?                
                <Box display={"flex"} mb={1}>
                <FormControl size='small'  style={{ minWidth:30+'rem'}} >
                    <InputLabel size='small' variant="outlined" id="Tipo-label-nvo">Tipo</InputLabel>
                    <Select  size='small' variant="outlined"
                        label="Tipo" id="Tipo-select2" name="Tipo"
                        value={Tipo} onChange={onChangeSel}
                    >
                        {listTipo.map((tipo, ) => {
                            if(tipo.Id>0) {
                            return(
                            <MenuItem size='small' value={tipo.Id} key={tipo.Id}>
                                <em>{tipo.Nom }</em>
                            </MenuItem>
                            )}
                        } )}                    
                    </Select>
                    </FormControl>
                    </Box>
                :   null
                }
            </Box>                           
            <TextField required id="Titular-input" onChange={onChange} style={{paddingBottom:.5+'rem',width:30+'rem'}}
                label="Titular" value={Nombre} name='Nombre'size="small" autoComplete='off' // defaultValue="Nombre"                
                //auxArrerror={arrError[3]} onBlur={()=>verifiNombre()}
            />            
            <br/>                           
            <TextField  id="CInt-input" onChange={onChangeNumTel} style={{marginRight:2+'rem',width:8+'rem'}}
                label="Teléfono" defaultValue="Tel" value={Tel} name='Tel' size="small" type="number"
                inputProps= {{ maxlength: 10 }} autoComplete='off'
            /> 
            <TextField  id="Email-input" onChange={onChangeCorreo} style={{paddingBottom:1+'rem',width:19+'rem'}}
                label="Email"  value={Email} name='Email' size="small" autoComplete='off'
            /> 
            <br/>
            <TextField   id="Suplente-input" onChange={onChange} style={{paddingBottom:.5+'rem',width:30+'rem'}}
                label="Suplente" value={Suplente} name='Suplente'size="small" autoComplete='off'                 
            />            
            <br/>                           
            <TextField  id="Suplente-tel" onChange={onChangeNumTel} style={{marginRight:2+'rem',width:8+'rem'}}
                label="Teléfono"  value={SuplenteTel} name='SuplenteTel' size="small" type="number"
                inputProps= {{ maxlength: 10 }} autoComplete='off'
            /> 
            <TextField  id="Suplente-email" onChange={onChangeCorreo} style={{paddingBottom:1+'rem',width:19+'rem'}}
                label="Email"  value={SuplenteEmail} name='SuplenteEmail' size="small" autoComplete='off'
            />                 
        </div> 
    )     

    const cerrarBorrar = () => {            
        setAviso(false);
        setModalAbierto(false);       
    };

    return (    
    <Modal
        titulo={titulo} modalAbierto={modalAbierto}
        setModalAbierto={setModalAbierto} guardarDatos={guardarDatos}
        mensaje={mensaje} tipoAdvertencia={tipoAdvertencia}
        aviso={aviso} btnDeshabilitado={btnDeshabilitado}
        setAviso={setAviso} tamanio={'md'} cargando={espera}     
        cerrarBorrar={cerrarBorrar}   
    >
        {DatosGenerales}
    </Modal>             
    )
}

export default ModalNvoEdit