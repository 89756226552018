import {makeStyles} from "@material-ui/core/styles";
import Modal from "../generales/ModalCerrarActualiza";
import {useEffect, useState} from "react";
import {FormControl, Grid, InputLabel, LinearProgress, MenuItem, Select, 
        TextField,InputAdornment,IconButton,Input,
        } from "@material-ui/core";
import qs from "qs";
import {ErrorGeneral, llamaApiCargaToken,llamadaApiToken} from "../funciones/ClienteAxios";
import Autocomplete from "@material-ui/lab/Autocomplete";
import moment from "moment/moment";
import {DatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import DateFnsUtils from '@date-io/date-fns';
import esLocale from "date-fns/locale/es";
import {toast} from "react-toastify";
import axios from "axios";
import {useHistory} from "react-router-dom";
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

const useStyles = makeStyles((theme) => ({}));

const ModalNvoEdit = ({abrirModal, setAbrirModal, registro ,listMun ,actualiza,titulo}) => {
    const history = useHistory();
    const classes = useStyles();
    const source = axios.CancelToken.source();    
    const idusu = localStorage.getItem("UsuId");
    const fechaMax = moment(new Date()).subtract(18,'years')  
    const fecha = Date.now()
    const [selectedDate, handleDateChange] = useState(fechaMax);
    //const [listMun, setListMun] = useState([])
    const [listSecc, setListSecc] = useState([])
    const [auxListSecc, setAuxListSecc] = useState([])
    const [listCoordEst, setListCoordEst] = useState([])
    const [nuevoRegistro, setNuevoRegistro] = useState({        
        Municipio: '', Seccion: '', Direccion: '',  
        UsrBase:0, OrgId:0, Superior:"", Status:0,
        Alias:"", Nombre:"", Usuario:"", Pass:"",
        Email:"", Tel:"", Ine:"",      
    });
    const { Municipio, Seccion,  Direccion,  UsrBase,
        OrgId, Superior, Status, Alias, Nombre, Usuario,
        Pass, Email, Tel, Ine } = nuevoRegistro
    const [btnDeshabilitado, setBotonDeshabilitado] = useState(false)
    const [aviso, setAviso] = useState(false);
    const [mensaje, setMensaje] = useState("");
    const [tipoAdvertencia, setTipoAdvertencia] = useState("success");
    const [cargando, setCargando] = useState(false);
    const[visiblePwd,setVisiblePwd]= useState(false)      

    const handleClose = () => {
        setAbrirModal(false)
        setAviso(false);
    }

    useEffect(() => {       
        console.log(registro);
        if (registro) {
            recuperarPass()
            handleDateChange(moment(registro.FNac))
            
        }
        else
        {
            if (listSecc.length === 0) 
            {   recuperarSecciones()
            }
        }              
        
        if (listCoordEst.length === 0) {
            recuperarTipo()
        }

        return ()=> {
            source.cancel();
        }    
    }, []);

    const recuperarSecciones = (auxMun) => {
        const data = qs.stringify({
            usuario: idusu,
            idDispositivo: 'w8rf51v21dsd2cs',
        });
        const url = "lugar-seccion-list";

        function respuesta(response) {
            if (response[0].Id != -1) {
                setListSecc(response)
                if (auxMun) {
                    const seccFilt=response.filter((auxSeccion)=>auxSeccion.MuniId===auxMun[0].Id)             
                    setAuxListSecc(seccFilt)    
                }
            }
        }

        llamaApiCargaToken(data, url, respuesta, ErrorGeneral, setCargando, history, source);
    };

    const recuperarTipo = () => {
        const data = qs.stringify({
            Usuario: idusu,            
            idDispositivo: '45vf2d1vdf21d2f'
        });
        const url = "organizacion-superior-list";

        function respuesta(response) {
            if (response[0].Id != -1) {
                setListCoordEst(response)
            }
        }
        llamaApiCargaToken(data, url, respuesta, ErrorGeneral, setCargando, history, source);
    };

    const recuperarPass = () => {
        const data = qs.stringify({
            Usuario: idusu,
            UsrOrganizacion:registro.UsrOrg,
            idDispositivo: '45vf2d1vdf21d2f'
        });
        const url = "organizacion-pass";

        function respuesta(response) {
            
            if (response.Pass ) {
                const auxMun=listMun.filter((auxM)=>auxM.Id===registro.MuniId)        
                setNuevoRegistro({...nuevoRegistro,
                    Municipio: auxMun[0], Seccion: registro.SeccId,
                    UsrBase:registro.UsrOrg, OrgId:registro.Id, Superior:registro.JefeId, Status:1,
                    Alias:registro.Alias, Nombre:registro.Responsable, Usuario:registro.UsrOrgUsr, Pass:response.Pass,
                    Email:registro.Email, Tel:registro.Tel, Ine:registro.Ine ,
        
                })  
                recuperarSecciones(auxMun)        
            }
        }
        llamadaApiToken(data, url, respuesta, ErrorGeneral,  history, source);
    };

    const guardarMuni = (e) => {
        if (e !== null && e !== undefined) {
            let seccFilt = listSecc.filter((auxSeccion) => auxSeccion.MuniId === e.Id)
            setAuxListSecc(seccFilt)
            setNuevoRegistro({
                ...nuevoRegistro,
                Municipio: e,
                Seccion: ""
            })
            setMensaje('');
            setAviso(false);
        } else {
            setNuevoRegistro({
                ...nuevoRegistro,
                Municipio: "", Seccion: ""
            })
            setAuxListSecc([])
        }
    }

    const guardarSeccionSelect = (e) => {
        if (e !== null && e !== undefined) {
            setNuevoRegistro({
                ...nuevoRegistro,
                Seccion: e.target.value
            })
            setMensaje('');
            setAviso(false);
        } else {
            setNuevoRegistro({
                ...nuevoRegistro,
                Seccion: ""
            })
        }
    }

    const onChangeNumTel = e =>
	{   const expNopermitida= new RegExp('[A-Za-z.$@#_&-+()/:;!?,<>=]');          
        const expMenos = new RegExp("'");
        const expMas = new RegExp('-');     
        const expCadena = new RegExp('[0-9]');             
        if (  e.target.value.length<=10 &&( expCadena.test(e.target.value) && !expNopermitida.test(e.target.value) 
            && !expMenos.test(e.target.value) && !expMas.test(e.target.value) ) 
            || e.target.value==='') 
        {
            setNuevoRegistro({
                ...nuevoRegistro, 
                [e.target.name] : e.target.value//.toUpperCase()  
            })                       
        }		    

	}; 

  const onChangeSel = e => {
        setNuevoRegistro({
            ...nuevoRegistro,
            [e.target.name]: e.target.value
        })
    }  

    const valGen=(e, expNopermitida,expMenos,expMas,expCadena)=>{
        if ( ( expCadena.test(e.target.value) && !expNopermitida.test(e.target.value) 
        && !expMenos.test(e.target.value) && !expMas.test(e.target.value) ) 
        || e.target.value===' '|| e.target.value==='') 
        {
            setNuevoRegistro({
                ...nuevoRegistro, 
                [e.target.name] : e.target.value//.toUpperCase()  
            })            
        }		
    }

    const valGen2=(e, expNopermitida,expMenos,expMas,expCadena)=>{
        if ( ( expCadena.test(e.target.value) && !expNopermitida.test(e.target.value) 
        && !expMenos.test(e.target.value) && !expMas.test(e.target.value) ) 
        || e.target.value==='') 
        {
            setNuevoRegistro({
                ...nuevoRegistro, 
                [e.target.name] : e.target.value//.toUpperCase()  
            })            
        }		
    }

    const onChange = e =>
	{   const expNopermitida = new RegExp('[@$#._:!%-({})/*=?|]');
        console.log(e.target.value,e.target.name);        
        console.log("antes del colsol"); 
        const expMenos = new RegExp("'");
        const expMas = new RegExp('"');             
        const expCadena = new RegExp('[A-Za-z0-9.#_]');                 
        valGen(e, expNopermitida,expMenos,expMas,expCadena)
	};

    const onChangeCorreo = e =>
	{   const expNopermitida = new RegExp('[$#:!%-({})/*=?|]');
        const expMenos = new RegExp("'");
        const expMas = new RegExp('"');             
        const expCadena = new RegExp('[A-Za-z0-9.@_]');  
        valGen(e, expNopermitida,expMenos,expMas,expCadena)        
	};

    const onChangeTxt = e =>
	{   const expNopermitida = new RegExp('[@$#._:!%-({})/*=?|]');
        const expMenos = new RegExp("'");
        const expMas = new RegExp('"');             
        const expCadena = new RegExp('[A-Za-z]');  
        valGen(e, expNopermitida,expMenos,expMas,expCadena)        
	};

    const onChangeTxt2 = e =>
	{   const expNopermitida = new RegExp('[@$#._:!%-({})/*=?|]');
        const expMenos = new RegExp("'");
        const expMas = new RegExp('"');             
        const expCadena = new RegExp('[A-Za-z0-9]');  
        valGen2(e, expNopermitida,expMenos,expMas,expCadena)        
	};

    const guardarRecorrido = () => {
        setCargando(true)
        setBotonDeshabilitado(true);

        const data = qs.stringify({                        
            UsrSolicita:idusu,
            UsrBase: registro ? UsrBase : -1,
            OrgId: registro ? OrgId : 0,
            Superior:Superior,
            Municipio:Municipio.Id,
            Seccion:Seccion,
            Status:Status,
            Alias:Alias,
            Nombre:Nombre,
            Usuario:Usuario,
            Pass:Pass,
            Email:Email,
            Tel:Tel,
            Ine:Ine,
            Fnac: moment.utc(selectedDate).format("YYYY-MM-DD") + 'T00:00:00',
            idDispositivo: '45vf2d1vdf21d2f'
        });

        const url = "organizacion-new-edit";

        function respuesta(response) {
            if (response.respuesta === 1) {
                setAviso(true)
                const mensaje = registro ? "Se edito correctamente" : 'Organización agregado correctamente'
                toast.success(mensaje, {
                    pauseOnFocusLoss: false,
                    toastId: `nvo-recorrido${1}`
                })
                actualiza()
                setAbrirModal(false)
            } else {
                setBotonDeshabilitado(false);
                toast.error('Datos incorrectos, intente mas tarde', {
                    pauseOnFocusLoss: false,
                    toastId: `error-recorrido${1}`
                })
            }
        }

        llamaApiCargaToken(data, url, respuesta, ErrorGeneral, setCargando, history, source);
    }

    const verificaNulos = (campo,descripcion)=>{
        let respuesta =false;        
          
        if( (typeof campo !== 'number' && typeof campo === 'string' 
            && (campo.trim() === '' || campo.trim() === null) )
        ) 
        {   respuesta=true;        
            //console.log(campo+' '+descripcion);                  
            setMensaje('No se lleno el campo '+descripcion);
            setTipoAdvertencia("warning")  
            setAviso(respuesta);
        }
        return(respuesta);
    }

    function guardarDatos(e) {
        e.preventDefault()
        if (  !verificaNulos(Municipio,"Municipio") && !verificaNulos(Seccion,"Sección")  && !verificaNulos(Superior,"Superior") 
            && !verificaNulos(Alias,"Alias") && !verificaNulos(Nombre,"Nombre") && !verificaNulos(Usuario,"Usuario")
            && !verificaNulos(Pass,"Contraseña") && !verificaNulos(Tel,"Teléfono") && !verificaNulos(selectedDate,"Fecha Naciemiento")     
        ) 
        {   //console.log("prueba",nuevoRegistro);
            setAviso(false);
            guardarRecorrido()
        }  
    }

    return (
    <Modal
        titulo={titulo} modalAbierto={abrirModal} mensaje={mensaje}
        setModalAbierto={setAbrirModal} guardarDatos={guardarDatos}
        tipoAdvertencia={tipoAdvertencia} aviso={aviso} tamanio={'sm'}
        btnDeshabilitado={btnDeshabilitado} setAviso={setAviso}
        cargando={cargando} cerrarBorrar={handleClose}
    >
        <Grid container alignItems={'center'} spacing={1}>
            
            <Grid item xs={8}>
                {(registro && listMun.length !== 0 && Municipio!=="" )||  listMun.length !== 0 ?
                    <Autocomplete
                        id="muni-select-nvoPerfil" size='small' fullWidth
                        onChange={(e, option) => guardarMuni(option)} value={Municipio}
                        getOptionLabel={(option) => option.Nom} name="Municipio"
                        noOptionsText={"Sin coincidencias"} options={listMun}
                        renderInput={(params) => (
                            <TextField {...params} size='small'
                                label="Municipio" variant="outlined"/>
                        )}
                    />
                    : <LinearProgress/>}
            </Grid>
            <Grid item xs={4}>
                {auxListSecc.length !== 0 ?
                    <FormControl size='small'>
                        <InputLabel size='small' variant="outlined" id="Seccion-label-nvo">Sección</InputLabel>
                        <Select size='small' variant="outlined" style={{width: '7rem'}}
                            label="Sección" id="Seccion-select2" name="Seccion"
                            value={Seccion} onChange={guardarSeccionSelect}
                            fullWidth
                        >
                            {auxListSecc.map((seccion, index) => {
                                return (
                                <MenuItem size='small' value={seccion.Id} key={seccion.Id}>
                                    <em>{seccion.Numero}</em>
                                </MenuItem>
                                )
                                }
                            )}
                        </Select>
                    </FormControl>
                    : <FormControl size='small'>
                        <InputLabel size='small' variant="outlined" id="Seccion-label-nvo">Sección</InputLabel>
                        <Select size='small' variant="outlined" style={{width: '7rem'}} 
                            label="Sección" id="Seccion-select2" name="Seccion"
                            value={Seccion} onChange={guardarSeccionSelect}
                            fullWidth
                        >
                            <MenuItem size='small' value={''}>
                                <em></em>
                            </MenuItem>
                        </Select>
                    </FormControl>
                }
            </Grid>            
            {listCoordEst.length !== 0 ?
                <FormControl size="small" style={{marginLeft:".5rem",width:"15rem",marginRight:".5rem"}}>
                    <InputLabel htmlFor="Superior">Superior</InputLabel>
                    <Select  value={Superior} fullWidth onChange={onChangeSel}
                        name={'Superior'} id={ 'superior-select'}
                    >
                        {listCoordEst.map(element => {
                            return( 
                            <MenuItem size='small' value={element.Id} key={element.Id}>
                                <em>{element.Nom}</em>
                            </MenuItem>
                            )
                        })}
                    </Select>
                </FormControl>
            : null
            }
            
            <Grid item xs={4}>
                <MuiPickersUtilsProvider locale={esLocale} utils={DateFnsUtils}>
                    <DatePicker
                        disableFuture label="Fecha"
                        format="dd/MM/yyyy" size="small"
                        views={["year", "month", "date"]}                                                
                        minDate="1900-01-01"  maxDate={fechaMax}
                        value={selectedDate} onChange={handleDateChange}
                        style={{width: 6.5 + 'rem'}}
                    />
                </MuiPickersUtilsProvider>
            </Grid>
            <Grid item xs={6} >
                <TextField id="Alias-Txt" name="Alias" label="Alias" fullWidth size="small"
                    value={Alias} onChange={onChange} variant="outlined"/>                                                       
            </Grid>
            <Grid item xs={6} style={{}}>                
                <TextField id="Nombre-Txt" name="Nombre" label="Responsable" fullWidth size="small"
                    value={Nombre} onChange={onChangeTxt} variant="outlined"/>                                        
            </Grid>
            <Grid item xs={6} style={{}}>
                <TextField id="Usuario-Txt" name="Usuario" label="Usuario" fullWidth size="small"
                    value={Usuario} onChange={onChangeTxt2} variant="outlined"/>                
            </Grid>
            <Grid item xs={6} style={{marginBottom:.7+'rem'}}>                
                <FormControl  size="small" style={{width:13.5+'rem'}} >
                <InputLabel htmlFor="pwd-usu1-txt2" size="small">
                    Contraseña
                </InputLabel>
                <Input
                    id="input-pwd-usu2" 
                    type={visiblePwd ? 'text' : 'password'}
                    value={Pass}  autoComplete='off' name="Pass"
                    onChange={onChangeTxt2 } size="small"
                    endAdornment={
                        <InputAdornment position="end" size="small">
                            <IconButton size="small" 
                                aria-label="icono password visibility"
                                onClick={()=>setVisiblePwd(!visiblePwd)}  //onMouseDown={mostrarPwdUp}
                            >
                            {visiblePwd ? <Visibility size="small"/> : <VisibilityOff size="small"/>}
                            </IconButton>
                        </InputAdornment>
                    }
                />
                </FormControl>                     
            </Grid>
            <Grid item xs={5} >                                              
                <TextField id="Tel-Txt" name="Tel" label="Tel" fullWidth size="small"
                    value={Tel} onChange={onChangeNumTel} variant="outlined"/>                
            </Grid>
            <Grid item xs={7} >                                              
                <TextField id="Ine-Txt" name="Ine" label="Ine" fullWidth size="small"
                    value={Ine} onChange={onChangeTxt2} variant="outlined"/>                    
            </Grid>
            <Grid item xs={12} >                              
                <TextField id="Email-Txt" name="Email" label="Email" fullWidth size="small"
                    value={Email} onChange={onChangeCorreo} variant="outlined"/>                        
            </Grid>
        </Grid>
    </Modal>);
};

export default ModalNvoEdit;
