import React,{createContext,useState} from 'react';

export const NodoContext = createContext();

const NodoProvider = (props) => {
    const [nodoSeleccionado,setNodoSeleccionado]=useState([])
    return (
        <NodoContext.Provider
            value={ { nodoSeleccionado,setNodoSeleccionado} }
        >
            { props.children }
       </NodoContext.Provider> 
    )
}

export default NodoProvider
