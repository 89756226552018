import React from 'react';
import {IconButton,Tooltip} from "@material-ui/core";
import SearchIcon from '@material-ui/icons/Search';
const IconBuscar = ({leyenda,handleClick}) => {
    return (
    <IconButton color="primary"  aria-label="add circle" 
                    component="span" onClick={handleClick}>
        <Tooltip title={leyenda}>
            <SearchIcon/>
        </Tooltip>
    </IconButton>   
    );
};

export default IconBuscar;