import React,{useEffect} from "react";
import Modal from "../generales/ModalInformativo";
import { celda, TablaGeneral,celdaIcono } from "../generales/TablaGeneral";
import { makeStyles } from "@material-ui/core/styles";
import {Box, TableRow,Card} from '@material-ui/core';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord'; 
import MapaDetalle from "./MapaDetalle";
import GraficasSeccion from "./GraficasSeccion";
const useStyles = makeStyles({  
  container: {
    maxHeight: window.innerHeight - 252,
    minHeight: 280,    
    width:10.5 + "rem",
  },
  container2: {
    maxHeight: 550,
  },
  tableRow: {
    "&$selected, &$selected:hover": {
      backgroundColor: process.env.REACT_APP_Color_Second,
    },
  },
  tableCell: {
    "$selected &": {
      color: "white",
    },
  },
  hover: {},
  selected: {},
});

const columnas = [
  {
    id: 2, titulo: "Sección", alinear: "center",
  },
  {
    id: 3, titulo: "Avance", alinear: "right",
  },
  {
    id: 4, titulo: "", alinear: "center",
  } 
];

const ModalListAvanze = ({ modalAbierto, setModalAbierto, listSecc ,nomMuni ,tipo,listCentro,coordPerfiles}) => {
  const classes = useStyles();
  const [selectedID] = React.useState(null);
  const libreriaG = [process.env.REACT_APP_GOOGLE_LIBRARIE];
  let altuTamMax = 600;
  //const [regisSelect, setRegisSelect] = React.useState([]); 

  useEffect(() => {
    //console.log(listSecc);
  }, []);

  /* const seleccionado=(auxId,auxOrden)=>{
    setSelectedID(auxId)   
    setRegisSelect(auxOrden)
  } */

  function Renglon(props) {
    const { row } = props;
    let icono=<FiberManualRecordIcon fontSize="inherit"  style={{color:row.color }} />
    let auxIcon=<span>{icono} {row.nombre}</span>
    return (
      <TableRow
        //onClick={() => { seleccionado(row.Id, row); }}
        className={classes.tableRow} selected={selectedID === row.Id}
        classes={{ hover: classes.hover, selected: classes.selected }}
      >
        {/* celdaIcono(row.avance+"%",icono,0,classes.tableCell) */}
        {celda( "left", { width: 25 + "px", fontSize: 12 + "px", paddingLeft: 8 + "px" },
          auxIcon ,1,classes.tableCell
        )}
        {celda( "right", { width: 30 + "px", fontSize: 12 + "px"},
          `${row.avance}%` ,2,classes.tableCell
        )}    
        {celda( "right", { width: 55 + "px", fontSize: 12 + "px",paddingRight:.5+'rem'},
          `${row.avanceNum} / ${row.meta}` ,3,classes.tableCell
        )}        
      </TableRow>
    );
  }

  function cabeza() {
    let estiloCol = { backgroundColor: process.env.REACT_APP_Fondo_Color, color: "white" };
    return (
      <TableRow>
        {columnas.map((row) => {
          return celda(row.alinear, estiloCol, row.titulo, row.id,{});
        })}
      </TableRow>
    );
  }

  function Cuerpo(auxlista) {
    return auxlista.length !== 0
      ? auxlista.map((row) => <Renglon key={row.Id} row={row} />)
      : null;       
  }

  function tablaCompleta(auxlista) {      
    return (
    <Card>
     { TablaGeneral(Cuerpo(auxlista),cabeza(),classes.container)}
    </Card>
    );
  }
  const tamanioH =
  window.innerHeight < altuTamMax
    ? window.innerHeight - 100
    : window.innerHeight - 180;
  return (
    <Modal
      titulo={nomMuni} modalAbierto={modalAbierto}
      setModalAbierto={setModalAbierto} tamanio={"xl"}
    >
        <Box display={"flex"} flexDirection="row">
            <Box display={"flex"} mr={1}>
                {listSecc.lenth!==0 ? tablaCompleta(listSecc) : null}
            </Box>    
            <Box>
                <GraficasSeccion
                    valor={listSecc} tipo={tipo}
                />
            </Box>    
            <Box display={"flex"} width={950} >
            <MapaDetalle zoom={15}
                tamanio={{ height: tamanioH, width: "100%" }}
                libreriaG={libreriaG}
                listSeccion={listSecc}  listCentro={listCentro}
                coordPerfiles={coordPerfiles}
            />
            </Box>
        
        </Box>
        
    </Modal>
  );
};

export default ModalListAvanze;
