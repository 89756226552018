import React,{useState} from 'react';
import TablaPublico from './TablaPublico'
import {Box,IconButton,Tooltip, TextField,LinearProgress,//Breadcrumbs,
  Select,Typography,Card,FormControl,InputLabel,MenuItem} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import IntervaloSelect from './IntervaloTabla';
import SearchIcon from '@material-ui/icons/Search';
//import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import CardPerfil  from './CardPerfil';
//import ContenedorMapa from './ContenedorMapa';
import { llamadaApiCarga,ErrorGeneral } from '../funciones/ClienteAxios';
import qs from 'qs';
import { useHistory } from "react-router-dom";
import MapDelegacion from '../MapaEdo';
const listEstatus=[
  {Id:0,nom:"Todos"},
  {Id:1,nom:"Pendiente"},
  {Id:2,nom:"Valido"},
  {Id:3,nom:"Rechazado"},
]

const PublicoInicio = ({listMuni}) => {
  const [fSelect, setFSelect] = useState({inicio:new Date(),final:new Date()});
  //const[totalPerfil,setTotalPerfil]= useState({Valido:0,Pendiente:0,Rechazado:0});
  let history = useHistory();         
  const usuID = localStorage.getItem('UsuId');
  let Dispvo =localStorage.getItem('Dispvo') ;    
  let auxDispV =Dispvo.length >= 12 ? Dispvo:'w8rf51v21dsd2cs'
  const[totalPerfil,setTotalPerfil]= useState([]);
  const[actualizar,setActualizar]=useState(true);
  const [arregloColor,setArregloColor] = useState([])    
  const [listMun,setListMun]=useState([])
  const [munSvgSelect,setMunSvgSelect]=useState(0)
  const [muniSelect,setMuniSelect]=React.useState([]);
  const[listSecc,setListSecc]=useState([]);
  const [auxBandera,setAuxBandera]=React.useState(0);
  const [seccionSelect,setSeccionSelect]=React.useState([]);
  const [esperaSecc, setEsperaSecc] = useState(false)
  const [filtro, setFiltro] = useState({estatus:0,municipio:0,seccion:0})
  const{estatus} =filtro
  const llamadaListSecc=(auxMuni)=>{
    let data = qs.stringify({
      usuario:usuID,   
      municipio:auxMuni,                                                         
      idDispositivo: auxDispV,
  });
      let url = "lugar-seccion";
      function respuestaSecc(auxiliar) {
        if (auxiliar[0].Id != -1) 
        { setListSecc(auxiliar)       
          //console.log(auxiliar);    
        }
      }
      llamadaApiCarga(data, url, respuestaSecc,ErrorGeneral,setEsperaSecc,history);
    }

  const guardarMuni = (e) => {      
    if (e !== null && e !== undefined) 
    { setMuniSelect(e);
      llamadaListSecc(e.Id);   
      setFiltro({  
        ...filtro,
        municipio :e.Id ,
        seccion:0
      })    
    } 
    else 
    { setMuniSelect([]);        
      setFiltro({  
        ...filtro,
        municipio :0 ,
        seccion:0
      })  
    }
    setAuxBandera(0);
    setSeccionSelect([]);    
  };
    
  const guardarSecc=(e)=>{    
    if (e!== null && e!== undefined) 
    { setSeccionSelect(e)          
      setFiltro({  
        ...filtro,
        //municipio :0 ,
        seccion:e.Id
      })       
    }
    else
    { setSeccionSelect([])          
      setFiltro({  
        ...filtro,
        //municipio :muniSelect ? muniSelect.Id :0 ,
        seccion:0
      })       
    }
    setAuxBandera(0)    
  }

  const generarLista = e =>{   
    e.preventDefault()    
    setTotalPerfil([])
    setArregloColor([])
    setActualizar(!actualizar)        
  }    
  
  const generaCard=(seleccionado)=>{
    let auxMun=listMun.filter((muni)=>muni.Id===seleccionado)
    //console.log(auxMun);
    return(
    <Card style={{padding:.3+'rem'}}>
      <Typography>{auxMun.length > 0 ? auxMun[0].Nom : ""}</Typography>
    </Card>
    )
  }

  const onChangeEstatus=(e)=>{    
    if (e!== null && e!== undefined) 
    { setFiltro({  
        ...filtro,
        estatus :e.target.value ,
      })            
    }
    else
    { setFiltro({
        ...filtro,
        estatus : 0 
      })           
    }        
  }  

  const cardMuni= listMun.length!==0 && munSvgSelect!== 0 ? generaCard(munSvgSelect):null
  return(
  <div>
    <Box display={'flex'} pb={1}>
      <Box >
        <IconButton color="primary"  aria-label="add circle" 
          component="span" onClick={generarLista}>
            <Tooltip title="Buscar">
            <SearchIcon/>
            </Tooltip>
        </IconButton>                                                                                                                              
      </Box>    
      <Box>
        <IntervaloSelect fSelect={fSelect}  setFSelect={setFSelect} />
      </Box>
      {listEstatus.length!==0 ?                
        <Box display={"flex"} pt={1} >
        <FormControl size='small'  style={{marginLeft:1.6+'rem', minWidth:7+'rem'}} >
          <InputLabel size='small' variant="outlined" id="estatus-label">Verificación</InputLabel>
          <Select  size='small' variant="outlined"
            label="Verificación" id="verificacion-select2" name="estatus"
            value={estatus} onChange={onChangeEstatus}
          >
            {listEstatus.map((campo) => {              
              return(
              <MenuItem size='small' value={campo.Id} key={campo.Id+1}>
                <em>{campo.nom }</em>
              </MenuItem>
              )
            } )}                    
          </Select>
          </FormControl>
          </Box>
        :   null
        }
      <Box pl={1} pt={1}>
        {listMuni.length!==0 ?           
          <Autocomplete
          id="municipio2-demo" size='small'   options={listMuni} value={muniSelect}
          onChange={(e,option)=>guardarMuni(option)} noOptionsText={"Sin coincidencias"}         
          getOptionLabel={(option) => option.Nom} style={{ width: 22+'rem' }}            
          renderInput={(params) => (
            <TextField {...params} 
              label="Municipio" variant="outlined" 
            />
          )}
        />               
        :<LinearProgress/>
        }
      </Box>
      <Box pl={1} pt={1}>
      {muniSelect.length!== 0 && listSecc.length !==0 && !esperaSecc ?
          <Autocomplete
            id="Seccion-demo" size='small' options={listSecc}   
            onChange={(e,option)=>guardarSecc(option)}            
            getOptionLabel={(option) => option.Numero.toString()}
            style={{ width: 10+'rem' }} noOptionsText={"Sin coincidencias"}
            renderInput={(params) => (
              <TextField {...params} 
                label="Sección" variant="outlined" 
              />
            )}
          />
        : <LinearProgress/> 
        }
      </Box>
    </Box>       
    <Box>
      {totalPerfil.length !== 0 ? 
      <CardPerfil valor={totalPerfil} />
      :null}
    </Box>
    <Box display={"flex"} row  width={"100%"} >
      <Box width={"62%"} style={{minWidth:64+'rem'}} >
        <TablaPublico fSelect={fSelect} actualizar={actualizar} setActualizar={setActualizar}
          setTotalPerfil={setTotalPerfil} setArregloColor={setArregloColor}
          setListMun={setListMun} listMun={listMun} filtro={filtro}
        />
      </Box>
      {arregloColor.length !== 0 ?
        <Box display={"flex"} flexDirection="column"  style={{minWidth:20+'rem'}} width={"32%"}>
         {/*  <span  >
            {cardMuni}
            <ContenedorMapa coloreados={arregloColor} setMunSvgSelect={setMunSvgSelect}/>
          </span> */}
          <span style={{marginLeft:1+'rem'}} >
            <MapDelegacion arregloColor={arregloColor}/>
          </span>
        </Box>
      :null
      }
    </Box>
  </div>
  );
};

export default PublicoInicio;