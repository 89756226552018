import {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import { Box,IconButton, LinearProgress, Paper,Table,
    TableBody, TableCell, TableContainer, TableHead,
    TableRow, Tooltip, } from '@material-ui/core';
import qs from 'qs';
import moment from 'moment';
import {ErrorGeneral, llamadaApiToken} from '../funciones/ClienteAxios';
import LocationOnIcon from "@material-ui/icons/LocationOn";
import ModalMapa from "./ModalMapa";
import EditarStatusProspecto from './EditarStatus';
import FiberManualRecord from '@material-ui/icons/FiberManualRecord'; 

const useStyles = makeStyles(theme=>({
    container: {
        maxHeight: window.innerHeight - 170,
        minHeight: 280,
        minWidth: '100%',
    },
    container2: {
        maxHeight: 550,
    },
    tableRow: {
        "&$selected, &$selected:hover": {
            backgroundColor:theme.palette.secondary.main
        }
    },
    tableCell: {
        "$selected &": {
            color: "white"
        }
    },
    hover: {},
    selected: {}
}));

export default function TablaProspecto({    history,
                                            fechasSeleccionadas,
                                            selectedID,
                                            setSelectedID,
                                            source,
                                            idusu,
                                            buscar,                                            
                                            abrirModal,
                                            setAbrirModal,
                                            setProspecto,
                                            buscarDatos
                                        }) {
    const classes = useStyles();
    let fInicio = moment(fechasSeleccionadas.inicio).format("YYYY-MM-DD")
    let fFinal = moment(fechasSeleccionadas.final).format("YYYY-MM-DD")    
    const [espera, setEspera] = useState(false);
    const [datos, guardarDatos] = useState([])
    const [listStatus, setListStatus] = useState([])
    const [sinDatos, guardarSinDatos] = useState(true)    
    const [seleccionado, setSeleccionado] = useState([]);
    const [modalStatus, setModalStatus] = useState(false);

    useEffect(() => {
        const llenarTabla = () => {
            setEspera(true)            

            let data = qs.stringify({
                Usuario: idusu,
                Estatus: 0,
                Fini: fInicio + 'T00:00:00',
                Ffin: fFinal + 'T23:59:59',
                idDispositivo: '45vf2d1vdf21d2f'
            });
            let url = "prospecto-list";

            function response(data) {
                console.log(data);
                if (data[0].Id != -1) {
                    guardarSinDatos(true)
                    guardarDatos(data)
                    setEspera(false)
                } else {
                    guardarSinDatos(false)
                    setEspera(false)
                }
            }

            llamadaApiToken(data, url, response, ErrorGeneral, history, source);
        };

        const llenarListStd = () => {
            let data = qs.stringify({
                Usuario: idusu,
                idDispositivo: '45vf2d1vdf21d2f'
            });
            let url = "prospecto-status";

            function response(data) {
                console.log(data);
                if (data[0].Id != -1) 
                {   setListStatus(data)               
                }
            }

            llamadaApiToken(data, url, response, ErrorGeneral, history, source);
        };

        if (listStatus.length===0) 
        {
            llenarListStd()
        }
        llenarTabla();
    }, [buscar]);

    const seleccionar = (auxId, auxOrden) => {
        setSelectedID(auxId)
        setProspecto(auxOrden)
        setSeleccionado(auxOrden)
    }

    const abrirModalMapa = () => {
        setAbrirModal(true)
    }

    const modalMapa = abrirModal ? <ModalMapa abrirModal={abrirModal}
                                        setAbrirModal={setAbrirModal}  datos={seleccionado}
                                    /> : null
    const celda=(alinear,estilo,contenido)=>{
        return (
        <TableCell align={alinear} className={classes.tableCell} padding={'none'} style={estilo} >
            {contenido}
        </TableCell>
        )
    }  

    function abrirModalEditStd(){      
        setModalStatus(true);    
    }

    function Row(props) {
        const {row} = props;
        return (           
        <TableRow onClick={() => { seleccionar(row.Id, row) }}
            className={classes.tableRow} selected={selectedID === row.Id}
            classes={{hover: classes.hover, selected: classes.selected}}
        >                                 
            {celda("center",{fontSize: 12 + 'px',width:30},
            <Tooltip title={`Editar Estatus`}>
                <IconButton   aria-label="add circle" size="small" 
                    onClick={() => abrirModalEditStd(row)} component="span"
                >
                    {selectedID === row.Id ? 
                        <FiberManualRecord style={{color:'white',width:18+'px'}} />
                        :<FiberManualRecord style={{width:18+'px',color:row.StatusColor}} color="primary"/>
                    }
                </IconButton>                            
            </Tooltip> ) }              
            {row.Lat && row.Lon !== 0.0 ?
                celda("center",{fontSize: 12 + 'px',width:30},                      
                    <Tooltip title={'Ver más'}>
                        <IconButton color="primary" size="small"
                            onClick={() => abrirModalMapa()}>
                            {selectedID === row.Id ?
                                <LocationOnIcon style={{color: 'white'}}/> 
                                : <LocationOnIcon/>}
                        </IconButton>
                    </Tooltip>
                ) 
                :celda("center",{fontSize: 12 + 'px',width:30}, "")
            }
            {celda("center",{fontSize: 12 + 'px',width:75},
                moment.utc(row.FReg).local(true).format("DD/MM/YYYY") )}                    
            {celda("center",{fontSize: 12 + 'px',width:75},
                moment.utc(row.FEnv).local(true).format("DD/MM/YYYY") )}                    
            {celda("left",{fontSize: 12 + 'px',width:180},
                `${row.Nom} ${row.Pat} ${row.Mat}` )}      
            {celda("left",{fontSize: 12 + 'px',width:80}, row.UsrNom)}                                                            
            {celda("center",{fontSize: 12 + 'px',width:55}, row.TipoNom)}
            {celda("left",{fontSize: 12 + 'px',width:80,paddingLeft:"4px"}, row.EdoNom)}                                                                                                    
            {celda("left",{fontSize: 12 + 'px',width:80}, row.MunNom)}
            {celda("center",{fontSize: 12 + 'px',width:55}, row.SecNum)}
            {celda("left",{fontSize: 12 + 'px',width:300}, row.Domicilio)}                    
        </TableRow>                      
        );
    }

    function tablaCompleta(auxlista) {
        return (
        <TableContainer className={classes.container}>
        <Table stickyHeader aria-label="sticky table">
            <TableHead>
                <TableRow>                            
                    {celda("center",{},"")} 
                    {celda("center",{},"")}                             
                    <TableCell align="center" padding='none'>F. Sistema</TableCell>
                    <TableCell align="center" padding='none'>F. Registro</TableCell>
                    <TableCell align="center" padding='none'>Nombre</TableCell>
                    <TableCell align="center" padding='none'>Usuario</TableCell>
                    <TableCell align="center" padding='none'>Tipo</TableCell>
                    <TableCell align="center" padding='none'>Estado</TableCell>
                    <TableCell align="center" padding='none'>Municipio</TableCell>
                    <TableCell align="center" padding='none'>Sección</TableCell>
                    <TableCell align="center" padding='none'>Dirección</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {auxlista.length != 0 ? (auxlista.map((row) => (
                    <Row key={row.Id} row={row}/>
                ))) : null}            
            </TableBody>
        </Table>
        </TableContainer>
        )
    }

    const tablaSinDatos = () => {
        return (
            <TableContainer className={classes.container2}>
                <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                            <TableCell align="center" padding='none'></TableCell>
                            <TableCell align="center" padding='none'>Fecha Sistema</TableCell>
                            <TableCell align="center" padding='none'>Fecha Registro</TableCell>
                            <TableCell align="center" padding='none'>Nombre</TableCell>
                            <TableCell align="center" padding='none'>Tipo</TableCell>
                            <TableCell align="center" padding='none'>Estado</TableCell>
                            <TableCell align="center" padding='none'>Municipio</TableCell>
                            <TableCell align="center" padding='none'>Sección</TableCell>
                            <TableCell align="center" padding='none'>Dirección</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow className={classes.tableRow}>
                            <TableCell className={classes.tableCell} align="center">Dia</TableCell>
                            <TableCell className={classes.tableCell} align="left">Sin</TableCell>
                            <TableCell className={classes.tableCell} align="left">Prospectos</TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
        )
    }

    const modStd= modalStatus ? <EditarStatusProspecto  modalAbierto={modalStatus}
                                    setModalAbierto ={setModalStatus} titulo={ `${seleccionado.Nom} ${seleccionado.Pat} ${seleccionado.Mat}`} actualizar={buscarDatos}    
                                    seleccion={seleccionado} listStatus={listStatus}
                                />:null
    const contenido = sinDatos && datos.length != 0 ? tablaCompleta(datos) : tablaSinDatos()
    const tabla = espera ? (<Box mt={2}><LinearProgress/> </Box>) : (contenido)

    return (
        <Paper>
            {modStd}
            {tabla}
            {modalMapa}
        </Paper>
    );
}