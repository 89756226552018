import React,{useState,useEffect} from 'react';
import {Bar,} from 'react-chartjs-2';
import { Card, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
    card:{
      boxShadow:'0 0.5em 1em -0.125em rgb(10 10 10 / 10%), 0 0 0 1px rgb(10 10 10 / 2%)',
    },
  });

export default function InciGrafica({valor,descripcion}){  
    let altuTamMax=825
    const classes = useStyles();
    let colorTabla =[];
    const auxCoordi = valor;
    let contador=0, miLabel=[], midata=[] , bandera=0;
    const [alturaContenedor, setAlturaContenedor] = useState(window.innerHeight<altuTamMax ? window.innerHeight-485: window.innerHeight - 620)
    const [anchoContenedor, setAnchoContenedor] =  useState(window.innerWidth>1600 ? 
                                                            (window.innerWidth>=1600 && window.innerWidth<1800 ? 
                                                                window.innerWidth-1380 
                                                              : window.innerWidth-1300) 
                                                            :  window.innerWidth/2.5)
            
  useEffect(()=>{
    window.addEventListener('resize',ajustaTam);
    return()=>window.removeEventListener("resize",ajustaTam)
  },[alturaContenedor,anchoContenedor,altuTamMax])

  function ajustaTam() {
    console.log(bandera);
    bandera+=1
    if(window.innerHeight < altuTamMax)
    { setAlturaContenedor(window.innerHeight-465)
    }
    else
    { setAlturaContenedor(window.innerHeight-620)
    }
    if(window.innerWidth > 1300)
    {
      if(window.innerWidth>=1490 && window.innerWidth<1700)
      { setAnchoContenedor(window.innerWidth-939)
      }
      else
      { setAnchoContenedor(window.innerWidth-800)
      }
    }
    else
    { setAnchoContenedor(window.innerWidth/2.5)
    }        
  }
    function ColorTab (elcolor) {
      let colorEfecti= '';/*
      if(elcolor<60){
          colorEfecti= '#FF0505E8';
      }
      else{
          if(elcolor>=60 && elcolor<80)
          {
              colorEfecti= '#F3A80B';               
          }
          else{
              if(elcolor>=80 )
              {
                  colorEfecti= '#1CC63D';
              }
          }
      }*/
      colorEfecti= '#F58A05';               
      return ( colorEfecti );
    } 

    auxCoordi.forEach( coordi => {
      midata[contador]=coordi.Total;
      miLabel[contador]=coordi.TipoNom.length>19  ?coordi.TipoNom.substring(0,17)+"...":coordi.TipoNom;
      let auxiliar =coordi.Total;                        
      colorTabla[contador] =ColorTab(auxiliar);                    
      contador++;        
   });
   /*
   const imprime= auxCoordi.map((coordi) => {            
        midata[contador]=coordi.promovidos;
        miLabel[contador]=contador+1;
        let auxiliar =coordi.efectividad;                        
        colorTabla[contador] =ColorTab(auxiliar);                    
        contador++;          
    })*/
    contador = 0;
    const options = {
      indexAxis:'y',
       scales: {
        yAxes: [
          {
            stacked: true,
            ticks: {
              beginAtZero: true,
              
            },Labels :{              
              enabled:false
            },
          },                    
        ],
        x:
          {            
            ticks:{
              callback: function(value) {
                return value.toFixed(1).replace(/\d(?=(\d{3})+\.)/g, '$&,');
            }
            }
          },
      },
    }  
  const horizontalRuta = {        
      labels: miLabel,
      datasets: [
        {
          label: 'Incidencias',//
          backgroundColor: colorTabla,
          borderColor: 'rgb(11,227,210)',
          borderWidth: 0,
          height: 0,
          hoverBackgroundColor: 'rgba(255,0,54,0.4)',
          hoverBorderColor: 'rgb(0,88,101)',
          data: midata 
        }
      ]
    };    


    

  return ( 
  <Card className={classes.card} style={{marginLeft:'1em', marginTop:1+'rem',height:alturaContenedor+25,width:anchoContenedor}}>    
    <Typography id="ajustarTitulo" style={{textAlign:'center'}}> {descripcion} </Typography>
    <Bar
      id="graficaHorBarIncidencia" data={horizontalRuta}
      options={options} width={anchoContenedor}
      height={alturaContenedor}
    />                   
  </Card>
  );
}
