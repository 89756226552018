import React, {useState, useEffect} from 'react';
import qs from "qs";
import ModalInfo from '../generales/ModalInformativo'
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import {IconButton, LinearProgress, Box, List, Card, Typography, Grid} from '@material-ui/core';
import {makeStyles} from "@material-ui/core/styles";
import moment from "moment";
import {llamadaApiCarga, ErrorGeneral} from '../funciones/ClienteAxios';

const useStyles = makeStyles((theme) => ({

    alturaLista: {
        minHeight: 100, overflow: 'auto',
    }, estiloCards: {
        marginBottom: '.3rem',
        width: '95%'
    }, tamanioFecha: {
        fontSize: 12
    }, tamanioLog: {
        marginLeft: '.4em', fontSize: 12, marginTop: 5
    },
    stat: {
        position: "absolute", top: '55%', transform: 'translateY(-55%)'
    }
}));


export default function ModalBitacora({seleccionado, setModalAbierto, modalAbierto, titulo, subtitulo}) {
    const classes = useStyles();
    const [historial, setHistorial] = useState([]);
    const [espera, setEspera] = useState([]);

    useEffect(() => {
        let Dispvo = localStorage.getItem('Dispvo');
        let auxDispV = Dispvo.length >= 12 ? Dispvo : 'w8rf51v21dsd2cs'
        let usuID = localStorage.getItem('UsuId');
        const llenaBitacora = () => {
            let data = qs.stringify({
                usuario: usuID, solicitud: seleccionado.Id, idDispositivo: auxDispV,
            });
            let url = "sugerencia-publico-detalle-lista";
            llamadaApiCarga(data, url, respuestaBitacora, ErrorGeneral, setEspera);
        };
        llenaBitacora()


    }, [])

    function respuestaBitacora(auxiliar) {
        if (auxiliar[0].Id !== -1) {
            setHistorial(auxiliar);
        }
    }

    function generarStatus(color, valor) {
        return (<Card className={classes.tamanioCard} style={{backgroundColor: color, alignContent: 'center'}}>
            <div style={{marginLeft: 2}} className={classes.stat}>
                {valor}</div>
        </Card>)
    }

    function generarBitacora() {
        let arrayElementos = historial.map((bitacora) => {
            console.log('BITACORA', bitacora)
            return (
                <Card className={classes.estiloCards} key={bitacora.Id}
                      style={
                          bitacora.Descr === bitacora.StatusNom ?
                              {
                                  borderLeft: 'solid',
                                  borderColor: bitacora.StatusColor
                              }
                              :
                              {
                                  borderLeft: 'solid',
                                  borderColor: bitacora.TipoColor
                              }
                      }>
                    <Grid container alignItems={'center'}>
                        <Grid item style={{marginLeft: '1rem'}}>
                           <span>
                               <b className={classes.tamanioFecha}>
                                   {moment.utc(bitacora.Fecha).format('YYYY-MM-DD HH:mm:ss') === "1900-01-01 00:00" ? "" : moment.utc(bitacora.Fecha).format('DD/MM/YYYY HH:mm:ss')}
                               </b>
                           </span>
                        </Grid>
                        <Grid item xs={3} style={{textAlign:'center', marginLeft:'1rem'}}>
                            {
                                bitacora.Descr === bitacora.StatusNom ?
                                    <Card style={{
                                        backgroundColor: bitacora.StatusColor,
                                        color: '#FFF',
                                        paddingLeft: 8,
                                        paddingRight: 8,
                                        marginTop: 8,
                                        marginBottom: 8
                                    }}>
                                        <span>{bitacora.StatusNom}</span>
                                    </Card>
                                    :
                                    bitacora.Descr === bitacora.TipoNom ?
                                        <Card style={{
                                            backgroundColor: bitacora.TipoColor,
                                            color: '#FFF',
                                            paddingLeft: 8,
                                            paddingRight: 8,
                                            marginTop: 8,
                                            marginBottom: 8
                                        }}>
                                            <span>{bitacora.TipoNom}</span>
                                        </Card>
                                        :
                                        <Card style={
                                            {
                                                backgroundColor: 'tomato',
                                                color: '#FFF',
                                                paddingLeft: 8,
                                                paddingRight: 8,
                                                marginTop: 8,
                                                marginBottom: 8
                                            }
                                        }>
                                            <span>N/E</span>
                                        </Card>
                            }
                        </Grid>
                        <Grid item style={{marginLeft: '1rem'}}>
                            <span>{bitacora.UserNom}</span>
                        </Grid>
                    </Grid>
                </Card>)
        });
        return arrayElementos;
    }

    const mostrarBitacora = historial.length > 0 ? generarBitacora() :
        <p style={{textAlign: 'center'}}>Sin resultados</p>;
    const listado = espera ? (<Box><LinearProgress/> </Box>) : (mostrarBitacora)
    return (

        <ModalInfo setModalAbierto={setModalAbierto}
                   modalAbierto={modalAbierto}
                   titulo={titulo}
                   tamanio='xs'
                   subtitulo={subtitulo}>
            <List className={classes.alturaLista}>
                {listado}
            </List>
        </ModalInfo>)
}