import React,{useState} from 'react';
import TablaExterno from './TablaExterno'
import {Box,IconButton,Tooltip, Breadcrumbs,Typography,Card} from "@material-ui/core";
import IntervaloSelect from './IntervaloTabla';
import SearchIcon from '@material-ui/icons/Search';
import CardPerfil  from './CardPerfil';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import ContenedorMapa from './ContenedorMapa';
import MapDelegacion from '../MapaEdo';
const CoorSeccional = () => {
    const [fSelect, setFSelect] = useState({inicio:new Date(),final:new Date()});
    const[actualizar,setActualizar]=useState(true);
    const[totalPerfil,setTotalPerfil]= useState([]);
    const [arregloColor,setArregloColor] = useState([])  
    const [infoNvlCoord,setInfoNvlCoord] = useState([])   
    const [listMun,setListMun]=useState([])
    const [munSvgSelect,setMunSvgSelect]=useState(0)
    const generarLista = e =>{   
        e.preventDefault()    
        setTotalPerfil([])
        setArregloColor([])
        setActualizar(!actualizar)
    }
    const letraTipo=(auxNvl,auxNvlId,auxUser)=>{
        //console.log(auxNvl);
        return(
        <span   >
          <Typography color="primary" variant="body2" id={auxUser+auxNvlId} 
            style={{fontSize:.8+'rem',fontWeight:600}}
          >
            {auxUser}
          </Typography>
          <Typography color="textPrimary" variant="body2" id={auxNvl+auxNvlId} 
            style={{fontSize:.7+'rem',textAlign:"center"}}
          >
            {auxNvl}
          </Typography>
        </span>
        )
      }
    
    const nvlInfo=(lista)=>{
        //console.log(lista);
        return(
        <Box mt={1} mb={2}>
        <Breadcrumbs separator={<NavigateNextIcon  />} aria-label="breadcrumb"  >
            {lista.CZId>0 ? letraTipo(lista.CZNom,lista.CZId,lista.CZUser) : null}
            {lista.CRId>0 ? letraTipo(lista.CRNom,lista.CRId,lista.CRUser) : null}
            {lista.CMId>0 ? letraTipo(lista.CMNom,lista.CMId,lista.CMUser) : null}    
            {lista.CSId>0 ? letraTipo(lista.CSNom,lista.CSId,lista.CSUser) : null} 
            {lista.PrId>0 ? letraTipo(lista.PrNom,lista.PrId,lista.PrUser) : null}            
        </Breadcrumbs>
        </Box>
        )
    }

    const generaCard=(seleccionado)=>{
        let auxMun=listMun.filter((muni)=>muni.Id===seleccionado)
        return(
        <Card style={{padding:.3+'rem'}}>
          <Typography>{auxMun.length > 0 ? auxMun[0].Nom : ""}</Typography>
        </Card>
        )
    }
    const cardMuni= listMun.length!==0 && munSvgSelect!== 0 ? generaCard(munSvgSelect):null

  return(
  <div>
    <Box display={'flex'} pb={1}>
      <Box >
        <IconButton color="primary"  aria-label="add circle" 
          component="span" onClick={generarLista}>
          <Tooltip title="Buscar">
            <SearchIcon/>
          </Tooltip>
        </IconButton>                                                                                                                              
      </Box>    
      <Box>
        <IntervaloSelect fSelect={fSelect}  setFSelect={setFSelect} />
      </Box>
    </Box>  
    {infoNvlCoord.length > 0 ? nvlInfo(infoNvlCoord[0]) :null}
    <Box>
      {totalPerfil.length !== 0 ? 
      <CardPerfil valor={totalPerfil} />
      :null}
    </Box> 
    <Box display={"flex"} row >
      <TablaExterno fSelect={fSelect} actualizar={actualizar} 
        setActualizar={setActualizar} setTotalPerfil={setTotalPerfil} 
        setArregloColor={setArregloColor} setInfoNvlCoord={setInfoNvlCoord }
        setListMun={setListMun} listMun={listMun} 
      />
      {arregloColor.length !== 0 ?
      <Box display={"flex"} flexDirection="column">
        {/* <span style={{marginLeft:4+'rem'}} >
          {cardMuni}
          <ContenedorMapa coloreados={arregloColor} setMunSvgSelect={setMunSvgSelect}/>
        </span> */}
        <span style={{marginLeft:1+'rem'}} >
          <MapDelegacion arregloColor={arregloColor}/>
          </span>
      </Box>
      :null
      }
    </Box>
  </div>
);
};

export default CoorSeccional;
