import { useCallback,useState,useContext ,useEffect} from 'react';
import { Paper, Typography,IconButton,Tooltip,Box } from '@material-ui/core';
import { Handle, Position } from 'reactflow';
import CreateIcon from '@material-ui/icons/Create';
import ModalNvoEdit from './ModalEdit';
import { NodoContext } from './ContextEsq';

const handleStyle = { left: 10 };

function NodoUpdate({ data, isConnectable }) {
  const {setNodoSeleccionado}=useContext(NodoContext)
  const [modalAbierto,setModalAbierto]=useState(false)
  const [nodo,setNodo]=useState([])
  
 /*  useEffect(() => {
    console.log(data);
    
  }, []) */
  
  const textupdaternode={
    height: "4rem",
    border: "1px solid #eee",
    //padding: "5px",
    borderRadius:"5px",
    //background: "white",
    width:"26rem"}
  
  const onChange = useCallback((evt) => {
    console.log(evt.target.value);
  }, []);

  const seleccionar=()=>{
    //setModalAbierto(true)
    //setNodo(nodo)
    setNodoSeleccionado({nodo: data, bandera:true} )
  }

  const modImg=()=>{    
    setNodoSeleccionado({nodo: data, modImg:true} )
  }

  const modal=  modalAbierto ? <ModalNvoEdit modalAbierto={modalAbierto} setModalAbierto={setModalAbierto} 
                                titulo={`Editar Registro ${data.idNodo}`} listMun={[ ]} auxReg={nodo} getDatos={()=>{}} />
                                :null
  const bandImg= data?.original?.JefeImgMini!=="mini/" && data.original.JefeImgMini.length!==0 ? true:false
  return (  
  <div style={textupdaternode}>
    {modal}
    <Paper style={{height:"3.8rem", width:"25.8rem"}}>
      <Handle type="target" position={Position.Top} isConnectable={isConnectable} id="b" />
      <Box display={"flex"} flexDirection="row" pt={.8} >          
        <img src={ bandImg ? data.original.JefeImgMini : "face_manager.svg"} 
          style={{width:40,height:40,marginLeft:".3rem"}} onClick={() => modImg()}
        />         

        <div style={{display:"flex",flexDirection:"column",marginLeft:".5rem"}}  onClick={() => seleccionar()} >     
          <Typography variant="body1" component="body1" style={{fontSize:".75rem"}} >
            {`${data.nomJf}`}
          </Typography>
          <Typography variant="body1" component="body1" style={{fontSize:".7rem"}} >
            {`${data?.original?.Supl}`}
          </Typography>
          <Typography variant="body1" component="body1" style={{fontSize:".7rem"}} >
            {`${data.label}`}
          </Typography>
          <Box>
            
         {/*    <Tooltip title={`Editar Comite`} style={{marginLeft:"1rem"}}>
              <IconButton   aria-label="add circle" size="small" 
                onClick={() => seleccionar(data)} component="span">            
                  <CreateIcon style={{width:18+'px'}} color="primary"/>            
              </IconButton>                            
            </Tooltip>         */}         
          </Box>
                                
        </div>
      </Box>
      {/*<Handle type="source" position={Position.Bottom}        
          style={handleStyle}/> 
      */ }
      {data?.padre ?
        <Handle type="source" position={Position.Bottom} id="a"  
          isConnectable={isConnectable} 
        />
      :null }
    </Paper>
  </div>   
  );
}

export default NodoUpdate;