import React,{useState,useEffect} from 'react';
import {Bar} from 'react-chartjs-2';
import { Card, Typography,Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import XLSX from 'xlsx';
import moment from 'moment';

const useStyles = makeStyles({

  card:{
    boxShadow:'0 0.5em 1em -0.125em rgb(10 10 10 / 10%), 0 0 0 1px rgb(10 10 10 / 2%)',
  },
  cardBoton:{
    marginLeft:'.6em',
    color:process.env.REACT_APP_Color_Second,
    backgroundColor:'white',
    fontSize:16,
    minWidth:20+"px",
    textAlign:'center'
  },
  cardBotonSelec:{
    marginLeft:'.6em',
    color:'white',
    backgroundColor:process.env.REACT_APP_Color_Second,
    fontSize:16,
    minWidth:20+"px",
    textAlign:'center'
  }
});


export default function GraficaSeccion({valor,descripcion,tituloSeccion,auxMun}){  
  let altuTamMax=923
  const [alturaContenedor, setAlturaContenedor] = useState(window.innerHeight<altuTamMax ? (window.innerHeight>700 ? window.innerHeight-560 : window.innerHeight/2.5): window.innerHeight - 620)
  const [anchoContenedor, setAnchoContenedor] = useState(window.innerWidth>1300 ? (window.innerWidth>=1490 && window.innerWidth<1700 ? window.innerWidth-630 : window.innerWidth-700) : window.innerWidth/2)
  const classes = useStyles();
//    const[espera,setEspera]=useState(false);
  const [listaXCasilla,setListaXCasilla]=  useState([]);      
  const [miLabel,setMiLabel]=  useState([]);      
  const [midata,setMidata]=  useState([]);      
  const [miColor,setMiColor]=  useState([]);    
  const [btnSeleccionado,setBtnSeleccionado]=  useState(0);      
  const [miCasilla,setMiCasilla]=  useState("Sección");      
  const auxCoordi = valor;
    
  //let auxDis ="espacioGraf2";

  useEffect(()=>{
    window.addEventListener('resize', function() {
      if(window.innerHeight<altuTamMax)
      { if(window.innerHeight<700)
        { setAlturaContenedor(window.innerHeight/2.5)
        }
        else
        { setAlturaContenedor(window.innerHeight-560)
        }
      }
      else
      { setAlturaContenedor(window.innerHeight-520)
      }
      if(window.innerWidth>1300)
      { if(window.innerWidth>=1490 && window.innerWidth<1700)
        { setAnchoContenedor(window.innerWidth-630)
        }
        else
        { setAnchoContenedor(window.innerWidth-700)
        }
      }
      else
      { setAnchoContenedor(window.innerWidth/2)
      }      
    });

  },[alturaContenedor,anchoContenedor,altuTamMax])

  useEffect(()=>
  {  
    let auxmiLabel=[], auxmidata=[], auxmiColor=[], contador=0,auxiliar =""                 
    let auxArreglo=[];
    auxArreglo.push({ value : -1 , label : "Excel" })
    auxArreglo.push({ value : 0 , label : "Secc" })
    function SumaPartidos() 
    {
      let auxArreTotal =[];              
      auxCoordi.forEach( secc =>
      {             
        let resul=[], resul2=[]
        if(auxArreTotal.length===0)
        {  // console.log("una vez");
          resul2=auxCoordi.filter((dat)=>dat.ParId===secc.ParId )
          //console.log(resul);
        }
        else
        {
          resul2=auxCoordi.filter((dat)=>dat.ParId===(secc.ParId ) )  
        //  console.log(resul2);
          resul=auxArreTotal.filter((dat)=>dat.ParId===(secc.ParId ) )             
        }
        if (  resul2.length>1&&resul.length===0)
        {              
            let sumTotalnum=0;
            let sumNombre=secc.ParId;
            resul2.forEach((seccion) => {                
              sumTotalnum=sumTotalnum+ seccion.Voto;
              
            })
            auxArreTotal.push({ value : sumTotalnum , ParId : sumNombre })  
            const voto=sumTotalnum>0?sumTotalnum:""        
            auxmidata[contador]=sumTotalnum;
            auxmiLabel[contador]=secc.ParSigla+" "+voto;                
            auxmiColor[contador]=secc.ParColor.substring(0, 7);                    
            contador++;             
        }
        else
        {
          if (resul.length===0) {
            auxArreTotal.push({ value : secc.Voto , ParId : secc.ParId })         
            const voto=secc.Voto>0?secc.Voto:""      
            auxmidata[contador]=secc.Voto;
            auxmiLabel[contador]=secc.ParSigla+" "+voto;                  
            auxmiColor[contador]=secc.ParColor.substring(0, 7);    
            contador++;             
          }
            
        }
      });  
    }
    SumaPartidos();
    auxCoordi.forEach( coordi => {
      if (auxiliar!==coordi.Tipo) 
      {
        auxiliar=coordi.Tipo                
        auxArreglo.push({ value : coordi.CasId , label : auxiliar })         
      }
      //auxmidata[contador]=coordi.Voto;
      //auxmiLabel[contador]=coordi.ParSigla;                
      // auxmiColor[contador]=coordi.ParColor;          
      //contador++;             
    });
    setMiLabel(auxmiLabel);
    setMidata(auxmidata);
    setMiColor(auxmiColor);    
    setListaXCasilla(auxArreglo)
    setBtnSeleccionado(0)

  },[valor,auxCoordi]);

  const exportarArch = () => 
  {
    /* convert state to workbook */
    let auxArregloExcel=[], auxi2=["#","Casilla","Partido","Votos"];
    let auxnumero = miLabel.length, auxdescripcion="";
    auxArregloExcel.push(auxi2);  
    if (miCasilla==="Sección") 
    { auxdescripcion= miCasilla+" "+tituloSeccion;
      auxCoordi.forEach((seccion,index) => {                
        let auxi=[index+1,seccion.Tipo,seccion.ParSigla, seccion.Voto]
        auxArregloExcel.push(auxi);   
        
      })
    }
    else
    { if (auxnumero>0) 
      { auxdescripcion= tituloSeccion+" "+miCasilla ;
        for (let index = 0; index < auxnumero; index++) 
        { let auxi=[index+1,miCasilla,miLabel[index], midata[index]]
          auxArregloExcel.push(auxi);   
        }
      }
      else
      { auxi2=["Sin","Partidos","con Votos"];
        auxArregloExcel.push(auxi2);   
      }
    }
    let today = new Date();                    
    let fec = moment(today);
    //console.log(fec.format("YYMMDDHHMMSS"));
    const ws = XLSX.utils.aoa_to_sheet(auxArregloExcel);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Reporte");
    /* generate XLSX file and send to client */
    XLSX.writeFile(wb, fec.format("YYMMDDHHmmss")+" Votación de " +auxdescripcion+".xlsx")
  };

  function filtrarVot(nombre, numID) {
    let auxmiLabel=[], auxmidata=[], auxmiColor=[], contador=0;
    if (numID=== -1) 
    { exportarArch()
    }
    else
    { if (numID===0) 
      {  setMiCasilla(nombre)         
        let auxArreTotal =[];                        
        auxCoordi.forEach( secc =>
        { 
          let resul=[], resul2=[]
          if(auxArreTotal.length===0)
          {   
            resul2=auxCoordi.filter((dat)=>dat.ParId===secc.ParId )                
          }
          else
          {
            resul2=auxCoordi.filter((dat)=>dat.ParId===(secc.ParId ) )                    
            resul=auxArreTotal.filter((dat)=>dat.ParId===(secc.ParId ) )             
          }
          if (  resul2.length>1&&resul.length===0)
          {              
            let sumTotalnum=0;
            let sumNombre=secc.ParId;
            resul2.forEach((seccion) => {                
              sumTotalnum=sumTotalnum+ seccion.Voto;
              
            })
            auxArreTotal.push({ value : sumTotalnum , ParId : sumNombre })         
            const voto=sumTotalnum>0?sumTotalnum:"" 
            auxmidata[contador]=sumTotalnum;
            auxmiLabel[contador]=secc.ParSigla+" "+voto;                
            auxmiColor[contador]=secc.ParColor.substring(0, 7);                    
            contador++;             
          }
          else
          {
            if (resul.length===0) {
              auxArreTotal.push({ value : secc.Voto , ParId : secc.ParId })         
              const voto=secc.Voto>0?secc.Voto:"" 
              auxmidata[contador]=secc.Voto;
              auxmiLabel[contador]=secc.ParSigla+" "+voto;                   
              auxmiColor[contador]=secc.ParColor.substring(0, 7);    
              contador++;             
            }
              
          }
        });  
      }
      else
      { setMiCasilla(nombre)            
        let info=valor.filter((dat)=>dat.Tipo.search(nombre ) !== -1)          
        info.forEach( coordi => {               
          const voto=coordi.Voto>0?coordi.Voto:"" 
          auxmidata[contador]=coordi.Voto;
          auxmiLabel[contador]=coordi.ParSigla+" "+voto;                
          auxmiColor[contador]=coordi.ParColor.substring(0, 7);          
          contador++;             
        });
      }
      //console.log(auxmidata);
      //console.log(auxmiLabel);
      //console.log(auxmidata);
      setMiLabel(auxmiLabel);
      setMidata(auxmidata);
      setMiColor(auxmiColor);
      setBtnSeleccionado(numID)
    }
  }
      
  const crearBotones=(auxArr)=> {        
    return(
    <Box display="flex" flexDirection="row">     
      {auxArr.map((casilla,index) =>
      {  
        const aux=btnSeleccionado!==casilla.value ? classes.cardBoton : classes.cardBotonSelec             
        if (index===16) 
        {
          return(
          <Card onClick={()=>filtrarVot(casilla.label,casilla.value)} className={aux} >
            {casilla.label}
          </Card>            
          )
        }
        return(
        <Card onClick={()=>filtrarVot(casilla.label,casilla.value)} className={aux} >
          {casilla.label}
        </Card>                  
        )
      })}
    </Box>
    )
  }

  const options = {
    plugins: {
      legend: {
        display: false
      }
    },
    indexAxis:'y',
    tooltips: {
      enabled: true,
    },
    layout: {
      padding: {
        left: 0,
        right: 0,
        top: 10,
        bottom: 5
      }
    },
    title:{
      fontStyle: 'bold',
    },
    scales: {
    yAxes: [
      {
        stacked: true,
        ticks: {
          beginAtZero: true,
        },
      },
    ],
    xAxes: [
      {
        stacked: true,
        scaleLabel :{
          fontSize:6,
        },
      },
    ],
    },
  }  
  const horizontalRuta = {        
    labels: miLabel,
    datasets: [
      {
        label: 'Votos',//
        backgroundColor: miColor,
        borderColor: 'rgb(11,227,210)',
        borderWidth: 0,
        height: 0,
        hoverBackgroundColor: '#F0D7D7',
        hoverBorderColor: 'rgb(0,88,101)',
        data: midata 
      }
    ]
    };    
    /*if (Auxtam===1) {
      auxDis="espacioGraf";
    }*/
  
  const botones = listaXCasilla.length!==0?(crearBotones(listaXCasilla)):null
  /*const botones = <span data-tip="Exportar Votacion"><a className="pt-2 pl-2"  onClick={exportarArch}>
                            <img  className="pt-2 " src="export.svg"  alt="" border="0" height="30" width="30"/> 
                        </a></span>*/
  return ( 
  <Card className={classes.card} style={{marginLeft:'1em',height:alturaContenedor+30,width:anchoContenedor}}>    
    <Typography id="ajustarTitulo" style={{textAlign:'center',marginTop:'.2em'}}>{descripcion}</Typography>
      {botones}
      <Bar
        id="graficaSecc" data={horizontalRuta}
        width={anchoContenedor} height={alturaContenedor-30}
        options={options}
      />                   
    </Card>
   );
}
