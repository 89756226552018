import React,{useState,useEffect} from 'react';
import {Box,LinearProgress} from "@material-ui/core";
import {authUser} from "../funciones/AuthUser";
//import {AuthContext} from '../context/AuthContext'
import PanelMovilizacion from './MPanelMun';
import { useHistory } from "react-router-dom";
import axios from 'axios';
import qs from 'qs';
import {llamaApiCargaToken,ErrorGeneral } from '../funciones/ClienteAxios';
export default function Movilizacion (){
    let history = useHistory();
    const source = axios.CancelToken.source();
    //const {usuario,guardarUsuario}=React.useContext(AuthContext);
   // const {generales,guardarGenerales}=React.useContext(GeneralesContext); 
    const [lista,setLista] = useState([]);     
    const [actualizar,setActualizar] = useState(0)
    const [espera,setEspera] = useState(false);  
    const Dispvo =localStorage.getItem('Dispvo') ;    
    const auxDispV =Dispvo && Dispvo.length >= 12 ? Dispvo:'w8rf51v21dsd2cs'
    
    useEffect(()=>{ 
                        
        const autenticado = authUser();
        if(!autenticado){
            localStorage.clear();
            history.push('/');
            //guardarUsuario([]);  
        }else{
           /* if (usuario.length===0) 
                {
                    guardarUsuario({Nombre:usu,nivel:nivelUsuario});                                         
                    
                }  */      
            getLista();
        }
        
        return ()=> {
            source.cancel();
        }
                    
    },[actualizar,history]);

    const getLista = () => {  
        const data = qs.stringify({                
            idDispositivo: auxDispV,
        });
        const url = "promovido-web-promocion-listsecc";
        function respuesta(aux) {
            if (aux) 
            {   
                 setLista(aux);    
            }else{
                setLista([])
            }       
        }
        llamaApiCargaToken(data, url, respuesta,ErrorGeneral,setEspera,history,source);
    };

     const contenido = lista.length!==0 ? <PanelMovilizacion datos={lista} actualizar={actualizar} setActualizar={setActualizar}/>: null;
     const elementos = espera ? (<Box style={{marginTop:'2rem',marginLeft:'1em',width:'600px'}} sx={{width:'100%'}}>
                                 <LinearProgress/> </Box>) : contenido;

     return(
     <div style={{marginTop:'2em'}}>
        {elementos}
     </div>)
}
 