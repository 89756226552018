import { Typography } from '@material-ui/core'
import React from 'react'
import ModalAviso from '../generales/ModalAviso'
  const  ModalAvisoPerfil =({modalAbierto ,setModalAbierto, titulo,abrirModImg,actualizar, setActualizar})=> {    
    React.useEffect(()=>{
        console.log("dentro de aviso");
    },[])
    const seleccionarOpcion=(e,opcion)=>{
        e.preventDefault()
        if (opcion===1) 
        {   setModalAbierto(false)
            abrirModImg()
        } 
        else 
        {
            setModalAbierto(false)
            //abrirModImg()
             setActualizar(!actualizar)
        }
    }

    return (
    <span>
    <ModalAviso titulo={titulo} modalAbierto={modalAbierto}   
        guardarDatos={(e)=>seleccionarOpcion(e,1) }
        tamanio={'md'}  cerrarAviso={ (e)=>seleccionarOpcion(e,0)}
    >
     {/*    <Typography>Información enviada</Typography>        
         */}
        <Typography>¿Desea enviar la imagen?</Typography>
        
    </ModalAviso>
    </span>
    )
}
export default  ModalAvisoPerfil