import {ErrorGeneral, llamaApiCargaToken} from "../funciones/ClienteAxios";
import {authUser} from "../funciones/AuthUser";
import {useHistory} from "react-router-dom";
import {makeStyles} from '@material-ui/core/styles';
import qs from "qs";
import axios from "axios";
import React, {useEffect, useState} from "react";
import {GeneralesContext} from '../context/GeneralesContext'
import { Box, Grid, LinearProgress, } from "@material-ui/core";
import Flow from "./Esquema";
import Esquema2 from "./Esquema2";
import NodoProvider from "./ContextEsq";
const useStyles = makeStyles((theme) => ({
    alturaTabla: {
        maxHeight: "65vh", minHeight: "50vh", width: 60 + 'rem', overflowY: 'auto'
    },

    tableRow: {
        "&$selected, &$selected:hover": {
            backgroundColor: process.env.REACT_APP_Color_Second
        }
    }, tableCell: {
        "$selected &": {
            color: "white"
        }
    }, hover: {}, selected: {}
}));

let arreColores=["#038545", "#EF8012", "#347AF4", "#FFC107", "#119A4F", "#B406D8", "#FFA318", "#DC4A3D", "#32CA81", 
                    "#F9B546", "#ED0808", "#0097A7", "#880E4F", "#4BAC7D", "#C52363", "#F57F06", "#7C594C", "#DBE772"]

const Comite = () => {

    const classes = useStyles();
    const history = useHistory();
    const source = axios.CancelToken.source();
    const UsuId = localStorage.getItem('UsuId');
    const Dispvo = localStorage.getItem("Dispvo");
    const auxDispV = Dispvo && Dispvo.length >= 12 ? Dispvo : "451224452121";
    const {generales, guardarGenerales} = React.useContext(GeneralesContext);
    const [espera, setEspera] = useState(false);    
    const [cargandoPerfil, setCargandoPerfil] = useState(null);                
    const [listComite, setListComite] = useState([]);              
    const [listEdges, setListEdges] = useState([]);                

    useEffect(() => {
        const valida=authUser()
        if (valida) 
        {   llenaList()
            guardarGenerales({...generales, Titulo: 'Comités'})    
        }
        else
        {   localStorage.clear();
            history.push('/');
        }

        return ()=> {
            source.cancel();
        }            
    }, []);

    const llenaList=()=>{
        const data = qs.stringify({
            Usuario: UsuId,  idDispositivo: auxDispV,
        });

        const url = 'comite-list'

        function respuesta(aux) {
            if (aux.length !== 0 && aux[0].Id!==-1) {
                
                let arreglo=[], posX=0,posY=0 ,auxEdges=[]     
                let pos=0  , oldFather=0, auxArregloColor=[],padre=[]
                if (aux.length > arreColores.length) 
                {                    
                    for (let index = 0; index < aux.length; index++) 
                    {   const nivel= (index % 18 === 0) ? (index / 18===1 ? 0 :17): (index % 18)
                        auxArregloColor[index] = arreColores[nivel]          
                    }                     
                }      

                aux.forEach((nodo,index)=> padre[index] = `${nodo.Padre}` )
                let biArr=[]

                
                const filtrado = [...new Set(padre)]  
                            
                filtrado.forEach((padre)=> {
                    console.log(padre);
                    const filtro=aux.filter((nodo)=>`${nodo.Padre}` === padre)
                    biArr.push(...filtro)
                } )
                console.log(biArr);
                aux.forEach((nodo,index)=>{
                    pos=`node-${nodo.Id}`
                    const filtro=filtrado.filter((padre)=>padre === `${nodo.Id}`)
                    if (oldFather!==nodo.Padre) 
                    {   oldFather=nodo.Padre
                        posX+=200                        
                        posY+=index === 1 ? 90 : 85
                        //posX+=500                        
                    }
                    else
                    {   posX+= 20
                        posY+=82
                        //posX+= 20
                        //posY+=120
                    }  
                    arreglo.push({id:pos   ,
                        data: { label: nodo.TpNom, 
                                padre:( filtro.length !== 0 ? true: false ),
                                idPadre:nodo.Padre, idNodo:nodo.Id,
                                nomJf:nodo.Jefe,original:nodo
                            },
                        position: { x: posX  , y: posY },
                        type: 'textUpdater',
                    })
                                        
                    if (nodo.Padre!==0) 
                    {
                        auxEdges.push( { 
                            id:`edge-${index+1}`, source: `node-${nodo.Padre}`,
                            target: pos, sourceHandle: 'a',style:{stroke:auxArregloColor[index]} })        
                    }
                                                          
                    
                })                 
                //console.log(aux,arreglo);               
                setListComite(arreglo)
                setListEdges(auxEdges)
            }
        };
        
        llamaApiCargaToken(data, url, respuesta, ErrorGeneral, setEspera, history, source);
    }

    return (
    <Box style={{height:"100%"}}>
        {/* <Esquema2/>
        */}
        {!espera ?
            listComite.length!==0 && listEdges.length!==0 ?
                <NodoProvider>
                    <Flow listComite={listComite} listEdges={listEdges} getDatos={llenaList} />  
                </NodoProvider>
                        
            :null
            :<Box mt={5}><LinearProgress/></Box>
        }
        
    </Box>    
    );
};

export default Comite;
