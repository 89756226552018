import React,{useState,useEffect} from 'react';
import {Box,LinearProgress,//IconButton,Typography
    IconButton,Tooltip,} from "@material-ui/core";
//import MenuIcon from '@material-ui/icons/Menu'; 
import SearchIcon from '@material-ui/icons/Search';
import { authUser } from '../funciones/AuthUser'
import { useHistory } from "react-router-dom";
import {AuthContext} from '../context/AuthContext'
import {GeneralesContext} from '../context/GeneralesContext'
import axios from 'axios';
import qs from 'qs';
import moment from 'moment';
import TablaRegional from './TablaRegional';
import IntervaloSelect from '../perfiles/IntervaloTabla';
//import { makeStyles } from '@material-ui/core/styles';
//import Lista from '../layout/Lista'

 

export default function MunicipioInicio(){
 
    let history = useHistory();
    const {usuario,guardarUsuario}=React.useContext(AuthContext);
    const {generales,guardarGenerales}=React.useContext(GeneralesContext);     
    const [listMuni,setListMuni] = useState([]);
    const [listRegionalTotal,setListRegionalTotal] = useState([]);
    const [listRegion,setListRegion] = useState([]);
    //let fechaMax = moment(new Date()).subtract(1,'month')    
    const [fSelect, setFSelect] = useState({inicio:"Thu Feb 02 2023 00:00:00 GMT-0600 (hora estándar central)",final:new Date()});
    const[actualizar,setActualizar]=useState(true);
    const[espera,setEspera]=useState(false);
    //const drawerWidth = 125;
 useEffect(()=>{ 
    let id =localStorage.getItem('UsuId') ;  
    let usuTipo =localStorage.getItem('Tipo') ;    
    let token =localStorage.getItem('token20') ;
    async function getMunicipios(){ 
        setEspera(false)                
        let fInicio=moment(fSelect.inicio).format("YYYY-MM-DD");
        let fFinal=moment(fSelect.final).format("YYYY-MM-DD");
        //console.log(fInicio);
        let auxU=process.env.REACT_APP_LINK +`/coordinador-lista-total`;            
        let dataInfo = qs.stringify({
            'usuario': id,
            'fini':fInicio+'T00:00:00',
            'ffin':fFinal+'T23:59:59',
            'status':0,
            'tipo':usuTipo,
            'idDispositivo': 'csdq21weddewde' 
            });
        let config = {
            url:auxU ,
            method: 'POST',
            headers: { 'access-token' : token, 'Content-Type': 'application/x-www-form-urlencoded' },
            data: dataInfo
        };

        let aux ={};
        await axios(config)
        .then(function (response) {       
            aux =  response.data;        
             //console.log(aux);
            if (aux[0].MuniId>=0) 
            {                         
                setListMuni(aux);              
                setListRegionalTotal(aux)
                let region=[]
                
                aux.forEach(elemento => {
                    if (elemento.RegionId!==0) 
                    {
                        if (region.length===0 || region.filter((reg)=>reg.Id ===elemento.RegionId ).length === 0) 
                        {
                            region.push({Id:elemento.RegionId,Nom:elemento.RegionNom})      
                        }                        
                    }                    
                });
               // console.log(region);
                setListRegion(region)
            }
        })
        .catch(function (error) {
            console.log(error);
            if ( ( error && error.response  && error.response.data && error.response.data.error && error.response.data.error.name)
                    && ( error.response.data.error.name === "JsonWebTokenError" || error.response.data.error.name === "TokenExpiredError" )) 
            {   localStorage.clear();                    
                history.push("/");                                          
            }
            if ( error && error.response  && error.response.data && error.response.data.mensaje 
                && error.response.data.mensaje === "Datos Incorrectos") 
            {
                console.log('sin datos' );                    
            }
        });
        setEspera(true)        
    }

    async function getRegiones(){                    
        let auxU=process.env.REACT_APP_LINK +`/coordinador-list`;            
        let dataInfo = qs.stringify({
            'usuario': id,            
            'tipo':usuTipo,
            'idDispositivo': 'csdq21weddewde' 
            });
        let config = {
            url:auxU ,
            method: 'POST',
            headers: { 'access-token' : token, 'Content-Type': 'application/x-www-form-urlencoded' },
            data: dataInfo
        };
        let aux ={};
        await axios(config)
        .then(function (response) {       
            aux =  response.data;        
            //console.log(aux);
            if (aux.length!==0) 
            {   aux.sort(function (a, b) { return a.Id - b.Id; });   
                /* let barrido1=aux.filter((elemento)=>elemento.Id!== 0)        
                //console.log(barrido1);  
               for (let index = 0; index < barrido1.length; index++) 
                {   if ( barrido1[index].Nom.search("BIS") >- 1 ) 
                    {   let barrido2=[]              
                        let auxCad=barrido1[index].Nom.slice(0,barrido1[index].Nom.search("B") )              
                        for (let cont2 = 0; cont2 < barrido1.length; cont2++) 
                        {   let cadBarrida = barrido1[cont2].Nom                                             
                            if (auxCad.trim() ===cadBarrida ) 
                            {   barrido2.push(barrido1[cont2])
                                barrido2.push(barrido1[index])
                            }
                            else
                            {   if (barrido1[cont2].Nom.search(auxCad) === -1) 
                                { barrido2.push(barrido1[cont2])
                                }                  
                            }                
                        }                            
                    barrido1=barrido2
                    }                      
                }             */   
                //setListRegion(aux)
               // console.log(barrido1);
                //setListRegion(barrido1)
                
            }
        })
        .catch(function (error) {
            console.log(error);
            if ( ( error && error.response  && error.response.data && error.response.data.error && error.response.data.error.name)
                    && ( error.response.data.error.name === "JsonWebTokenError" || error.response.data.error.name === "TokenExpiredError" )) 
            {   localStorage.clear();                    
                history.push("/");                                          
            }
            if ( error && error.response  && error.response.data && error.response.data.mensaje 
                && error.response.data.mensaje === "Datos Incorrectos" ) 
            {
                console.log('sin datos' );                    
            }
        });          
    }

    const autenticado = authUser();
    if(!autenticado){
        localStorage.clear();
        history.push("/");
        guardarUsuario([])
    }
    else
    {   const usu= localStorage.getItem('UsuarioNom');
        const nvl= localStorage.getItem('Tipo');           
        if (usuario.length===0) 
        {
            guardarUsuario({Nombre:usu,nivel:nvl});                                                 
        }
        //document.title="Municipio";       
       //console.log(fSelect.inicio);
        getMunicipios()     
        //getRegiones()
    }

//la siguiente linea puede generar errores o no en el useEffect
// eslint-disable-next-line react-hooks/exhaustive-deps
},[actualizar]);

    const generarLista = e =>{   
        e.preventDefault()    
        setActualizar(!actualizar)
       // setAuxBandera(1)
    }

    const tabla=<Box display={'flex'} flexDirection="column" >
                    <Box pb={1}>
                        <IconButton color="primary"  aria-label="add circle" 
                            component="span" onClick={generarLista}>
                            <Tooltip title="Buscar">
                            <SearchIcon/>
                            </Tooltip>
                        </IconButton>  
                        <IntervaloSelect fSelect={fSelect}  setFSelect={setFSelect} />
                    </Box>                
                    <Box>
                        <TablaRegional muni={listMuni} listRegion={listRegion} listRegionalTotal={ listRegionalTotal} />
                    </Box>
                </Box>

    const contenido = listMuni.length!==0 && listRegionalTotal.length!==0 &&listRegion.length!==0 && espera? (tabla) :  (<Box pt={1} sx={{width:'100%'}}><LinearProgress/> </Box>);

    return (
    <Box display="flex" flexDirection="row" 
        style={{ marginLeft:'1rem'}}
    >  
        {contenido}
    </Box>
    )
}
 