import React from 'react';
import DateFnsUtils from '@date-io/date-fns';
import esLocale from "date-fns/locale/es";
import {
  DatePicker,  
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import { Box} from '@material-ui/core'
export default function IntervaloSelect({fSelect, setFSelect}){
  const {inicio,final}=fSelect  
  const guardarInicio = auxfecha =>
  {
    setFSelect({
      ...fSelect,
      inicio : auxfecha  
    })
  };
  const guardarFinal = auxfecha =>
  {
    setFSelect({
      ...fSelect,
      final : auxfecha  
    })
  };
    
    return (
    <MuiPickersUtilsProvider locale={esLocale} utils={DateFnsUtils}>    
    <Box component="span"  >
      <Box  ml={1}  component="span" width={1/4}>                                                
        <DatePicker
            disableFuture format="dd/MM/yyyy"
            views={["year", "month", "date"]}
            label="Fecha Inicial" size="small"
            minDate="2021-12-31" maxDate={new Date()}
            value={inicio} onChange={guardarInicio}      
            style={{width:6.5+'rem'}}     
            cancelLabel={"Cancelar"}                  
            okLabel="Seleccionar"     
        />
      </Box>    
      <Box ml={1}  component="span" width="25%">
        <DatePicker
            disableFuture id="final"
            format="dd/MM/yyyy" label="Fecha Final"
            views={["year", "month", "date"]}
            minDate={inicio} maxDate={new Date()}
            value={final} name="final"
            size="small" style={{width:6.5+'rem'}}      
            cancelLabel={"Cancelar"}                  
            okLabel="Seleccionar"    
            onChange={guardarFinal}
            minDateMessage={"La fecha final debe ser menor a Fecha inicial"}
        />
      </Box> 
  </Box>                         
    </MuiPickersUtilsProvider>
    );
}