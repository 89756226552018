import React,{useState,useEffect} from 'react';
import {Box,IconButton,Tooltip,TextField, LinearProgress,
  Breadcrumbs,Typography,Button} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import SearchIcon from '@material-ui/icons/Search';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
//import axios from 'axios';
//import qs from 'qs';
//import { useHistory } from "react-router-dom";
import IntervaloSelect from './IntervaloTabla';
import TablaMuni from './TablaXMuni'
import CardPerfil  from './CardPerfil';
//import ContenedorMapa from './ContenedorMapa';
import MapaPerfil from '../MapaPerfil/Index';
import ModalSeccionXMun from '../MetaXLocacion/ModSeccXMun'; 

const MuniInicio = ({listMuni}) => {
  const [fSelect, setFSelect] = useState({inicio:new Date(),final:new Date()});
  const[actualizar,setActualizar]=useState(true);
  const[totalPerfil,setTotalPerfil]= useState([]);
  const [muniSelect,setMuniSelect]=React.useState([]);
  const [auxBandera,setAuxBandera]=React.useState(0);
  const [arregloColor,setArregloColor] = useState([])  
  const [infoNvlCoord,setInfoNvlCoord] = useState([])   
  const [coordPerfiles,setCoordPerfiles] = useState([])   
  //const [munSvgSelect,setMunSvgSelect]=useState(0)
  const[modalMetaSecc,setModalMetaSecc]=useState(false);
  const [muniGps,setMuniGps] = useState([])   
  const [auxMapVar,setAuxMapVar] = useState([])   

  // let history = useHistory();     
  const generarLista = e =>{   
    e.preventDefault() 
    setTotalPerfil([])   
    setActualizar(!actualizar)
    setAuxBandera(1)
    setMuniGps([])
    setCoordPerfiles([])
  }

  const guardarMuni=(e)=>{
    //console.log(e);
    if (e!== null && e!== undefined) 
    { setMuniSelect(e)            
    }
    else
    { setMuniSelect([])     
    }
    setAuxBandera(0)
    setInfoNvlCoord([]) 
  }

  const letraTipo=(auxNvl,auxNvlId,auxUser)=>{
    //console.log(auxNvl);
    return(
    <span   >
      <Typography color="primary" variant="body2" id={auxUser+auxNvlId} 
        style={{fontSize:.8+'rem',fontWeight:600}}
      >
        {auxUser}
      </Typography>
      <Typography color="textPrimary" variant="body2" id={auxNvl+auxNvlId} 
        style={{fontSize:.7+'rem',textAlign:"center"}}
      >
        {auxNvl}
      </Typography>
    </span>
    )
  }

  const letraTipo2=(auxNvl,auxId,auxUser)=>{
    //console.log(auxNvl);
    return(
    <span   >
      <Typography color="primary" variant="body2" id={auxId} 
        style={{fontSize:.8+'rem',fontWeight:600}}
      >
        {auxUser}
      </Typography>
      <Typography color="textPrimary" variant="body2" id={auxId} 
        style={{fontSize:.7+'rem',textAlign:"center"}}
      >
        {auxNvl}
      </Typography>
    </span>
    )
  }

  const nvlInfo=(lista)=>{
    //console.log(lista);
    return(
    <Box mt={1} mb={2}>
    <Breadcrumbs separator={<NavigateNextIcon  />} aria-label="breadcrumb"  >
      {lista.CZId>0 ? letraTipo(lista.CZNom,lista.CZId,lista.CZUser) : null}
      {lista.CRId>0 ? letraTipo(lista.CRNom,lista.CRId,lista.CRUser) : null}
      {lista.CMId>0 ? letraTipo(lista.CMNom,lista.CMId,lista.CMUser) : null}    
      {lista.CSId>0 ? letraTipo(lista.CSNom,lista.CSId,lista.CSUser) : null} 
      {lista.PrId>0 ? letraTipo(lista.PrNom,lista.PrId,lista.PrUser) : null}
      {lista.MetaValor.length !== 0 ? letraTipo2(lista.MetaTitulo,"meta-mun1",lista.MetaValor) : null}            
      {lista.SeccionValor.length !== 0 ? letraTipo2(lista.SeccionTitulo,"meta-mun1",lista.SeccionValor) : null}
    </Breadcrumbs>
    </Box>
    )
  }

  const modSeccion = modalMetaSecc? <ModalSeccionXMun modalAbierto={modalMetaSecc} nivel={3}
                                    setModalAbierto={setModalMetaSecc}  muniSelect={muniSelect}
                                    nomMuni={muniSelect.Nom} />:null
  return(
  <div>
    <Box display={'flex'} pb={1}>
      <Box >
        <IconButton color="primary"  aria-label="add circle" 
            component="span" onClick={generarLista}>
          <Tooltip title="Buscar">
            <SearchIcon/>
          </Tooltip>
        </IconButton>                                                                                                                              
      </Box>    
      <Box>
        <IntervaloSelect fSelect={fSelect}  setFSelect={setFSelect} />
      </Box>
      <Box pl={1} pt={1}>
      {listMuni.length!==0 ?
        <Autocomplete
          id="municipio-autocomplete-01" size='small'
          onChange={(e,option)=>guardarMuni(option)}
          options={listMuni}
          getOptionLabel={(option) => option.Nom}
          style={{ width: 22+'rem' }}
          noOptionsText={"Sin coincidencias"}
          renderInput={(params) => (
              <TextField {...params} 
              label="Municipio" variant="outlined" />
          )}
        />
      :<LinearProgress/>}
      </Box>            
    </Box>
    <Box display="flex" flexDirection={"row"}>
    {infoNvlCoord.length > 0 ? nvlInfo(infoNvlCoord[0]) :null}
    {infoNvlCoord.length > 0 ?
    <Box alignContent={"center"} ml={2}>
      <Button onClick={()=>setModalMetaSecc(true)} style={{marginTop:1+'rem'}}
       size="small" variant="outlined" color="primary" >Meta</Button>
       </Box>
      :null}
      </Box>
    <Box display="flex" flexDirection={"row"}>
      {totalPerfil.length !== 0 && auxBandera === 1 ? 
        <CardPerfil valor={totalPerfil} />
      :null}
      
    </Box>   
    {  muniSelect.length!==0 && auxBandera===1 ? 
      <Box display={"flex"} row >
        <TablaMuni fSelect={fSelect} actualizar={actualizar} setActualizar={setActualizar}
          muniSelect={muniSelect} setTotalPerfil={setTotalPerfil} setMuniGps={setMuniGps}
          setArregloColor={setArregloColor} setInfoNvlCoord={setInfoNvlCoord}
          setCoordPerfiles={setCoordPerfiles} setAuxMapVar={setAuxMapVar}
        />
        {/* arregloColor.length !== 0 ?
          <span style={{marginLeft:3+'rem'}} >
            <ContenedorMapa coloreados={arregloColor} setMunSvgSelect={setMunSvgSelect}  />
          </span>
          :null */
        }
        { auxBandera===1 && muniGps.length !== 0 ? 
          <MapaPerfil muniGps={muniGps} zoom={13}  nomMuni={muniSelect.Nom} 
            tipo={1} coordPerfiles={coordPerfiles} auxMapVar={auxMapVar} /> 
        : null}
      </Box>
    :null}
    {modSeccion}
  </div>
  );
};

export default MuniInicio;
