import React,{useState,useEffect} from 'react';
import {Doughnut} from 'react-chartjs-2';
import { Card, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({

    card:{
      boxShadow:'0 0.5em 1em -0.125em rgb(10 10 10 / 10%), 0 0 0 1px rgb(10 10 10 / 2%)',
      marginRight:'1.5em'
    },

  });

const MGraficaTotal = ({valor,descripcion,Auxtam,seccion}) => {  
    const alturaCont = window.innerHeight<937 ? window.innerHeight-470 : window.innerHeight-700
    const classes = useStyles();
//    let colorTabla =[];
    const auxCoordi = valor;
    let contador=0; 
    //let miLabel=[];
    //let midata=[];
    const [miLabel,setMiLabel]=  useState([]);       
    const [midata,setMidata]=  useState([]);   
    const [colorTabla,setColorTabla]=  useState([]);       
    
    useEffect(()=>{                         
        let auxMovil=0;
        let auxProm=0;
        if (seccion!==0) {
          let info=valor.filter((dat)=>dat.SeccId === seccion)    
          info.forEach( movili => {
            auxProm = auxProm + movili.Promovido;
            auxMovil = auxMovil + movili.Movilizado;
        });
        }
        else{
            auxCoordi.forEach( movili => {
                auxProm = auxProm + movili.Promovido;
                auxMovil = auxMovil + movili.Movilizado;
            });
         
                                 
            contador++; 
        }
        setMidata([auxMovil,auxProm])
        setMiLabel(["Movilizados "+auxMovil,"Promovidos "+auxProm])   
        setColorTabla(["#1CC63D","#ff1744"])          
    },[seccion,auxCoordi,contador,valor]);
    
   
    
    contador = 0;
    const options = {
      legend: {
        display: true,
      },
      tooltips: {
        enabled: false,
      },
     
    }  
  const horizontalRuta = {        
      labels: miLabel,
      datasets: [
        {
          label: 'Incidencias',//
          backgroundColor: colorTabla,
          borderColor: 'rgb(11,227,210)',
          borderWidth: 0,
          height: 0,
          hoverBackgroundColor: ['#A4EBB2','#FCBBC7'],
          hoverBorderColor: '#00001a',
          data: midata           
        }
      ],
      
    };    
 
    
  return ( 
    <Card className={classes.card} style={{marginTop:1+'rem',height:alturaCont+20}}>    
          <Typography id="ajustarTitulo" style={{textAlign:'center'}}>{descripcion}</Typography>
          {/* <HorizontalBar
              id="graficaHorBarIncidencia"
              data={horizontalRuta}
              width={850}
              height={500}
              options={options}
            />               */}     
            <Doughnut
                data={horizontalRuta}
                width={250}
              height={250}  
              options={options}
            />
    </Card>
   );
}

 
export default MGraficaTotal;