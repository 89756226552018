import React,{useState,useEffect} from 'react';
import DatosCard from './DatosCard';
import Graficas from './Graficas';
import {Table,TableBody,TableCell,TableContainer,TableHead,TableRow
        ,Card,TextField,Box, Paper,CardMedia} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ContenedorMunicipio from './ContenedorMunicipio';
import CardPartidos from './CardPartidos'
import {ordenMapBx,ordenIdColor} from "../MapaEdo/OrdenxMapBox"
import MapDelegacion from '../MapaEdo';
const useStyles = makeStyles({
    root: {
      width: '100%',
    },
    estiloCard:{
        width: '17.5rem',
        boxShadow:'0 0.5em 1em -0.125em rgb(10 10 10 / 10%), 0 0 0 1px rgb(10 10 10 / 2%)',
        color:'#4a4a4a',
        display:'block',
        padding:'1rem',
        maxHeight:'100%',
        minHeight: 280,
        marginRight:'1rem'
    },
    formControl:{
        boxSizing:'border-box',
        fontSize:'1rem',
        position:'relative',
        marginBottom:'1em'
    },
    container: {
      width: '16rem',
      minHeight: 280,
      //minWidth:'100%',
    },
    tablaContainer: {
        width: '16rem',
        maxHeight: window.innerHeight-170,
        minHeight: 280,
        //minWidth:'100%',
      },
    totalEstilo:{
        fontSize:'1.25rem',
        fontWeight:'bold'
    },
    tituloEstilo:{
        paddingBottom:'.25rem',
        fontWeight:'bold'
    },
    tableRow: {
      "&$selected, &$selected:hover": {
        backgroundColor: process.env.REACT_APP_Color_Second
      }
    },
    tableCell: {
      "$selected &": {
        color: "white"
      }
    },
    hover: {},
    selected: {}
  });
  
const alturaCont = window.innerHeight<937 ? window.innerHeight-140 : window.innerHeight-130

export default function TablaMunicipio({muni}){
    //const datos = muni
    const contador = muni.length-1
    const classes = useStyles();
    const [seleccionado,setSeleccionado] = useState([]);
    const [arregloColor,setArregloColor] = useState([])
    const [promotor, setPromotor] = useState({nombre:""})
    const [idSelec,setIdSelec] = useState(-1)
    const [datos,setDatos] = useState([]);
    const [arrMuniTotal,setArrMuniTotal] = useState([]);
    const {nombre} = promotor;        
    let colMap=process.env.REACT_APP_Color_Mapa
    useEffect(()=>{
      //console.log(muni);
        setDatos(muni)
        setSeleccionado(muni[0])
        setIdSelec(muni[0].MuniId)
        let contador=0
        let auxArrMuni=[]
        let sinPerfil=[]
        let auxMun=[]
        //console.log(muni);
        muni.forEach((auxMuni)=>{
            if ( auxMuni.MuniId!==0 && ( auxMuni.Pendiente>0 || auxMuni.Valido >0 || auxMuni.Rechazado > 0 )) 
            {                                              
                auxArrMuni.push(auxMuni)
                contador++; 
            }
            else
            {   if (auxMuni.MuniId!==0) {
                    sinPerfil.push(auxMuni)
                }                
            }
        })

        if (auxArrMuni.length>0) 
        {   //console.log(auxArrMuni);              
            setArrMuniTotal(auxArrMuni)   
            auxArrMuni.forEach((municipio)=>{
                let auxNvo= ordenIdColor(municipio.MuniNum)
                //auxMun.push({Id:auxNvo,auxColor:process.env.REACT_APP_Fondo_Color}) //seleccionado
                ordenMapBx(auxMun,auxNvo,colMap) 
            })                                
        }              

        if (sinPerfil.length>0) 
        {   sinPerfil.forEach((auxMuni)=>{
                let auxNvo= ordenIdColor(auxMuni.MuniNum)
                //auxMun.push({Id:auxNvo,auxColor:auxMuni.PartColor})                    
                ordenMapBx(auxMun,auxNvo,auxMuni.PartColor) 
            })            
        }

        setArregloColor(auxMun)                        

        //la siguiente linea puede generar errores o no en el useEffect
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[muni])



    const RenglonNum =(valorId,regis)=>{
        setIdSelec(valorId);
        //let info=datos.filter((dat)=>dat.municipio_id=== valorId) ;        
        setSeleccionado(regis);
        let auxNvo =regis.MuniNum
        if (regis.MuniNum > 0) 
        {   auxNvo=ordenIdColor(regis.MuniNum)            
            //agregamos únicamente el que se va a colorear (reinicia todo)
            let arregloColoreado=[]    
            //arregloColoreado.push({Id:auxNvo,auxColor:process.env.REACT_APP_Fondo_Color})    
            ordenMapBx(arregloColoreado,auxNvo,colMap) 
            let datFiltra=datos.filter((region)=>region.MuniId!== regis.MuniId && region.MuniId!==0)
            if (datFiltra.length>0) 
            {
                datFiltra.forEach((municipio)=>{                      
                    let auxNvo =municipio.MuniNum            
                    if (auxNvo > 0) 
                    {                 
                        auxNvo=ordenIdColor(municipio.MuniNum)
                        ordenMapBx(arregloColoreado,auxNvo,municipio.PartColor) 
                        //arregloColoreado.push({Id:auxNvo,auxColor:municipio.PartColor})                        
                    }                              
                })    
            }
            setArregloColor(arregloColoreado)
        } 
        else
        {   let datFiltra=datos
            let arregloColoreado=[]    
            //let auxMun=[]
            if (arrMuniTotal.length>0) 
            {   
                console.log(arrMuniTotal); 
                arrMuniTotal.forEach((auxMuniTot)=>{
                   let auxNvo= ordenIdColor(auxMuniTot.MuniNum)
                    datFiltra=datFiltra.filter((region)=>region.MuniId!== auxMuniTot.MuniId )
                    ordenMapBx(arregloColoreado,auxNvo,colMap) 
                    //arregloColoreado.push({Id:auxNvo,auxColor:process.env.REACT_APP_Fondo_Color})
                })                                                               
            }
            datFiltra=datFiltra.filter((region)=>region.MuniId !==0)
            datFiltra.forEach((municipio)=>{
                let auxNvo =municipio.MuniNum            
                if (auxNvo > 0) 
                {                 
                    auxNvo=ordenIdColor(municipio.MuniNum)
                    ordenMapBx(arregloColoreado,auxNvo,municipio.PartColor) 
                    //arregloColoreado.push({Id:auxNvo,auxColor:municipio.PartColor})                        
                }                              
            })
            setArregloColor(arregloColoreado)          
        }
        // setInfoProm(info[0]);
    }

    function filtro(){        
        let auxCadena=nombre.trim();
       // console.log(auxCadena);
        let info=[];//datos[0];     
        let aux=  datos.filter((dat)=>dat.MuniNom.toUpperCase().search(auxCadena.toUpperCase() ) !== -1) 
        info.push(datos[0])     
        let auxNum=0;
        if (aux!==null||aux!=='') 
        {   aux.forEach((mun)=>
            {   if (mun.MuniNom!=='EDOMEX')
                {   info.push(mun);    
                    auxNum++;
                }                
            })
        }   
        return TablaPromo(info,auxNum);
    }
     
    const onChange = e =>
    {         
        let expNopermitida = new RegExp('[0-9._:$!%-({})/*|]');
        let expMenos = new RegExp('-');
        let expMas = new RegExp('[+]');     
        let expCadena = new RegExp('[A-Za-z]');
        if ((expCadena.test(e.target.value) && !expNopermitida.test(e.target.value) && !expMenos.test(e.target.value) 
            && !expMas.test(e.target.value)) || e.target.value===' '||e.target.value==='') 
        {
            setPromotor({
                ...promotor, //copiamos el nombre
                [e.target.name] : e.target.value  
            })
        }
    };
    
    function TablaPromo(auxDatos,total) {
        let numRenglon=0;
       // console.log(auxDatos);
        return(
            <Paper className={classes.table}>
            <TableContainer className={classes.tablaContainer} id="tamTablaMunicipios" style={{height:alturaCont-100}}>
                <Table stickyHeader aria-label="sticky table">
                    <TableHead >    
                        <TableRow>            
                            <TableCell padding='none' style={{backgroundColor:process.env.REACT_APP_Fondo_Color,color:'white',  borderBottom:"1px solid #2A3345"}}>
                                {total} Municipios
                            </TableCell>            
                        </TableRow>                        
                    </TableHead>  
                    <TableBody>                                  
                        {auxDatos.map((municipio) => {
                            let auxNombre="";
                            auxNombre=municipio.MuniNom.charAt(0)+ municipio.MuniNom.slice(1).toLowerCase()//municipio.MuniNom;    
                            numRenglon++;                                                                                                            
                            return (                  
                                <TableRow className={classes.tableRow} selected={ idSelec === municipio.MuniId} 
                                    classes={{ hover: classes.hover, selected: classes.selected }} 
                                    key={numRenglon} onClick={ () => RenglonNum(municipio.MuniId,municipio) }  
                                >                                
                                    <TableCell className={classes.tableCell}   padding='none'>
                                        <span>{auxNombre}</span>
                                    </TableCell>                                                    
                                </TableRow>              
                                );                        
                            })
                        }                                                
                    </TableBody>
                </Table>
            </TableContainer>
            </Paper>
        ); 
    }

    const edomexSvg=<Box id="contenedorMapa"  >                        
                        <ContenedorMunicipio coloreados={arregloColor}/>
                    </Box> 
                   
    //const valorColores=arrMuniTotal.length!==0 ?llenaSVGTotales(arrMuniTotal):null                        
    const datGeneral = (<Box display="flex" flexDirection="column"  >
                            {seleccionado.length !== 0 ?  
                            <Box display="flex" flexDirection="row" >
                                <DatosCard valor={seleccionado}/>
                                <CardPartidos  />  
                            </Box>
                            :null}
                            {<Box display="flex" flexDirection="row">
                            { arregloColor.length>0?
                                <MapDelegacion  arregloColor={arregloColor}/>
                                :null}                                               
                                { /* edomexSvg */}
                                <div style={{marginTop:1+'rem'}}>
                                    <Graficas valor={seleccionado} />
                                </div>
                            </Box>
                            }
                        </Box>) 
    const contenido = nombre.length>0? (filtro()):(TablaPromo(muni,contador));    
    const llamada= datos.length !== 0 ?(contenido):(<div style={{paddingTop:1+'rem'}}><h2>No contiene datos</h2></div>)

    return (
    <Box display={'flex'} flexDirection="row" style={{height: alturaCont-10}}>
        <Card className={classes.estiloCard}>                        
            <TextField className={classes.formControl}
                name="nombre" placeholder="Municipio"
                value={nombre} onChange={onChange} id="input-filtro-muni01"
            />	            
            {llamada} 
            {/* valorColores */}           
        </Card>
        {datGeneral}
    </Box>                    
    );
}