import { useState, useCallback,useContext,useEffect } from 'react';
import ReactFlow, { Background, Controls, 
    applyNodeChanges, applyEdgeChanges} from 'reactflow';
import NodoUpdate from './Nodo';
import { NodoContext } from './ContextEsq';
import ModalNvoEdit from './ModalEdit';
import axios from 'axios';
import qs from 'qs';
import { useHistory } from "react-router-dom";
import {llamaApiCargaToken,llamadaApiToken,ErrorGeneral } from '../funciones/ClienteAxios';
import ModalAgregarEvidencia from './ModalAgregarEvidencia';
/* const nodes = [
  {
      id: '1',
      position: { x: 0, y: 0 },
      data: { label: 'Hello' },
      type: 'textUpdater',
    },
    {
      id: '2',
      position: { x: 100, y: 100 },
      data: { label: 'World' },
    },
  ]; */

  //const initialEdges2 = [{ id: 'e1-2', source: "1", target: "2" }];
  //const initialEdges2 = [{ id: '1-2', source: '1', target: '2' },{ id: '2-3', source: '2', target: '3' }];
  /* const initialNodes = [
    {
      id: '1',
      data: { label: 'Hello' },
      position: { x: 0, y: 0 },
      type: 'textUpdater',
    },
    {
      id: '2', type: 'output',
      data: { label: 'World' },
      position: { x: 100, y: 100 },
    },
  ]; */
  
  //const initialEdges = [{ id: '1-2', source: '1', target: '2', label: 'to the', type: 'step' }];
  /* const initialEdges = [
    { id: 'edge-1', source: 'node-1', target: 'node-2', sourceHandle: 'a' },
    { id: 'edge-2', source: 'node-1', target: 'node-3', sourceHandle: 'b' },
  ]; */

  const nodeTypes = { textUpdater: NodoUpdate };
  

function Flow({listComite,listEdges,getDatos}) {
  const history = useHistory();
  const source = axios.CancelToken.source();
  const auxId =localStorage.getItem('UsuId') ;  
  const Dispvo =localStorage.getItem('Dispvo') ;    
  const auxDispV =Dispvo && Dispvo.length >= 12 ? Dispvo:'w8rf51v21dsd2cs'    
  const [nodes, setNodes] = useState(listComite);
  const [edges, setEdges] = useState(listEdges);
  const {nodoSeleccionado,setNodoSeleccionado}=useContext(NodoContext);
  const [modalAbierto,setModalAbierto]=useState(false)
  const [modalImg,setModalImg]=useState(false)
  const [registro,setRegistro]=useState([])
  const[listTipo, setListTipo] = useState([])      

  useEffect(() => {
    if (listTipo.length===0) 
    {
      llamadaListTipo()
    }          
    return ()=> {
      source.cancel();
    } 

  }, [])

  useEffect(() => {
   // console.log(nodoSeleccionado);
    if (nodoSeleccionado.length!==0) 
    {
      if ( nodoSeleccionado.bandera) 
      {     
        setModalAbierto(nodoSeleccionado.bandera)
        setRegistro(nodoSeleccionado.nodo)
      }
      else
      { if ( nodoSeleccionado.modImg) 
        {     
          setModalImg(nodoSeleccionado.modImg)
          setRegistro(nodoSeleccionado.nodo)
        }
      }  
    }          
    
  }, [nodoSeleccionado])
    
  function llamadaListTipo()  {            
    const auxU= `comite-tipo-list`;                            
    const data = qs.stringify({                
        Usuario:auxId,                          
        idDispositivo:auxDispV,
        });
              
    function respuesta (aux) 
    { if (aux[0].Id !== -1) 
      {                                                    
        setListTipo(aux)
      }                                                            
    }
    llamadaApiToken(data, auxU, respuesta, ErrorGeneral,  history, source);
  };
  
  const onNodesChange = useCallback(
    (changes) => setNodes((nds) => applyNodeChanges(changes, nds)),[]
  );
     /*  const onEdgesChange = useCallback(
        (changes) => setEdges((eds) => applyEdgeChanges(changes, eds)),
        []
      ); */
    /*   const onConnect = useCallback(
        (connection) => setEdges((eds) => addEdge(connection, eds)),
        [setEdges]
      );
     */

  const modal=  modalAbierto ? <ModalNvoEdit modalAbierto={modalAbierto} setModalAbierto={setModalAbierto} 
      titulo={`Editar Registro ${registro.idNodo}`} listTipo={listTipo} auxReg={registro} getDatos={getDatos} />
      :null

  const modimg =  modalImg ? <ModalAgregarEvidencia modalAbierto={modalImg} setModalAbierto={setModalImg} 
      titulo={`Imagen Registro ${registro.idNodo}`}   registro={registro} setUpdate={getDatos} />
      :null      
  return (
  <div style={{ height:"83vh"  }}> 
    {modal} 
    {modimg}
    <ReactFlow nodes={nodes} edges={edges} 
      nodeTypes={nodeTypes}
      // onNodesChange={onNodesChange}
      // onEdgesChange={onEdgesChange}
      //  onConnect={onConnect}      
    >
      <Background />
      <Controls />
    </ReactFlow>
    
  </div>
  );
}

export default Flow;