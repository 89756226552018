import React,{useState,useEffect} from 'react';
import {Box, IconButton,Table,TableBody,TableCell,TableContainer,TableHead,TableRow,TextField,
    Tooltip,Card,TablePagination,LinearProgress} from '@material-ui/core';
import SaveIcon from '@material-ui/icons/Save';
import { makeStyles } from '@material-ui/core/styles';
import XLSX from 'xlsx';
import moment from 'moment';
import ModalMovilizar from './ModalMovilizar';
import PaginacionTab from '../layout/PaginacionTab'
const useStyles = makeStyles({
    root: {
      width: '100%',
    },
    container: {
      width: '600px',
      minHeight: 280,
      backgroundColor:'white',
      boxShadow:'0 0.5em 1em -0.125em rgb(10 10 10 / 10%), 0 0 0 1px rgb(10 10 10 / 2%)',
    },
    formControl:{
        boxSizing:'border-box',
        fontSize:'1rem',
        position:'relative',
        marginBottom:'1em'
    },
    buscador:{
    width:'30em'
    ,marginLeft:'.8em'
    },
    estiloCardDatos:{
        color:'white',
        textAlign:'center',
        fontWeight:600,
        fontSize:.8+'rem'
    },
    titulos:{
        padding: 0.2 +'rem', 
        color:'#0F82EE',
        fontWeight:600
    },
    elementos:{
        alignContent:'center',
        padding: 0.2 +'rem'
    },
    tableRow: {
      "&$selected, &$selected:hover": {
        backgroundColor: "#0F91C1"
      }
    },
    tableCellNombre: {
        color:'#0F82EE',
        "$selected &": {
            color: "white"
          }
      },
    tableCell: {
      "$selected &": {
        color: "white"
      }
    },
    hover: {},
    selected: {}
  });

  const columnas=[{
    id:2,
    titulo:"Nombre",
    alinea:'left'
  },
  {
    id:3,
    titulo:"Status",
    alinea:'center'
  },
  {
    id:4,
    titulo:"Tel",
    alinea:'center'
  },
  {
    id:5,
    titulo:"Cel",
    alinea:'center'
  },
  {
    id:6,
    titulo:"Hora",
    alinea:'center'
  },


];


export default function TablaPromov({lista,secc}){
    const alturaTabla = window.innerHeight<937 ? (lista.length>300 ? window.innerHeight-260 : window.innerHeight-210) 
                        : (lista.length>300 ? window.innerHeight-350 : window.innerHeight-300)
    const classes = useStyles();
    let auxArreglo=[]
    const auxColor=process.env.REACT_APP_Color_Second
    const [espera,setEspera] = useState(false)
    const [datos,setDatos] = useState([])
    const [idSelec,setIdSelec]=  useState(-1);      
    const [promovido,guardarPromovido] = useState({ nombre:''});
    const [seleccionado, setSeleccionado]=useState("");
    const [modalMov, setModalMov] = useState(false);
    const [totalPaginas,setTotalPaginas]=useState(1);
    const [numPagina,setNumPagina]=useState(0);

    useEffect(()=>{
        setEspera(true)
        if(lista.length>300){
            let auxTam = lista.length;
            if(auxTam%300===0){
                let pagina=Math.trunc(auxTam/300)
                setTotalPaginas(pagina)   
            }else{
                let pagina=Math.trunc(auxTam/300)
                pagina=pagina+1;
                setTotalPaginas(pagina)
            }
            setNumPagina(0) 
            setDatos(lista.slice(0,300))
        }else{
            setTotalPaginas(1)
            setNumPagina(0)
            setDatos(lista)
        }
        setEspera(false)
    },[lista])

    const exportarArch = () => {
        let today = new Date();                    
        let fec = moment(today);
        const ws = XLSX.utils.aoa_to_sheet(auxArreglo);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "Reporte");
        XLSX.writeFile(wb, fec.format("YYMMDDHHmmss")+" Lista de promovidos de " +secc+" .xlsx")
    };

    function selecFila(reg){                
        setIdSelec(reg.Id);
    }
    

    function filtro(){        
        let auxCadena=nombre.trim();                 
        auxArreglo=[]               
        let info=datos.filter((dat)=>dat.Nombre.search(auxCadena.toUpperCase() ) !== -1)    
        return generarTabla(info);
    }

    function abrirModalMov(promov){
        setModalMov(!modalMov)
        setSeleccionado(promov)
        console.log(seleccionado)
    }

    function generarColor(valor,color){
        var colorEfecti='';    
        if(color===0){
            colorEfecti= '#FF0505E8';        
        }
        else{
            if(color===1)
             {
                 colorEfecti= '#1CC63D';
            }
        }
        return(
            <Card className={classes.estiloCardDatos} style={{backgroundColor:colorEfecti}}>
               {valor}
            </Card>
        )
    }

    function sinDatos(){
        return(
            <TableContainer className={classes.container} style={{height: datos.length>0 ? alturaTabla : 150}}>
                <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableRow >                                        
                            <TableCell padding="none" align="center" style={{backgroundColor:auxColor,color:'white'}}>
                                {datos.length}    
                            </TableCell>                                  
                            {columnas.map(row=>{
                                 return <TableCell padding='none' align={row.alinea} key={row.id} style={{backgroundColor:auxColor,color:'white'}}>{row.titulo}</TableCell>
                            })}                                    
                        </TableRow>                        
                    </TableHead>  
                    <TableBody> 
                        <TableCell padding='none' align="center">Sin</TableCell>
                        <TableCell padding='none' align="center" ></TableCell>
                        
                        <TableCell padding='none' align="center">Resultados</TableCell>
                        <TableCell padding='none' align="center"></TableCell>
                        <TableCell padding='none' align="center">Disponibles</TableCell>       
                    </TableBody>
                </Table>
            </TableContainer>
        )
    }

    function onChangePagina(event, newPage){
        event.preventDefault();
        if (newPage ===0) 
        {
            setDatos(lista.slice(0,300))   
        }
        else
        {
            setDatos(lista.slice(newPage * 300, newPage * 300 + 300))   
        }
        setNumPagina(newPage)
    }

    function generarFooter(){
        return(
                <TablePagination
                rowsPerPageOptions={false}
                colSpan={3}
                rowsPerPage={300}
                count={lista.length}
                page={numPagina}
                lang='es'
                labelDisplayedRows={
                    ({ from, to, count }) => {
                      return '' + from + '-' + to + ' de ' + count
                    }
                  }
                onPageChange={onChangePagina}
                ActionsComponent={PaginacionTab}/>)
    }

    function generarTabla(list) {
        let tot= Object.keys(list).length;
        let auxi2=["#","Nombre","Estatus","Tel","Cel","Horario"];    
        auxArreglo.push(auxi2);
        
        if(tot===0){
                let auxi3=["","Sin","Incidencias" ,"de este ","tipo"]
                auxArreglo.push(auxi3);            
        } 

        return(
            <TableContainer className={classes.container} style={{height: list.length>0 ? alturaTabla : 150}}>
                <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableRow >                                        
                            <TableCell padding="none" align="center" style={{backgroundColor:auxColor,color:'white'}}>
                                {list.length}    
                            </TableCell>                                  
                            {columnas.map(row=>{
                                 return <TableCell padding='none' align={row.alinea} key={row.id} style={{backgroundColor:auxColor,color:'white'}}>{row.titulo}</TableCell>
                            })}                                    
                        </TableRow>                        
                    </TableHead>  
                    <TableBody> 
                    {list.map((promov,index) => {  
                        
                        let auxMovi = promov.Moviliza ===1 ?"Movilizado" :"Pendiente";                                                            
                            let auxi=[ index+1 ,auxMovi , promov.Hora, promov.Nombre , promov.Tel,promov.Cel ]
                            auxArreglo.push(auxi);    
                        return(    
                                <TableRow className={classes.tableRow} selected={idSelec === promov.Id}  key={index}
                                    classes={{ hover: classes.hover, selected: classes.selected }} onClick={ () => selecFila(promov) }
                                > 
                                <TableCell padding='none' align="center" className={classes.tableCell} style={{width:1+'rem'}}>
                                            <Tooltip title="Movilizar Promovido">
                                                <IconButton size="small" component="span" onClick={() => abrirModalMov(promov)}>
                                                    <img src={idSelec === promov.Id ? "menuBlanco.svg" : "menu.svg"}  alt="" 
                                                    border="0" height="20" width="20"/>
                                                </IconButton>
                                            </Tooltip>          
                                </TableCell> 
                                <TableCell padding='none' className={classes.tableCellNombre} align="left" style={{fontSize:'13px',fontWeight:500,width:12+'rem',paddingLeft:'.4em'}} >
                                        {promov.Nombre}
                                </TableCell>                            
                                <TableCell padding='none' className={classes.tableCell} align="center"  style={{fontSize:'12px',width:3.5+'rem'}} >
                                        {generarColor(auxMovi,promov.Moviliza)}
                                 </TableCell>                                                                                                 
                                <TableCell padding='none' className={classes.tableCell} align="center" style={{fontSize:'12px',width:3+'rem'}} >
                                        {promov.Tel}
                                </TableCell>    
                                <TableCell padding='none' className={classes.tableCell} align="center" style={{fontSize:'12px',width:3+'rem'}} >
                                        {promov.Cel}
                                </TableCell>
                                <TableCell padding='none' className={classes.tableCell} align="center"  style={{fontSize:'12px',width:3+'rem'}} >
                                        <b>{promov.Hora}</b>
                                 </TableCell>
                                </TableRow>)
                    })} 
                    </TableBody>
                </Table>
            </TableContainer>
        )   
    }

    const {nombre} = promovido;        
    const onChange = e =>
    {         
        let expNopermitida = new RegExp('[0-9._:$!%-({})/*|]');
        let expMenos = new RegExp('-');
        let expMas = new RegExp('[+]');     
        let expCadena = new RegExp('[A-Za-z]');
        if ((expCadena.test(e.target.value) && !expNopermitida.test(e.target.value) && !expMenos.test(e.target.value) &&!expMas.test(e.target.value))
            ||e.target.value===' '||e.target.value==='') 
        {
            guardarPromovido({
                ...promovido, //copiamos el nombre
                [e.target.name] : e.target.value  
            })
        }


        
    };

    const paginacion = totalPaginas>1 ? generarFooter() : null;
    const modMovi = modalMov ? <ModalMovilizar modalAbierto={modalMov} setModalAbierto={setModalMov} 
                                seleccionado={seleccionado} datos={datos} setDatos={setDatos}/> : null;
    const contenido = promovido? (filtro()):(generarTabla(datos));
    const validaCont = datos.length!==0 ? contenido : sinDatos();
    const esperando = espera ? <Box style={{width:400+'px'}}><LinearProgress/></Box> : validaCont
    return (
        <div>
                        <Card style={{height:'3em',marginBottom:'.5rem', width:400}}>
                            <Box display="flex" flexDirection="row" style={{marginBottom:'1em'}}>
                                <TextField className={classes.buscador}
                                    disabled={datos.length===0}
                                    name="nombre"                         
                                    placeholder="Buscar Promovido"
                                    value={nombre}
                                    onChange={onChange}/>   
                                {datos.length!==0 ? 
                                <IconButton  onClick={()=>{}} style={{marginLeft:'.3em',marginTop:'.5em',width:'20px', height:'20px',marginRight:'.4em'}}>
                                    <Tooltip title="Exportar Promovidos">
                                        <SaveIcon color="primary"/>
                                    </Tooltip>
                                </IconButton>
                                : null}
                            </Box>
                        </Card>	            
                        {esperando}
                        {paginacion}
                        {modMovi}
        </div>
      );
}
 