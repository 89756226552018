import React,{useState} from "react";
import { makeStyles } from "@material-ui/core/styles";
import { FormControl, FormLabel, FormControlLabel, RadioGroup,
      Radio, Typography, Box, } from "@material-ui/core";
import axios from "axios";
import qs from "qs";
import { useHistory } from "react-router-dom";
import Modal from "../generales/ModalCerrarActualiza";
import {ErrorGeneral, llamaApiCargaToken,} from "../funciones/ClienteAxios";
const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
      width: "20ch",
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
    Textnumero: {
      width: 5 + "px",
    },
    formControlFull: {
      //margin: theme.spacing(1),
      //minWidth: 120,
      width: 5 + "rem",
    },
    margin: {
      margin: theme.spacing(1),
    },
  },
}));

const EditarStatusProspecto = ({ modalAbierto, setModalAbierto, titulo, actualizar, seleccion,listStatus }) => {
  let history = useHistory();
  const classes = useStyles();
  const [nvoCliente, setNvoCliente] = useState({ idUsu: "", stdUsu: "" });
  const [espera, setEspera] = useState(false);
  const [mensaje, setMensaje] = useState("");
  const [tipoAdvertencia, setTipoAdvertencia] = useState("success");
  const [btnDeshabilitado, setBtnDeshabilitado] = useState(false);
  const [aviso, setAviso] = useState(false);
  const [cambioEfectivo, serCambioEfectivo] = useState(false);
  const { stdUsu } = nvoCliente;
  //let colorCheckR='#0EA7E0'
  const source = axios.CancelToken.source();
  React.useEffect(() => {
    setNvoCliente({
      ...nvoCliente,      
      stdUsu: seleccion.StatusId.toString(),
    });
  }, []);


  const llamadaEditStd = (auxStatus) => {  
    let usuId = localStorage.getItem("UsuId");          
    let data = qs.stringify({
      Usuario:usuId,
      Llave: seleccion.Id,
      Status: stdUsu,
      idDispositivo: "451224452121",
    });

    let url = "prospecto-edit-status";

    function respuesta(aux) {
      if (aux.length !== 0) {
        //setNvoRegistro(aux[0]);        
        setBtnDeshabilitado(true);
        let mensaje = "Se edito correctamente el Estatus del Prospecto.";
        setTipoAdvertencia("success");
        setMensaje(mensaje);
        setAviso(true);
        serCambioEfectivo(true);
        actualizar()
        setModalAbierto(false)
      }
    }

    llamaApiCargaToken(data, url, respuesta, ErrorGeneral, setEspera, history, source);
  }

  const mandaAlerta = (mensaje, tipoAdvert) => {
    setMensaje(mensaje);
    setTipoAdvertencia(tipoAdvert);
    setAviso(true);
  };

  function guardarDatos(e) {
    e.preventDefault();

    llamadaEditStd();
  }
  
  const onChange = e =>
  {  
    setNvoCliente({ ...nvoCliente,          
      stdUsu: e.target.value  
    })        
  };

  const DatosGenerales = (
    <div>
      <Typography
        variant="h5" id="Nombre-input" size="small"
        style={{ paddingBottom: 0.5 + "rem", width: 35 + "rem" }}        
      >
        {seleccion.Nombre}
      </Typography>
      <FormControl component="fieldset" style={{marginLeft:.5+'rem'}}>          
        <FormLabel  >Estatus</FormLabel>        
        <RadioGroup  row aria-label="position"  defaultValue="top"
          name="stdUsu" value={stdUsu} onChange={onChange}
        >
          {listStatus.map((elemnt)=>{
            
            return elemnt.Id!==0?<FormControlLabel value={elemnt.Id.toString()}  label={elemnt.Nom}
            style={{color:elemnt.Color}}
            control={<Radio color="primary" />} />    :null
          })}
          
                        
        </RadioGroup>                     
    </FormControl>      
    <Box display={"flex"} justifyContent="center" row>
      {seleccion.Img1Mini &&seleccion.Img1Mini.length!==""?
      <img
        src={seleccion.Img1Mini} className={classes.imageSize}
        alt={"imagen mini 1 "} id={"img-min1"} key={0}
        name={"imagen mini 1 "} width={"45%"}
      />:null}
      {seleccion.Img2Mini && seleccion.Img2Mini.length!==""?
      <img
        src={seleccion.Img2Mini} className={classes.imageSize}
        alt={"imagen mini 2 "} id={"img-min2"} key={0}
        name={"imagen mini 2 "} width={"45%"}
      />:null}
      </Box>
    </div>
  );

  const cerrarBorrar = () => {    
    setAviso(false);
    setModalAbierto(false);
    if (cambioEfectivo) {
      actualizar()
    }
  };

  return (    
  <Modal  titulo={titulo}  modalAbierto={modalAbierto}
    setModalAbierto={setModalAbierto} guardarDatos={guardarDatos}
    mensaje={mensaje} tipoAdvertencia={tipoAdvertencia}
    aviso={aviso} btnDeshabilitado={btnDeshabilitado} setAviso={setAviso}
    tamanio={"sm"} cargando={espera} cerrarBorrar={cerrarBorrar}
  >
    {DatosGenerales}
  </Modal>
  );
};

export default EditarStatusProspecto;
