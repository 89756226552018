import React, {useState, useEffect} from "react";
import axios from "axios";
import qs from "qs";
import { useHistory } from "react-router-dom";
import ModalInformativo from '../generales/ModalInformativo';
import {Box, LinearProgress, Table,TableHead,TableCell,
        TableContainer,TableRow, TableBody,Card,IconButton,Tooltip,} from '@material-ui/core';
import { makeStyles } from "@material-ui/core/styles";
import moment from "moment";
import {llamaApiCargaToken,llamadaApiToken,ErrorGeneral } from '../funciones/ClienteAxios';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';

const useStyles = makeStyles((theme) => ({
    root: {
        alignItems: 'center',
        display:'flex',
        flexDirection:'row'
    },
    imagen:{
        marginLeft:'1.5em',
        width: 460+"px",
        height: 460+"px",
    },
    tablaEv:{
        width:420+'px',
    },
    tableRow: {
        "&$selected, &$selected:hover": {
          backgroundColor: process.env.REACT_APP_Color_Second 
        }
      },
      tableCellFecha:{
        color:process.env.REACT_APP_Fondo_Color,
        "$selected &": {
            color: "white"
          }
      },
      tableCell: {
        "$selected &": {
          color: "white"
        }
      },
      hover: {},
      selected: {}
}));

export default function ModalGaleria ({modalAbierto,setModalAbierto,seleccionado,setModalEvidencia,procSel}){
    const classes = useStyles();
    const history = useHistory();   
    const Dispvo =localStorage.getItem('Dispvo') ;    
    const auxDispV =Dispvo && Dispvo.length >= 12 ? Dispvo:'w8rf51v21dsd2cs'  
    const auxCol=process.env.REACT_APP_Fondo_Color
    const source = axios.CancelToken.source();
    const [listaDatos, setListaDatos] = useState([]);
    const [espera, setEspera] = useState(false);
    
    const [idSelec,setIdSelec] = useState(-1)
    const [evidenciaSel,setEvidenciaSel] = useState([])    
    const auxEstil={width:'20px', height:'20px'}
    useEffect(()=>{
        //console.log(seleccionado);               
        consultarEvidencia();

        return ()=> {
            source.cancel();
        }    
    },[seleccionado])

    const consultarEvidencia = () => {            
        const data = qs.stringify({
            periodo:procSel,
            seccion:seleccionado.seccId,
            idDispositivo: auxDispV,
        });
        const url = "votacion-evidencia-list";
        function respuesta(aux) {
            if (aux[0] && aux[0].Id && aux[0].Id !== -1)
            {  
                //console.log(aux);
                setListaDatos(aux);                                                              
            }
        }
        llamaApiCargaToken(data, url, respuesta,ErrorGeneral,setEspera,history,source);
    };

    function selecFila(elem){
        setIdSelec(elem.Id)
        setEvidenciaSel(elem)
    }

    const abrirModEvidencia=()=>{
        setModalEvidencia(true)
        setModalAbierto(false)
    }

    const celda = (alinear, estilo, contenido,index) => {
        return (
        <TableCell align={alinear} key={index} className={classes.tableCell} 
            padding={'none'} style={estilo} >
            {contenido}
        </TableCell>)
    }
    
    const CelTitl=({index,titulo,aline})=> celda(aline?aline:"center", {backgroundColor:auxCol,color:'white',}, titulo?titulo:"",index) 

    function listarResultados(){        
        return(
        <Box className={classes.root}>
            <Card style={{height:450}}>
            <TableContainer className={classes.tablaEv} id="tablaFot" style={{height:420}}>
                <Table stickyHeader aria-label="sticky table">
                    <TableHead >    
                        <TableRow>        
                            <CelTitl index={1} titulo={
                                <Tooltip title="Asignar Votos">
                                 <IconButton  onClick={abrirModEvidencia} style={auxEstil}>
                                 
                                     <AddCircleOutlineIcon style={{color:"white"}} />
                                     {listaDatos.length}                                 
                                    </IconButton>
                                </Tooltip>
                                } aline="center" />
                            <CelTitl index={2} titulo={"Fecha"} aline="center" />                                
                            <CelTitl index={3} titulo={"Evidencia"} aline="left" />                                                            
                            <CelTitl index={4} titulo={"Casilla"} aline="center" />                                                                                        
                        </TableRow>
                    </TableHead>
                    <TableBody>
                    {listaDatos.map((res,index)=>{
                        const auxF = res.Fecha ?(moment.utc(res.Fecha).format('YYYY-MM-DD HH:mm') === "1900-01-01 00:00" ? 
                        "" : moment.utc(res.Fecha).format('DD/MM/YYYY HH:mm')) : null
                        const auxLink=res.Link.length > 28 ? 
                                    `${res.Link.substring(0,22)}...${res.Link.substring(res.Link.lastIndexOf("."),res.Link.length)}` 
                                    : res.Link
                        return(
                        <TableRow className={classes.tableRow} selected={ idSelec === res.Id} 
                            classes={{ hover: classes.hover, selected: classes.selected }} 
                            key={index} onClick={ () => selecFila(res) }  
                        >   
                            {celda("center", {width:20}, <b>{res.Id}</b>,index)}
                            {celda("center", {width:60,color:idSelec === res.Id?"white": auxCol}, <b>{auxF}</b>,index+1)}                            
                            {celda("left", {width:110,paddingLeft:.2+'em'},auxLink,index+2)}                            
                            {celda("center", {width:50+'px',paddingRight:.2+'em'}, res.IdCasilla,index+3)}                                                        
                        </TableRow>
                        )      
                    })}
                    </TableBody>
                </Table>
            </TableContainer>
            </Card>
            <Card className={classes.imagen}>
                {evidenciaSel.length!==0 ? 
                    <img src={evidenciaSel.Dir+evidenciaSel.Link} alt="evidencia"
                    width="450" height="450" style={{marginLeft:5+'px',marginTop:5+'px'}}/>
                :null}
            </Card>   
        </Box>)
    }

    function sinResultados(){
        return(
        <Box className={classes.root}>
            <Card style={{height:170}}>
            <TableContainer className={classes.tablaEv} id="tablaEvidencia"  style={{height:150}}>
                <Table stickyHeader aria-label="sticky table">
                    <TableHead >    
                        <TableRow>            
                            <CelTitl index={1} titulo={<Tooltip title="Asignar Votos">
                                 <IconButton  onClick={abrirModEvidencia} style={auxEstil}>
                                 
                                     <AddCircleOutlineIcon style={{color:"white"}}/>
                                    
                                    </IconButton>
                                </Tooltip>} aline="center" />
                            <CelTitl index={2} titulo={"Fecha"} aline="center" />                                
                            <CelTitl index={3} titulo={"Evidencia"} aline="center" />                                                            
                            <CelTitl index={4} titulo={"Casilla"} aline="center" />             
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow>
                            {celda("center", {}, "Sin",1)}
                            {celda("center", {}, "Resultados",2)}                            
                            {celda("center", {}, "Disponibles",3)}
                            {celda("center", {}, "",4)}                                                                                                                
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
            </Card>
        </Box>)
    }

    const mostrarModal = listaDatos.length!==0 ? listarResultados() : sinResultados()
    const mostrarCarga = espera ?  (<Box><LinearProgress/> </Box>) :( mostrarModal);


    return (
        <ModalInformativo setModalAbierto={setModalAbierto}
            modalAbierto={modalAbierto}
            titulo={"Evidencia Sección " + seleccionado.seccNom} tamanio='md' 
        >
           {mostrarCarga}
        </ModalInformativo>
    )
}