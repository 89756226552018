import React from 'react';
import {
  Card, Dialog, DialogActions, DialogContent,Box
  , DialogTitle, Paper, Typography
} from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import Alert from '@material-ui/lab/Alert';
import CloseIcon from '@material-ui/icons/Close';
import { grey } from '@material-ui/core/colors';
const useStyles = makeStyles((theme) => ({
  root: {
    alignItems: 'center',
    margin: 0,
  },
  fondoGris:{color: grey[500]}
}));

export default function ModalInformativo({ titulo, subtitulo, children, modalAbierto, setModalAbierto,
  aviso, setAviso, tipoAdvertencia, mensaje, tamanio, colorFondo, nvoTitulo }) {

  const [open, setOpen] = React.useState(false);
  const [scroll, setScroll] = React.useState('paper');
  const [colorTema,setColorTema] = React.useState(grey[100]);
  React.useLayoutEffect(()=>{
    const auxColor= localStorage.getItem('ColorTheme');
    if ( auxColor===undefined || auxColor===null) 
    {
      localStorage.setItem('ColorTheme',true);
      setColorTema(grey[100])    
    }
    else
    {  
      if (JSON.parse(auxColor)) {
        setColorTema(grey[700])      
      }else{
        setColorTema(grey[100])  
      }
      
    }
  },[])

  const cerrarBorrar = () => {
    setOpen(false);
    // setAviso(false);
    setModalAbierto(false)
  };
  const handleClose = () => {
    setOpen(false);
    setModalAbierto(false)
  };
  const handleCloseSN = () => {
    setAviso(false);
    //setModalAbierto(false)
  };

  const classes = useStyles();
  //const [espera,setEspera]=React.useState(false);
  const styles = (theme) => ({

    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      //color: theme.palette.grey[500],
      color: "white",
    },
  });

  const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
      <MuiDialogTitle disableTypography className={classes.root} {...other}>
        <Typography variant="h6">{children}</Typography>
        {onClose ? (
          <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
            <CloseIcon />
          </IconButton>
        ) : null}
      </MuiDialogTitle>
    );
  });
  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (modalAbierto) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [modalAbierto]);

  function tipoEdoProspecto(auxColorStd, etiqueta) {
    let renglon = (

      <Typography style={{ color: 'white', fontWeight: 600 }} variant='h6'
      >
        {etiqueta}
      </Typography>
    )
    return renglon
  }

  return (
    <div>
      <Dialog
        open={modalAbierto} onClose={handleClose}
        scroll={scroll} aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        maxWidth={tamanio} fullWidth={tamanio === "md" ? true : false}
      >

        <DialogTitle style={{ backgroundColor: nvoTitulo.StatusColor === '#FBBE06' ? process.env.REACT_APP_Fondo_Color : nvoTitulo.StatusColor === '#19A11E' ? '#19A11E' : '#E91E63', color: 'white', fontWeight: 600 }}
          id="scroll-dialog-title"
          align="center" onClose={handleClose}
        >
          {
            nvoTitulo.StatusId !== 1 || tamanio === "md" ?
              tipoEdoProspecto(nvoTitulo.StatusColor, nvoTitulo.StatusNom)
              : titulo
          }

          {
            subtitulo && subtitulo.length !== 0 ?
              <Typography color="textPrimary" style={{ fontWeight: 600, fontSize: 16, marginBottom: 0, paddingBottom: 0 }}>
                {subtitulo}
              </Typography>
              :
              null
          }
        </DialogTitle>
      
        <DialogContent dividers={scroll === 'paper'} style={{backgroundColor:colorTema}}>
        
          <div>
            {aviso && tipoAdvertencia && mensaje.length !== 0 ?
              <Alert severity={tipoAdvertencia} onClose={handleCloseSN}>{mensaje}</Alert>
              : <span></span>
            }
            {children}
          </div>
         
        </DialogContent>
      
        {/*   <DialogActions>
        <Button onClick={cerrarBorrar}  color="secondary" variant="outlined">
          Cerrar
        </Button>      
      </DialogActions> */}

      </Dialog>
    </div >
  );
}