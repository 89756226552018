import React,{useState,useEffect} from 'react';
import {Box,LinearProgress,//IconButton,Typography
    IconButton,Tooltip,FormControl,InputLabel,Select,
    MenuItem,TextField,FormControlLabel,Checkbox} from "@material-ui/core";
import DateFnsUtils from '@date-io/date-fns';
import esLocale from "date-fns/locale/es";
import {DatePicker, MuiPickersUtilsProvider} from '@material-ui/pickers';
import SearchIcon from '@material-ui/icons/Search';
import { authUser } from '../funciones/AuthUser'
import { useHistory } from "react-router-dom";
import {AuthContext} from '../context/AuthContext'
import {GeneralesContext} from '../context/GeneralesContext'
import axios from 'axios';
import qs from 'qs';
import moment from 'moment';
import TablaEstruct from './TablaEstructura';
import {llamaApiCargaToken,llamadaApiToken,ErrorGeneral } from '../funciones/ClienteAxios';
import ModalNvoEdit from './ModalNvo';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import CardTotal from './CardTotal';

export default function Estructura(){
 
    const history = useHistory();
    const source = axios.CancelToken.source();
    const auxId =localStorage.getItem('UsuId') ;  
    const nvl= localStorage.getItem('Tipo');      
    const Dispvo =localStorage.getItem('Dispvo') ;    
    const auxDispV =Dispvo && Dispvo.length >= 12 ? Dispvo:'w8rf51v21dsd2cs'      
    const {usuario,guardarUsuario}=React.useContext(AuthContext);
    const {generales,guardarGenerales}=React.useContext(GeneralesContext);     
    const [listMuni,setListMuni] = useState([]);            
    const [fSelect, setFSelect] = useState({inicio:new Date(),final:new Date()});
    const[actualizar,setActualizar]=useState(true);
    const[espera,setEspera]=useState(false);
    const[afiliado,setAfiliado]=useState({muni:0, secc:0,fecha:false,cadena:""});
    const[listAfil,setListAfil]=useState([])
    const [munId,setMunId] = useState(null)
    const [seccId,setSeccId] = useState("")
    const [munSelect,setMunSelect] = useState([])
    const[listSecc,setListSecc]=useState([]);
    const[listAllSecc,setListALSecc]=useState([]);
    const[listPart,setListPart]=useState([]);
    const[listTipo,setListTipo]=useState([]);    
    const[listCasilla,setListCasilla]=useState([]);    
    const [modalNvo, setModalNvo] = useState(false);    
    const [listTotl, setListTotl] = useState([])  
    const{muni, secc, fecha, cadena}=afiliado
    const {inicio,final}=fSelect  
    //const drawerWidth = 125;
    useEffect(()=>{             
        const autenticado = authUser();
        if(!autenticado){
            localStorage.clear();
            history.push("/");
            guardarUsuario([])
        }
        else
        {   const usu= localStorage.getItem('UsuarioNom');                 
            if (usuario.length===0) 
            {
                guardarUsuario({Nombre:usu,nivel:nvl});      
                                                     
            }      
            
            if (listMuni.length===0) 
            {   llamadaListMuni()  
            }
            if (listAllSecc.length===0) 
            {   llamadaListAllSecc()        
            }
            if (listPart.length===0) 
            {   llamadaListPartido()  
            }
           /* if (listTipo.length===0) 
            {   llamadaListTipo()  
            }
             if (listCasilla.length===0) 
            {   llamadaListCasilla()  
             }*/  
            guardarGenerales({...generales,Titulo:'Observadores'})      
            llenaTotales()    
        }
        
        return ()=> {
            source.cancel();
        }      
    //la siguiente linea puede generar errores o no en el useEffect
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    const llenaTotales = () => {
        const data = qs.stringify({
            Usuario: auxId,           
            idDispositivo: 'w8rf51v21dsd2cs',
        });
        const url = "estructura-avance";

        function respuesta(response) {
            if (response[0].Id != -1) {
                setListTotl(response)
            }
        }
    
        llamadaApiToken(data, url, respuesta, ErrorGeneral, history, source);
    };

    const getDatos = () => {  
        const auxF1 =moment.utc(fSelect.inicio).format('YYYY-MM-DD')+"T00:00:00"           
        const auxF2 =moment.utc(fSelect.final).format('YYYY-MM-DD')+"T00:00:00"           
        const data = qs.stringify({
            Usuario: auxId,                     
            Muni:munId?munId:0,
            Secc:seccId?seccId:0,
            Fecha:fecha?1:0,
            FIni:auxF1,
            FFin:auxF2,
            Cadena:cadena,
            idDispositivo: auxDispV,
        });
        const url = "estructura-list";
        function respuesta(aux) {
            if (aux[0].Id!== -1)
            {   setListAfil(aux)
            }
            else{
                setListAfil([])
            }
        }
        llamaApiCargaToken(data, url, respuesta,ErrorGeneral,setEspera,history,source);
    };

    function llamadaListMuni()  {            
        const auxU= `lugar-municipio`;                            
        const dataInfo = qs.stringify({                
            usuario:auxId,              
            estado:15,                
            idDispositivo:auxDispV,
            });
                 
        function respuesta (aux) 
        {   if (aux[0].Id !== -1) 
            {                                                    
                setListMuni(aux)
            }                                                            
        }
        llamaApiCargaToken(dataInfo, auxU, respuesta, ErrorGeneral, setEspera, history, source);
    };

    function llamadaListSecc(auxMuni)  {            
        const url= `lugar-seccion`;                            
        const data = qs.stringify({                
            usuario:auxId,              
            municipio:auxMuni,                
            idDispositivo:auxDispV,
        });
                 
        function respuesta (aux) 
        {   if (aux[0].Id !== -1) 
            {                                                    
                setListSecc(aux)
            }                                                            
        }
        llamaApiCargaToken(data, url, respuesta, ErrorGeneral, setEspera, history, source);
    };
    
    function llamadaListAllSecc()  {            
        const auxU= `lugar-seccion-list`;                            
        const dataInfo = qs.stringify({                
            usuario:auxId,                          
            idDispositivo:auxDispV,
            });
                 
        function respuesta (aux) 
        {   if (aux[0].Id !== -1) 
            {                                                    
                setListALSecc(aux)
            }                                                            
        }
        llamadaApiToken(dataInfo, auxU, respuesta, ErrorGeneral,  history, source);
    };

    function llamadaListPartido()  {            
        const auxU= `partido-base-list`;                            
        const dataInfo = qs.stringify({                
            usuario:auxId,                          
            idDispositivo:auxDispV,
            });
                 
        function respuesta (aux) 
        {   if (aux[0].Id !== -1) 
            {                                                    
                setListPart(aux)
            }                                                            
        }
        llamadaApiToken(dataInfo, auxU, respuesta, ErrorGeneral,  history, source);
    };

/*     function llamadaListTipo()  {            
        const auxU= `estructura-casilla-tipo-list`;                            
        const dataInfo = qs.stringify({                                                
            idDispositivo:auxDispV,
        });
                 
        function respuesta (aux) 
        {   if (aux[0].Id !== -1) 
            {                                                    
                setListTipo(aux)
            }                                                            
        }
        llamadaApiToken(dataInfo, auxU, respuesta, ErrorGeneral, history, source);
    };

    function llamadaListCasilla()  {            
        const auxU= `estructura-casilla-list`;                            
        const dataInfo = qs.stringify({                                                
            idDispositivo:auxDispV,
        });
                 
        function respuesta (aux) 
        {   if (aux[0].Id !== -1) 
            {                                                    
                setListCasilla(aux)
            }                                                            
        }
        llamadaApiToken(dataInfo, auxU, respuesta, ErrorGeneral,  history, source);
    }; */

    const generarLista = e =>{   
        e.preventDefault()    
        setActualizar(!actualizar)       
    }

    const guardarInicio = auxfecha =>
    {
        setFSelect({...fSelect, inicio : auxfecha })
    };

    const guardarFinal = auxfecha =>
    {
        setFSelect({ ...fSelect, final : auxfecha })
    };

    const onChangeMun=(e)=>{   
        setListAfil([]) 
        if (e!== null && e!== undefined) 
        {   let aux=listMuni.filter((filtro)=>filtro.Id===parseInt(e.target.value) )            
            setMunSelect(aux[0])
            llamadaListSecc(e.target.value)
            setMunId( e.target.value )                            
        }
        else
        {   setMunId(null)           
        }   
        setSeccId(null)     
    }  

    const onChangeSecc=(e)=>{    
        if (e!== null && e!== undefined) 
        {  // let aux=listSecc.filter((filtro)=>filtro.Id===parseInt(e.target.value) )                    
            setSeccId( e.target.value )                            
        }
        else
        {   setSeccId(null)           
        }        
    }  

    const handleChange = () => {
        setAfiliado({...afiliado,fecha:!fecha} )
    };

    const listaMun= listMuni.length!==0 ?                    
    <FormControl size='small'  style={{ minWidth:8.5+'rem',marginTop:".3rem"}} >
        <InputLabel size='small' variant="outlined" id="muni-label">
            Municipio
        </InputLabel>
        <Select  size='small' variant="outlined" 
            label="Municipio" id="muni-select2" name="munId"
            value={munId} onChange={onChangeMun}
        >
            {listMuni.map(( edo ) => {                        
                return(
                <MenuItem size='small' value={edo.Id} key={edo.Id}>
                    <em>{edo.Nom }</em>
                </MenuItem>
                )
            } )}                    
        </Select>
    </FormControl>    
    :   null

    const listaSec= listSecc.length!==0 ?                    
    <FormControl size='small'  style={{ minWidth:8.5+'rem',marginLeft:".5rem",marginTop:".3rem"}} >
        <InputLabel size='small' variant="outlined" id="muni-label">
            Sección
        </InputLabel>
        <Select  size='small' variant="outlined" 
            label="Sección" id="secc-select2" name="seccId"
            value={seccId} onChange={onChangeSecc}
        >
            {listSecc.map(( secc ) => {                        
                return(
                <MenuItem size='small' value={secc.Id} key={secc.Id}>
                    <em>{secc.Numero }</em>
                </MenuItem>
                )
            } )}                    
        </Select>
    </FormControl>    
    :   null

    const onChange = e =>
    {         
        const expNopermitida = new RegExp('[._:$!%-({})/*|]');
        const expMenos = new RegExp('-');
        const expMas = new RegExp('[+]');     
        const expCadena = new RegExp('[A-Za-z0-9]');
        if ((expCadena.test(e.target.value) && !expNopermitida.test(e.target.value) && !expMenos.test(e.target.value) 
            && !expMas.test(e.target.value)) || e.target.value===' '||e.target.value==='') 
        {
            setAfiliado({
                ...afiliado, 
                [e.target.name] : e.target.value  
            })
        }
    };

    const Reloj =()=>{
    return<MuiPickersUtilsProvider locale={esLocale} utils={DateFnsUtils}>    
        <Box component="span"  >
        <Box  ml={1}  component="span" width={1/4}>                                                
            <DatePicker
                disableFuture format="dd/MM/yyyy"
                views={["year", "month", "date"]}
                label="Fecha Inicial" size="small"
                minDate="2021-12-31" maxDate={new Date()}
                value={inicio} onChange={guardarInicio}      
                style={{width:6.5+'rem'}} cancelLabel={"Cancelar"}                  
                okLabel="Seleccionar" disabled={!fecha}
            />
        </Box>    
        <Box ml={1}  component="span" width="25%">
            <DatePicker
                disableFuture id="final" disabled={!fecha}
                format="dd/MM/yyyy" label="Fecha Final"
                views={["year", "month", "date"]}
                minDate={inicio} maxDate={new Date()}
                value={final} name="final" cancelLabel={"Cancelar"}  
                size="small" style={{width:6.5+'rem'}}      
                okLabel="Seleccionar"  onChange={guardarFinal}
                minDateMessage={"La fecha final debe ser menor a Fecha inicial"}
            />
        </Box> 
    </Box>                         
    </MuiPickersUtilsProvider>
    }
    const auxmodNvo = modalNvo ? 
    <ModalNvoEdit modalAbierto={modalNvo} setModalAbierto={setModalNvo} getDatos={getDatos}
        titulo="Nuevo Registro" listMun={listMuni} listSecc={listAllSecc}   
        listPart={listPart}   listTipo={listTipo} listCasilla={listCasilla}                      
    />:null

    const contenido=()=>{
        return(
        <Box display={'flex'} flexDirection="column" >
            <Box>
                {listTotl.length !== 0  ?
                    <CardTotal valor={listTotl} munId={munId} />
                :null}
            </Box>   
            <Box mb={1}>    
            {auxmodNvo}             
                <IconButton color="primary"  aria-label="add circle" 
                    component="span" onClick={()=>getDatos()}>
                    <Tooltip title="Buscar">
                        <SearchIcon/>
                    </Tooltip>
                </IconButton>  
                <IconButton color="primary"  aria-label="add circle" 
                    component="span" onClick={()=>setModalNvo(true)}>
                    <Tooltip title="Agregar Registro">
                        <AddCircleOutlineIcon/>
                    </Tooltip>
                </IconButton>  
                <TextField  size='small' variant='outlined'
                    name="cadena" label="Nombre"
                    value={cadena} onChange={onChange}
                    style={{marginTop:".3rem", marginRight:".5rem"}}
                />	     
                {listaMun}   
                {listaSec} 
               
                <FormControlLabel  style={{marginLeft:".5rem"}}
                    disabled={espera} label="Fecha" size={'small'}
                    control={<Checkbox color="primary" checked={fecha} name={"fecha"}/>}                
                    labelPlacement="end"  onChange={handleChange}
                />
                {Reloj()}
            </Box>      
                
            <Box>
                <TablaEstruct muni={listAfil}   listMuni={listMuni} listAllSecc={listAllSecc} 
                    listPart={listPart} listTipo={listTipo} listCasilla={listCasilla}
                    getDatos={getDatos} 
                />
            </Box>
              
        </Box>)
    }

    return (
    <Box display="flex" flexDirection="row" style={{ marginLeft:'1rem'}} >        
        {listMuni.length!==0 ? 
            (contenido) 
        :  (<Box pt={1} sx={{width:'100%'}}><LinearProgress/> </Box>)
        }              
    </Box>
    )
}
 