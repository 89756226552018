import React,{useState,useEffect} from 'react';
import {Box,TextField, LinearProgress,Select,FormControl,InputLabel,
    MenuItem
} from "@material-ui/core";
import TablaSugePublico from './TablaSugePublico';
import axios from "axios";
import qs from "qs";
import moment from 'moment';
import { useHistory } from "react-router-dom";
import IntervaloSelect from './IntervaloTabla';
import IconBuscar from '../generales/Iconos/IconBuscar';
import {llamadaApi,llamadaApiCarga,ErrorGeneral } from '../funciones/ClienteAxios';
import {GeneralesContext} from '../context/GeneralesContext'

const SugerenciaPublico = () => {
    const source = axios.CancelToken.source();
    //let token =localStorage.getItem('token20') ;  
    const {generales,guardarGenerales}=React.useContext(GeneralesContext);    
    let usuID = localStorage.getItem('UsuId');
    let Dispvo =localStorage.getItem('Dispvo') ;    
    let auxDispV =Dispvo && Dispvo.length >= 12 ? Dispvo:'w8rf51v21dsd2cs'      
    let history = useHistory();         
    const [listSuge, setListSuge] = useState([])
    const [listTipo, setListTipo] = useState([])
    const [listStatus, setListStatus] = useState([])
    const [fSelect, setFSelect] = useState({inicio:new Date(),final:new Date()});    
    const [statusSug, setStatusSug] = useState(0)
    const [tipoSug, setTipoSug] = useState(0)
    const [espera, setEspera] = useState(false)
    const [actualizar, setActualizar] = useState(false)
    useEffect(() => {                
        const llenaListStatus = () => {            
            let data = qs.stringify({
                usuario:usuID,                                
                idDispositivo: auxDispV,
            });
            let url = "sugerencia-publico-status-lista";
            llamadaApi(data, url, respuestaListStat,ErrorGeneral,history);
        };

        const llenaListTipo = () => {            
            let data = qs.stringify({
                usuario:usuID,                                
                idDispositivo: auxDispV,
            });
            let url = "sugerencia-publico-tipo";
            llamadaApi(data, url, respuestaListTipo,ErrorGeneral,history);
        };
        if (usuID !== null && usuID !== undefined && parseInt(usuID)===1) 
        {
            llenaListTipo()
            llenaListStatus()    
            guardarGenerales({...generales,Titulo:'Sugerencias'})   
        }
        else
        {   
            localStorage.clear();                            
            history.push("/");  
        }
        
        //llenaListSug()
    }, [])

    useEffect(() => {                        
        llenaListSug()
    }, [actualizar])

    
    const llenaListSug = () => {
        let fInicio=moment(fSelect.inicio).format("YYYY-MM-DD");
        let fFinal=moment(fSelect.final).format("YYYY-MM-DD");
        let data = qs.stringify({
            usuario:usuID,
            fini:fInicio+"T00:00:00",
            ffin:fFinal+"T23:59:59",
            status:statusSug.length!==0 ? statusSug:0,
            tipo:tipoSug,
            idDispositivo: auxDispV,
        });
        let url = "sugerencia-publico-lista-1-1";
        llamadaApiCarga(data, url, respuestaListSug,ErrorGeneral,setEspera,history);
    };

    const btnBuscar=(e)=>{
        e.preventDefault()
       // llenaListSug()
       setActualizar(!actualizar)
    }

    function respuestaListSug(auxiliar) {
        if (auxiliar[0].Id !== -1) {
          //  console.log(auxiliar);
            setListSuge(auxiliar);      
        }else{
            setListSuge([]);      
        }
    }

    function respuestaListStat(auxiliar) {
        if (auxiliar[0].Id !== -1 ) {
            //console.log(auxiliar);
            setListStatus(auxiliar);      
        }
    }

    function respuestaListTipo(auxiliar) {
        if (auxiliar[0].Id !== -1 ) {
            //console.log(auxiliar);
            setListTipo(auxiliar);      
        }
    }

    const onChangeStatus=(e)=>{            
        if (e!== null && e!== undefined) 
        { setStatusSug( e.target.value )                  
        }
        else
        { setStatusSug("")                  
        }        
    }  

    const onChangeTipo=(e)=>{            
        if (e!== null && e!== undefined) 
        { setTipoSug( e.target.value )                  
        }
        else
        { setTipoSug("")                  
        }        
    }

    return (
    <div style={{marginLeft:.5+'rem'}} >
        <Box display={'flex'} mt={2} mb={2}>
            <Box >
                <IconBuscar leyenda="Buscar" handleClick={(e)=>btnBuscar(e)} />                                                                                                                        
            </Box>    
            <Box>
                <IntervaloSelect fSelect={fSelect}  setFSelect={setFSelect} />
            </Box>
            {listStatus.length!==0 ?                
            <Box display={"flex"} >
            <FormControl size='small'  style={{marginLeft:1.6+'rem',marginTop:.4+'rem', minWidth:7.5+'rem'}} >
                <InputLabel size='small' variant="outlined" id="estatus-label-nvo">Estatus</InputLabel>
                <Select  size='small' variant="outlined"
                    label="Estatus" id="statusSug-select2" name="statusSug"
                    value={statusSug} onChange={onChangeStatus}
                >
                    {listStatus.map((secc, index) => {
                         
                        return(
                        secc.Id ===0 ?  
                            <MenuItem size='small' value={secc.Id} key={secc.Id}>
                                <em>Todos</em>
                            </MenuItem>
                            :<MenuItem size='small' value={secc.Id} key={secc.Id}>
                                <em>{secc.Nom }</em>
                            </MenuItem>
                        )
                    } )}                    
                </Select>
                </FormControl>
                </Box>
            :   null
            }
            {listTipo.length!==0 ?                
            <Box display={"flex"} >
            <FormControl size='small'  style={{marginLeft:1.6+'rem',marginTop:.4+'rem', width:8.5+'rem'}} >
                <InputLabel size='small' variant="outlined" id="tipoSug-label-nvo">Categoría</InputLabel>
                <Select  size='small' variant="outlined"
                    label="Categoría" id="tipoSug-select2" name="tipoSug"
                    value={tipoSug} onChange={onChangeTipo}
                >
                    {listTipo.map((secc, index) => {
                        return(
                            secc.Id ===0 ?                        
                            <MenuItem size='small' value={secc.Id} key={secc.Id}>
                                <em>Todos</em>
                            </MenuItem>                         
                            :
                            <MenuItem size='small' value={secc.Id} key={secc.Id}>
                                <em>{secc.Nom }</em>
                            </MenuItem>                         
                        )
                    } )}                    
                </Select>
                </FormControl>
                </Box>
            :   null
            }
        </Box>
        { !espera  ? <TablaSugePublico listSuge={listSuge} listTipo={listTipo} listStatus={listStatus} actualizar={actualizar} setActualizar={setActualizar}/>
            :    <Box pt={3}><LinearProgress/> </Box>
        }
        
    </div>
    );
};

export default SugerenciaPublico;