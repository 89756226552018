import React, {useState, useEffect} from 'react';
import {Box,Select,InputLabel,MenuItem, LinearProgress, Typography,Paper} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from "react-router-dom";
import axios from 'axios';
import qs from 'qs';
import ModalInformativo from '../generales/ModalInformativo';
import {llamaApiCargaToken,ErrorGeneral } from '../funciones/ClienteAxios';
import Viewer from 'react-viewer';

const useStyles = makeStyles((theme) => ({
  imageSize: {
    //width: '50%', 
    height: '20vh'
}, container: {
    overflowY: 'auto', maxHeight: window.innerHeight - 240, marginTop: '.5rem'
}, content: {
    padding: '0!important', margin: 0
}
  }));

export default function ModalFoto({titulo,modalAbierto,setModalAbierto,seleccionado}){
  const classes = useStyles();  
  const history = useHistory();
  const source = axios.CancelToken.source(); 
  const auxId =localStorage.getItem('UsuId') ;  
  const Dispvo =localStorage.getItem('Dispvo') ;    
  const auxDispV =Dispvo && Dispvo.length >= 12 ? Dispvo:'w8rf51v21dsd2cs'
  const [listFoto, setListFoto] = useState([]);  
  const [bandera, setBandera] = useState(false);  
  const[espera,setEspera]=useState(false);
  const [visible, setVisible] = useState(false);
  const [index, setIndex] = useState(0);
  useEffect(()=>{                 
    llamadaListFoto(seleccionado.Id)
    return ()=> {
      source.cancel();
  }
  },[seleccionado]);

  function llamadaListFoto(auxLlave)  {            
    const auxU= `estructura-evidencia-list`;                            
    const dataInfo = qs.stringify({                
        Usuario:auxId,             
        Llave:auxLlave,             
        idDispositivo:auxDispV,
        });
             
    function respuesta (aux) 
    { if (aux[0].Id !== -1) 
      {                                                    
        setListFoto(aux)
        setBandera(false)
      } 
      else
      { setBandera(true)
      }                                                           
    }
    llamaApiCargaToken(dataInfo, auxU, respuesta, ErrorGeneral, setEspera, history, source);
}; 

const handleViewer = (index) => {
  setVisible(true)
  setIndex(index)
}

const viewer = <Paper square>
                <Viewer
                  visible={visible}
                  onClose={() => { setVisible(false); }}
                  images={listFoto.map((element) => {
                      return ({src: element.Dir + element.Archivo, alt: element.Descr})
                  })}
                  activeIndex={index}
                  zIndex={2000}
                />
                </Paper>     
  return (
  <ModalInformativo
    setModalAbierto={setModalAbierto} modalAbierto={modalAbierto}
    titulo={titulo} tamanio={'sm'} maxTam={false} > 
    {viewer}
    <Box display="flex" justifyContent="center" >
    {
       listFoto.length!==0? listFoto.map((foto,index)=>
        <span style={{marginRight:"1rem"}}>
          <Typography>{foto.Tipo}</Typography>
          <img src={foto.Dir+foto.Mini} onClick={() => handleViewer(index)}
            style={{marginBottom:"1rem"}} 
            id={index} key={index}  className={classes.imageSize}/>
        </span>
      )
      : espera ? <LinearProgress/> :<Typography>Registro sin Evidencia</Typography>
    }
    </Box>
  </ModalInformativo>
  )
}