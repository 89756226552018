import React, {useState, useEffect} from 'react';
import {FormControl,Grid,Box} from '@material-ui/core'
import Select from 'react-select';
import { makeStyles } from '@material-ui/core/styles';
import { toast } from 'react-toastify';
import { useHistory } from "react-router-dom";
import axios from 'axios';
import qs from 'qs';
import Modal from '../generales/Modal';
import {llamadaApiToken,llamaApiCargaToken,ErrorGeneral } from '../funciones/ClienteAxios';

const useStyles = makeStyles((theme) => ({
    root: {
      margin: {
        margin: theme.spacing(1),
      },
    },
    formEncabezado:{
      marginTop:'1.7em',  
    },
    formAnotacion: {
      width:'28.5em'
    },
    formSelect: {
      width:'12em'
    },
    formSelectTipo: {
        marginTop:'1em',
        width:'24.5em'
    },
    formObs:{
        marginTop:'1.7em',
        width:'30em',
        height:'6em'
    },
  }));


export default function NuevaIncidencia({titulo,modalAbierto,setModalAbierto}){
    const classes = useStyles(); 
    const history = useHistory();
    const source = axios.CancelToken.source(); 
    const auxId =localStorage.getItem('UsuId') ; 
    const Dispvo =localStorage.getItem('Dispvo') ;    
    const auxDispV =Dispvo && Dispvo.length >= 12 ? Dispvo:'w8rf51v21dsd2cs'      
    const [listaSeccion, setListSeccion] = useState([])
    const [lisSecComp, setLissSecComp] = useState([]);
    const [listaCasillas, setListaCasillas] = useState([])
    const [listaTipo, setListaTipo] = useState([])
    const [espera, setEspera] = useState(false)
    const [seccion,setSeccion] = useState(0);
    const [aviso, setAviso] = useState(false);
    const [mensaje, setMensaje] = useState("");
    const [tipoAdvertencia, setTipoAdvertencia] = useState("success");
    const [btnDeshabilitado,setBtnDeshabilitado]= useState(false)
    const [registro, setRegistro] = useState({  Tipo: 0, Casilla: 0, Descr: "", Nota: ""})
    const {Tipo,Casilla,Descr,Nota} = registro
    const exPermitidaAlfaNumerica = /^([A-Za-z0-9áéíóúÁÉÍÓÚñÑ]+)(?:[A-Za-z0-9 _.,ñÑáéíóúÁÉÍÓÚ]*)$/

    useEffect(()=>{                
        getSecciones()
        getTipoInci()
        return ()=> {
            source.cancel();
        }  
    },[]);

    const getSecciones = () => {            
        const data = qs.stringify({                
            idDispositivo: auxDispV,
        });
        const url = "incidencia-web-list-secc";
        function respuesta(aux) {
            if (aux)
            {   const auxSecciones=aux;     
                let arreSecc=[], variable=0;
                auxSecciones.forEach((seccion) => {
                    if (variable!==seccion.SeccionId)
                    {
                        variable=seccion.SeccionId;                        
                        arreSecc.push({ value : variable, label :variable })                                               
                    }                                        
                })
                setLissSecComp(aux);
                setListSeccion(arreSecc);
            }       
        }                        
        llamadaApiToken(data, url, respuesta,ErrorGeneral,history,source)                     
    }; 

     const getTipoInci = () => {            
        const data = qs.stringify({                
            idDispositivo: auxDispV,
        });
        const url = "incidencia-movil-tipo";
        function respuesta(aux) {
            if (aux)
            {
                let auxTipos=aux;                        
                let auxArreTipos=[];                                            
                auxTipos.forEach((tipo) =>
                {                                    
                    auxArreTipos.push({ value : tipo.Id, label : tipo.AreaNom+" - "+tipo.Nombre })                                                                                                   
                })
                setListaTipo(auxArreTipos);                             
            }      
        }                        
        llamadaApiToken(data, url, respuesta,ErrorGeneral,history,source)                     
    }; 

    function onChangeTipo(e){
        setRegistro({ ...registro, Tipo: e.value })
    }

    function onChangeSeccion(e){
        const dato= lisSecComp.filter(  (registro) => registro.SeccionId === e.value )                          
        let arreCasilla=[];                      
        dato.forEach((seccion) => 
        {            
            arreCasilla.push({ value : seccion.CasillaId, label : seccion.TipoNom })      
            //console.log(seccion.CasillaId+" "+seccion.TipoNom);                                                                                             
        })
        setSeccion(e.value)
        setListaCasillas(arreCasilla);
    }

    function onChangeCasilla(e){
        setRegistro({ ...registro, Casilla: e.value })
    }

    function onChangeDescripcion(e){
        if(exPermitidaAlfaNumerica.test(e.target.value)||e.target.value===' '||e.target.value==='')
        {   setRegistro({ ...registro, Descr: e.target.value })
        }
    }
    function onChangeNota(e){
        if(exPermitidaAlfaNumerica.test(e.target.value)||e.target.value===' '||e.target.value==='')
        {   setRegistro({ ...registro, Nota: e.target.value })
        }
    }

    function verificarTipo(tp){
        let validado=false;
        if(parseInt(tp) !== 0 )
        {   validado=true
        }
        else
        {   setTipoAdvertencia("warning")
            setMensaje("Seleccione un tipo válido")
            setAviso(true)
            validado=false;
        }
        return validado;
    }

    function verificarCasilla(cas){
        let validado=false;
        if(parseInt(cas) !== 0)
        {   validado=true
        }
        else
        {   setTipoAdvertencia("warning")
            setMensaje("Seleccione una casilla válida")
            setAviso(true)
            validado=false;
        }
        return validado;
    }

    function verificarDescripcion(des){
        let validado=false;
        if(des && des!==" ")
        {   validado=true
        }
        else
        {   setTipoAdvertencia("warning")
            setMensaje("Ingrese Descripción")
            setAviso(true)
            validado=false;
        }
        return validado;
    }

    function handleSubmition()
    {
        if( verificarTipo(Tipo) && verificarCasilla(Casilla) && verificarDescripcion(Descr))
        {   registrarIncidencia()
        }
    }

    const registrarIncidencia = () => {  
        setBtnDeshabilitado(true);              
        const data = qs.stringify({
            Tipo:Tipo,   
            Usuario:auxId,
            Casilla:Tipo,
            Descripcion: Descr,
            Nota:Nota,
            Otracausa:(Tipo===5 || Tipo===7 ?(Descr) :(" ")),
            idDispositivo: auxDispV,
        });
        const url = "incidencia-web-nuevo";
        function respuesta(aux) {
            if(aux.respuesta===1)
            {   setTipoAdvertencia("success")
                setMensaje("Incidencia registrada con éxito")
                toast.success("Incidencia registrada con éxito", 
                    {  position: toast.POSITION.BOTTOM_RIGHT,  theme: "colored", autoClose: 3500, 
                    containerId:'ModalNvaIncidencia'} );   
                setAviso(true)
                setSeccion(0)
                setRegistro({...registro, Casilla:0, Descr:"", Nota:"", Tipo:0})
            }  
            else
            {   setBtnDeshabilitado(false)
            }
        }
        llamaApiCargaToken(data, url, respuesta,ErrorGeneral,setEspera,history,source);
    };

    return (
    <Modal titulo={titulo} modalAbierto={modalAbierto}
        setModalAbierto={setModalAbierto} guardarDatos={handleSubmition}
        mensaje={mensaje}  tipoAdvertencia={tipoAdvertencia}
        aviso={aviso} btnDeshabilitado={btnDeshabilitado}
        setAviso={setAviso} tamanio={'md'} cargando={espera}
    >
        <form className={classes.root} noValidate autoComplete="off"> 
            <Grid container spacing={1}>
                {listaSeccion.length !== 0 ? 
                <Grid item sx={6}>
                    <FormControl className={classes.formSelect}>
                        <Select placeholder={"Sección"}  options={listaSeccion}
                            onChange={opt =>  onChangeSeccion(opt) }
                            styles={
                                {   menuList: styles => ({ ...styles,height:'250px' }),
                                }
                            }
                            noOptionsMessage={ () =>  ('Sin Resultados') }/>   
                    </FormControl>
                </Grid> 
                :null
                }
                {seccion>0 && listaCasillas.length!==0 ? 
                <Grid item sx={1}>
                    <FormControl className={classes.formSelect}>
                        <Select placeholder={"Casilla"}  options={listaCasillas}
                            onChange={opt =>  onChangeCasilla(opt) }
                            styles={
                                {   menuList: styles => ({ ...styles,height:'100px' }),
                                }
                            }
                            noOptionsMessage={ () =>  ('Sin Resultados') }/>   
                    </FormControl>
                </Grid> 
                :null
                }
            </Grid>
            <Grid container spacing={1}>
                {listaTipo.length!==0 ? 
                <Grid item sx={6}>
                    <FormControl className={classes.formSelectTipo}>
                        <Select placeholder={"Tipo de Incidencia"}  options={listaTipo}
                            onChange={opt =>  onChangeTipo(opt) }
                            styles={
                                {   menuList: styles => ({ ...styles,height:'150px' }),
                                }
                            }
                            noOptionsMessage={ () =>  ('Sin Resultados') }/>   
                    </FormControl>
                </Grid> :null
                }
            </Grid>
            <Box display="flex" flexDirection="column">
                <textarea className={classes.formObs} placeholder="Descripción"
                    value={Descr} onChange={onChangeDescripcion} id={'Descr'}
                    multiline rows={2} maxRows={4}/>
                <textarea className={classes.formObs} placeholder="Nota"
                    value={Nota} onChange={onChangeNota} id={'Nota'}
                    multiline rows={2} maxRows={4}/>
            </Box>
        </form>
    </Modal>
    )
}