import React,{useState,useEffect} from 'react';
import {Box, LinearProgress,TextField } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {authUser} from "../funciones/AuthUser";
//import {AuthContext} from '../context/AuthContext'
import { useHistory } from "react-router-dom";
import {AuthContext} from '../context/AuthContext'
import {GeneralesContext} from '../context/GeneralesContext'
import PanelVoto from './PrepPanelVoto';
import axios from 'axios';
import qs from 'qs';
import {llamaApiCargaToken,ErrorGeneral } from '../funciones/ClienteAxios';
  const listaProceso=[
    //{ value:3, label:'2022'}, 
    { value:2, label:'2023'}, 
    { value:1, label:'2018'},
]

export default function Prep(){
  let history = useHistory();
  const source = axios.CancelToken.source();
  const usu = localStorage.getItem('UsuarioNom');            
  const nvl=  localStorage.getItem('Tipo')?parseInt(localStorage.getItem('Tipo')):0;        
  const {usuario,guardarUsuario}=React.useContext(AuthContext);
  const {generales,guardarGenerales}=React.useContext(GeneralesContext); 
  const [listaRes,setListaRes] = useState([]);
  const [procesoSelec,setProcesoSelec] = useState(0)
  const [espera,setEspera] = useState(false);
  const [actualizar,setActualizar] = useState(0); 
  const Dispvo =localStorage.getItem('Dispvo') ;    
  const auxDispV =Dispvo && Dispvo.length >= 12 ? Dispvo:'w8rf51v21dsd2cs'      
  useEffect(()=>{        
            
    const autenticado = authUser();
    if(!autenticado){
      localStorage.clear();
      history.push('/');
      guardarUsuario([]);  
    }
    else
    {
      if (usuario.length===0) 
      {
        guardarUsuario({Nombre:usu,nivel:nvl});                                         
      
      }  
      guardarGenerales({...generales, Titulo: 'Votación'})   
      getLista();
    }
    
  },[actualizar,procesoSelec])

  const getLista = () => {  
    const data = qs.stringify({
        periodo:procesoSelec===0 ? 2 :procesoSelec,
        idDispositivo: auxDispV,
    });
    const url = "votacion-total";
    function respuesta(aux) {
      if (aux) 
      { //  console.log(aux);
        setListaRes(aux);    
      }else{
        setListaRes([])
      }    
    }
    llamaApiCargaToken(data, url, respuesta,ErrorGeneral,setEspera,history,source);
  };

  const handleChange =(event) =>{
    if(event){
      setProcesoSelec(event.value)
    }else{
      setProcesoSelec(0)
    }
  }

  function generarSelect(){
    return(
    <Autocomplete
      id="proceso" size='small'
      onChange={(e,option)=>handleChange(option)}
      options={listaProceso}
      getOptionLabel={(option) => option.label}
      style={{width: 13+'rem' }}
      noOptionsText={"Sin coincidencias"}
      renderInput={(params) => (
        <TextField {...params} 
        label="Proceso" variant="outlined" />
    )}/>
    )
  }
  const contenido = listaRes && procesoSelec>0? <PanelVoto datos={listaRes} setActualizar={setActualizar} actualizar={actualizar} procSel={procesoSelec}/> : null;
  const valCont = espera ? <Box sx={{width:'600px'}} style={{marginTop:'1em'}}><LinearProgress/></Box> : contenido;
  return( 
  <Box style={{marginLeft:'2em',marginTop:'1.5rem'}} display="flex" flexDirection="column">
    {generarSelect()}
    {valCont}
  </Box>)
  }