import {useEffect, useState,useContext} from "react";
import {useHistory} from "react-router-dom";
import SearchIcon from "@material-ui/icons/Search";
import {makeStyles} from "@material-ui/core/styles";
import { Box, Button, Card, CardContent, Grid,
    IconButton, LinearProgress, Paper, Tooltip,
    Typography, } from "@material-ui/core";
import TablaProspecto from "./TablaProspecto";
import Relojes from "./Relojes";
import axios from "axios";
import qs from "qs";
import {GeneralesContext} from '../context/GeneralesContext'
import { AuthContext } from "../context/AuthContext";
import {ErrorGeneral, llamaApiCargaToken,} from "../funciones/ClienteAxios";
import Galeria from "./Galeria";
import {AddCircleOutlined} from "@material-ui/icons";
import ModalNvoProspecto from "./ModalNvoProspecto";
import ModalAgregarEvidencia from "./ModalAgregarEvidencia";
import { authUser } from '../funciones/AuthUser'
const useStyles = makeStyles((theme) => ({}));

const RegistroPrevio = () => {
    const classes = useStyles();
    let idusu = localStorage.getItem("UsuId");
    let history = useHistory();
    const source = axios.CancelToken.source();
    const {generales,guardarGenerales}=useContext(GeneralesContext); 
    const {usuario,guardarUsuario}=useContext(AuthContext);
    const [fechasSeleccionadas, setFechasSeleccionadas] = useState({
        inicio: new Date(), final: new Date(), });

    const [selectedID, setSelectedID] = useState(null);    
    const [prospecto, setProspecto] = useState([]);
    const [galeria, setGaleria] = useState([]);
    const [latitud, setLatitud] = useState(0);
    const [longitud, setLongitud] = useState(0);
    const [cargando, setCargando] = useState(false);
    const [abrirModalMapa, setAbrirModalMapa] = useState(false);
    const [abrirModalAgregar, setAbrirModalAgregar] = useState(false);
    const [mensaje, setMensaje] = useState("");
    const [buscar, setBuscar] = useState(true);
    const [checked, setChecked] = useState(false);
    const [abrirModal, setAbrirModal] = useState(false);
    const [update, setUpdate] = useState(false);
    useEffect(() => {
        const usu = localStorage.getItem('UsuarioNom');                
        const usuID = localStorage.getItem('UsuId');   
        const nvl= localStorage.getItem('Tipo');    
        const veriSesion =  () => {            
            let auxValidacion=authUser()
            if(!auxValidacion ||(usu===undefined||usu===''||usu===null)||(nvl===undefined||nvl===''||nvl===null)
                ||(usuID===undefined||usuID===''||usuID===null) )
            {   guardarUsuario([])				
                guardarGenerales([])    
                localStorage.clear();            	             
                history.push("/")	                	
            }
            else
            {   
                if (usuario.length==0) 
                {
                    guardarUsuario({Nombre:usu,nivel:nvl});                                         
                    
                }                                                       
                guardarGenerales({...generales,Titulo:'Prospecto'})  
                if ("geolocation" in navigator) 
                {   navigator.geolocation.getCurrentPosition(function (position) {
                        setLatitud(position.coords.latitude);
                        setLongitud(position.coords.longitude);
                    });
                } 
                else 
                {   setLatitud(0);
                    setLongitud(0);
                }                                                     
            }   
        }
        veriSesion()
    }, []);


    useEffect(() => {
        setGaleria([]);
        if (prospecto !== null) 
        {
            let arr=[]
            //arr.push({src:prospecto.Img1Mini,Descr:"lado 1" })
            //arr.push({src:prospecto.Img2Mini,Descr:"lado 2" })
            const consultarImagenes = () => {
                let data = qs.stringify({
                    Llave: prospecto.Id,
                    Usuario: idusu,
                    idDispositivo: "987gyubjhkkjh",
                });
                let url = "prospecto-file-list";
    
                function respuesta(aux) {
                    if (aux[0].Dir===""&& (aux[0].Arch1===""||aux[0].Arch2==="")) 
                    {
                        setGaleria([]);                             
                        setMensaje("Sin fotografías.");
                    } 
                    else 
                    {   arr.push({srcMini:aux[0].Dir+aux[0].Mini1
                        ,Descr:"lado 1",usrNom:aux[0].UsrNom,FReg:aux[0].FReg, 
                        archivo:aux[0].Dir+aux[0].Arch1
                        })
                        arr.push({srcMini:aux[0].Dir+aux[0].Mini2
                            ,Descr:"lado 2",usrNom:aux[0].UsrNom,FReg:aux[0].FReg, 
                            archivo:aux[0].Dir+aux[0].Arch2
                        })
                        setGaleria(arr);
                    }
                }
    
                llamaApiCargaToken(data, url, respuesta, ErrorGeneral, setCargando, history, source);
            }; 
            consultarImagenes()                                                 
        }
    }, [selectedID,update]);

    const buscarDatos = () => {
        setBuscar(!buscar)
    }

    const modalAgregarEvidencia = abrirModal ? <ModalAgregarEvidencia abrirModal={abrirModal}
                                                setAbrirModal={setAbrirModal} prospecto={prospecto}
                                                selectedID={selectedID} idusu={idusu}  setUpdate={setUpdate}
                                            /> : null

    const modAgregarProspecto = abrirModalAgregar ? <ModalNvoProspecto abrirModal={abrirModalAgregar}
                                                        setAbrirModal={setAbrirModalAgregar}
                                                        idusu={idusu} history={history} buscarDatos={buscarDatos}
                                                        source={source} latitud={latitud}
                                                        longitud={longitud}
                                                    /> : null

    const agregarDatos = () => {
        setAbrirModalAgregar(true)
    }

    const listarGaleria =
        galeria.length !== 0 ? (
            <Galeria
                galeria={galeria}
            />
        ) : (
            <Paper className={classes.root}>
                <Typography variant={"h5"}>{mensaje}</Typography>
            </Paper>
        );

    const handleChange = () => {
        setChecked(!checked)
    };

    const handleAbrir = () => {
        setAbrirModal(true)
    }

    const principal = () => {
        return (
            <Grid container spacing={1}>
                {modAgregarProspecto}
                <Grid item xs={12}>
                    <Card>
                        <CardContent style={{padding:0.4+'rem'}} >
                            <Grid container spacing={1} alignItems={'center'}>
                            <Grid item>
                                    <Tooltip title={'Buscar'}>
                                        <IconButton
                                            color="primary"
                                            aria-label="Buscar"
                                            onClick={buscarDatos}
                                        >
                                            <SearchIcon/>
                                        </IconButton>
                                    </Tooltip>
                                </Grid>
                                <Grid item>
                                    <Relojes
                                        fechasSeleccionadas={fechasSeleccionadas}
                                        setFechasSeleccionadas={setFechasSeleccionadas}
                                    />
                                </Grid>
                                {/* <Grid item>
                                    <FormControlLabel
                                        value={checked}
                                        control={<Checkbox color="primary"/>}
                                        label="Buscar solo mis registros"
                                        labelPlacement="end"
                                        size={'small'}
                                        onChange={handleChange}
                                    />
                                </Grid> */}
                                 <Grid item>
                                    <Tooltip title={'Agregar Prospecto'}>
                                        <IconButton
                                            color="primary"
                                            aria-label="Agregar Prospecto"
                                            onClick={agregarDatos}
                                        >
                                            <AddCircleOutlined/>
                                        </IconButton>
                                    </Tooltip>
                                </Grid>
                                
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={8}>
                    <TablaProspecto
                        history={history} setProspecto={setProspecto}
                        fechasSeleccionadas={fechasSeleccionadas}
                        selectedID={selectedID} setSelectedID={setSelectedID}
                        source={source} idusu={idusu} buscar={buscar}
                        checked={checked} abrirModal={abrirModalMapa}
                        setAbrirModal={setAbrirModalMapa} buscarDatos={buscarDatos}
                    />
                </Grid>
                <Grid item xs={4}>
                    {modalAgregarEvidencia}
                    {selectedID !== null ? <Grid container>
                        <Grid item xs={12} style={{paddingBottom: 10}}>
                            <Button
                                variant="contained" color="primary"
                                startIcon={<AddCircleOutlined/>}
                                onClick={handleAbrir}
                            >
                                Subir Fotos
                            </Button>
                        </Grid>
                    </Grid> : null}
                    {cargando ? (
                        <Box pl={1} pt={5}>
                            <LinearProgress/>
                        </Box>
                    ) : (
                        listarGaleria
                    )}
                </Grid>
            </Grid>
        );
    };

    return <div>{principal()}</div>;
};

export default RegistroPrevio;
