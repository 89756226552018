import React,{useState} from 'react'
import {Box,IconButton,Table,TableBody,TableCell,LinearProgress,
      //,Button,Collapse,Paper,
      TableContainer,TableHead,TableRow,Card,Tooltip,TablePagination} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from "react-router-dom";
import axios from 'axios';
import qs from 'qs';
import XLSX from 'xlsx';
import moment from 'moment';
import ModalMapa from './ModalMapa'
import LocationOnIcon from '@material-ui/icons/LocationOn';
import InfoPromo from './ModInfoPublico';
import InfoIcon from '@material-ui/icons/Info';
import ModalNvoPerfilImg from '../PerfilPublico/ModalNvoPerfImg';
import ImageIcon from '@material-ui/icons/Image';
import SaveIcon from '@material-ui/icons/Save';
import { useRef } from 'react';
import { llamadaApi,ErrorGeneral ,llamadaApiCarga} from '../funciones/ClienteAxios';
import PaginacionTab from '../layout/PaginacionTab'
import { numeroPag } from "../funciones/Paginacion";
import {ordenMapBx,ordenIdColor} from "../MapaEdo/OrdenxMapBox"
const useStyles = makeStyles({
    root: {
      //width: '100%',
    },
    container: {
      //maxHeight: 550,
      maxHeight: window.innerHeight-252,
      minHeight: 280,
      maxWidth:'100%',
      minWidth:62+'rem',
    },
    container2: {
      maxHeight: 550,
      //maxHeight: window.innerHeight-170,
      //minHeight: 280,
    },
    tableRow: {
      "&$selected, &$selected:hover": {
        backgroundColor:process.env.REACT_APP_Color_Second
      }
    },
    tableCell: {
      "$selected &": {
        color: "white"
      }
    },
    hover: {},
    selected: {}
  });
  const columnas=[          
  /*   {
      id:2,
      titulo:"Reg",                                                
      alinear:'center'
    }, */     
    {
      id:3, titulo:"Registro", alinear:'center'
    }, 
    {
      id:13, titulo:"Dominio", alinear:'center'
    }, 
    {
      id:8,titulo:"Municipio", alinear:'center'
    },
    {
      id:7,titulo:"Sección", alinear:'center'
    },
    {
      id:6, titulo:"Teléfono",alinear:'center'
    }, 
   /*  {
      id:10, titulo:"Usuario",alinear:'center'
    }, */
    {
      id:11, titulo:"Nombre",alinear:'center'
    },
    {
      id:12, titulo:"Redes Sociales", alinear:'center'
    }, 
    {
      id:9, titulo:"Email", alinear:'center'
    },     
    {
      id:5, titulo:"Verificación",alinear:'center'
    },     
    {
      id:4, titulo:"Nacimiento",alinear:'center'
    },       
  ];

const TablaPerfilPublico = ({fSelect,actualizar,setActualizar,setArregloColor,setTotalPerfil,setInfoNvlCoord,listMun,setListMun,filtro}) => {
  const classes = useStyles();
  let history = useHistory();
  let Dispvo =localStorage.getItem('Dispvo') ;    
  let auxDispV =Dispvo.length >= 12 ? Dispvo:'w8rf51v21dsd2cs'
  //let auxArreglo=[]
           
  const [datos, guardarDatos] = React.useState([])  
  const[sinDatos,guardarSinDatos] = React.useState(true)
  const [espera,setEspera] = React.useState(false);
  const [selectedID, setSelectedID] = React.useState(null);  
  const [regisSelect, setRegisSelect] = React.useState([]);
  //const [modalNvoPerfil, setModalNvoPerfil] = React.useState(false);
  const [modalNvoImg, setModalNvoImg] = React.useState(false);
  //const[actualizar,setActualizar]=useState(true);  
  const [infoMapaMod, setInfoMapaMod] = useState(false);
  const [subtInfo, setSubtInfo] = useState("")
  //const [tituloNuevo, setTituloNuevo] = useState("")
  const [tituloMapa,setTituloMapa] = useState("")
  const [modalInfo, setModalInfo] = useState(false);
  //const [listMun,setListMun]=useState([])
  const [listSecc,setListSecc]=useState([])
  const [listPlantilla,setListPlantilla]=useState([])
  const [modalAvisoImg, setModalAvisoImg] = React.useState(false);
  const [modalAvisoImg2, setModalAvisoImg2] = React.useState(false);
  const [nvoRegisPerfil, setNvoRegisPerfil] = React.useState([]);
  const [listaExcel, setListaExcel] = React.useState([]);
  const [auxUsuID, setAuxUsuID] = React.useState(0);  
  const [totalPaginas,setTotalPaginas]=useState(0);
  const [numPagina,setNumPagina]=useState(0);
  const [listPagina,setListPagina]=useState([]);
  let tamMax=250
  let fondo=process.env.REACT_APP_Fondo_Color
  let colSecond=process.env.REACT_APP_Color_Second
  React.useEffect(()=>
  {
    const source = axios.CancelToken.source();    
    let fInicio=moment(fSelect.inicio).format("YYYY-MM-DD");
    let fFinal=moment(fSelect.final).format("YYYY-MM-DD");
    const usuID = localStorage.getItem('UsuId');
    let aux1=usuID!== null && parseInt(usuID) === 1 ? parseInt(usuID):0
    setAuxUsuID(aux1)
    let usuTipo =localStorage.getItem('Tipo') ;    

    const llamadaPlantilla=()=>{
      let data = qs.stringify({
        usuario:usuID,                                                
        idDispositivo: auxDispV,
      });
      let url = "plantilla-base";
      function respuestaPlantilla(auxiliar) {
        if (auxiliar[0].Id != -1) 
        { setListPlantilla(auxiliar)           
        }
      }
      llamadaApi(data, url, respuestaPlantilla,ErrorGeneral,history);
    }

    const llamadaListFiltro=()=>{
      let data = qs.stringify({
        usuario:usuID,    
        fini:fInicio+"T00:00:00",
        ffin:fFinal+"T23:59:59",
        status:filtro.estatus,
        municipio:filtro.municipio,
        seccion:filtro.seccion,                                            
        idDispositivo: auxDispV,
      });
      let url = "perfil-publico-lista-filtro";
      function respuestaFiltro(aux) {      
        if (aux[0].Id !== -1) 
        { console.log(aux);  
          guardarSinDatos(true)                                                           
                    
          let auxPendiente=0
          let auxVerificado=0
          let auxRechazado=0
          let auxArrMuni=[],auxPos=[]
          let arrM=[]
          arrM.push(["#","Municipio","Sección","Nombre","Teléfono","Redes Sociales","Email","Fecha Registro","Fecha Nacimiento","Foto"])
          aux.forEach((perfil,index )=> {
            if (perfil.StatusId===1) 
            { auxPendiente=auxPendiente+1
            }
            if (perfil.StatusId===2) 
            { auxVerificado=auxVerificado+1
            }
            if (perfil.StatusId===3) 
            { auxRechazado=auxRechazado+1
            }
            let idColor= ordenIdColor(perfil.MunId )
            let auxMuni=perfil.MunNom.charAt(0)+ perfil.MunNom.slice(1).toLowerCase()   
            let auxFReg=moment.utc( perfil.Fecha).format('YYYY-MM-DD HH:mm') === "1900-01-01 00:00"
                  ? "" : moment.utc( perfil.Fecha).format('DD/MM/YYYY HH:mm')
            let auxNac=moment.utc( perfil.Nacimiento).format('YYYY-MM-DD HH:mm') === "1900-01-01 00:00"
            ? "" : moment.utc( perfil.Nacimiento).format('DD/MM/YYYY') 
            arrM.push([index+1,auxMuni,perfil.SeccNum,perfil.Nombre,perfil.Tel,perfil.Social,perfil.Email,auxFReg,auxNac,perfil.Imagen])
            //auxArrMuni.push({Id:idColor,auxColor:colSecond,IdOriginal:perfil.MunId})
            let filtrado=auxPos.findIndex((elemt)=>elemt===idColor)
           // console.log(filtrado,idColor);
            if (filtrado ===-1) {
              ordenMapBx(auxArrMuni,idColor,colSecond) 
              auxPos.push(idColor)
            }
          });
          //auxTotal.push()
          setListaExcel(arrM)
          setTotalPerfil({
            Valido:auxVerificado,
            Pendiente:auxPendiente,
            Rechazado:auxRechazado}) 
          numeroPag(aux,tamMax,setTotalPaginas,setNumPagina,setListPagina)                       
          guardarDatos(aux) 
          setArregloColor(auxArrMuni)              
        }
        else
        { setListaExcel([])
          guardarDatos([])
          setListPagina([])
          guardarSinDatos(false)   
          setTotalPerfil([])    
          setArregloColor([])  
        }               
      }

      llamadaApiCarga(data, url, respuestaFiltro,ErrorGeneral,setEspera,history);
    } 

    const llamarListMun=()=>{
      let data = qs.stringify({
        usuario:usuID,       
        estado:15,
        tipo:usuTipo,                                         
        idDispositivo: auxDispV,
      });
      let url = "lugar-municipio-tipo";
      function respuestaMun(aux) {
        if (aux[0].Id != -1) 
        { setListMun(aux)           
        }
      }
      llamadaApi(data, url, respuestaMun,ErrorGeneral,history);
    }

    const llamarListSecc=()=>{
      let data = qs.stringify({
        usuario:usuID,                                                     
        idDispositivo: auxDispV,
      });
      let url = "lugar-seccion-list";
      function respuestaSecc(aux) {
        if (aux[0].Id != -1) 
        { setListSecc(aux)  
          //console.log(aux);        
        }
      }
      llamadaApi(data, url, respuestaSecc,ErrorGeneral,history);
    }

    llamadaListFiltro()

    if (listMun.length===0) 
    { llamarListMun()      
    }
    if (listSecc.length===0) 
    { llamarListSecc()     
    }
      
    if (listPlantilla.length===0) 
    {
      llamadaPlantilla()     
    }                  
             
    return ()=> {
      source.cancel();
    }   
               
  },[actualizar])
  
  function onChangePagina(event, newPage){
    event.preventDefault();
    if (newPage ===0) 
    { setListPagina(datos.slice(0,tamMax))   
    }
    else
    { setListPagina(datos.slice(newPage * tamMax, newPage * tamMax + tamMax))   
    }
    setNumPagina(newPage)
  }

  function generarFooter(){
    return(
    <TablePagination
      rowsPerPageOptions={false} colSpan={3}
      rowsPerPage={tamMax} count={datos.length}
      page={numPagina} lang='es'
      labelDisplayedRows={
          ({ from, to, count }) => {
            return '' + from + '-' + to + ' de ' + count
          }
        }
      onPageChange={onChangePagina}
      ActionsComponent={PaginacionTab}/>)
  }

  const exportarArch = () => {
    let today = new Date();                    
    let fec = moment(today);        
    console.log(listaExcel);
    const ws = XLSX.utils.aoa_to_sheet(listaExcel);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Reporte");
    XLSX.writeFile(wb, fec.format("YYMMDDHHmmss")+" Lista Publico.xlsx")
  };

  React.useEffect(()=>{
    if (nvoRegisPerfil.length!==0 &&!modalAvisoImg2 && !modalNvoImg ) {
      setTimeout(() => {
        //console.log('This will run after 1 second!')       
       setModalAvisoImg(true)
      }, 500);  
    }
    
  },[nvoRegisPerfil])

  const seleccionado=(auxId,auxOrden)=>{
    setSelectedID(auxId)   
    setRegisSelect(auxOrden)
  }      
  
  function tipoEdoProspecto(auxColorStd,etiqueta)
  {  
    let renglon=(
      <TableCell  align="center" padding={'none'}        
        style={{ color:'white',fontWeight:700 ,width:100+'px',paddingLeft:.4+'rem',paddingRight:.4+'rem'}} 
      >
        <Card style={{color:auxColorStd==="#EBF1F8"? "black":'white', fontWeight:"bold", backgroundColor:auxColorStd}}>
          {etiqueta}
        </Card>
      </TableCell>    )
    return renglon
  } 

  function abrirModalMapa(auxSelec){
    let fecha = moment.utc(auxSelec.Fecha).format('YYYY-MM-DD HH:mm:ss') === "1900-01-01 00:00"
    ? "" : moment.utc(auxSelec.Fecha).format('DD/MM/YYYY HH:mm:ss')
    let titulo=`${auxSelec.Id}`;
    setTituloMapa(titulo)
    setSubtInfo(fecha)
    setRegisSelect(auxSelec)
    setInfoMapaMod(true)
  }
  
  function abrirModalInfo(auxSelec){
    setModalInfo(true)
    
    let titulo=`Registro ${auxSelec.Id} `;
    setTituloMapa(titulo)
    setRegisSelect(auxSelec)
  } 

  const celda=(alinear,estilo,contenido)=>{
    return (
    <TableCell align={alinear} className={classes.tableCell} padding={'none'} style={estilo} >
      {contenido}
    </TableCell>
    )
  }  

  const editarPerfil=(e,row,auxValida)=>{
    e.preventDefault()
    if (auxValida===1) {      
      setModalNvoImg(true);    
      setNvoRegisPerfil({Nom:row.Nombre,llave:row.Id})  
    }    
  }

  function Renglon(props)
  {
    const { row } = props; 
    let auxMuni=row.MunNom.charAt(0)+ row.MunNom.slice(1).toLowerCase()   
    let auxNac=moment.utc( row.Nacimiento).format('YYYY-MM-DD HH:mm') === "1900-01-01 00:00"
    ? "" : moment.utc( row.Nacimiento).format('DD/MM/YYYY')    
    let auxFReg=moment.utc( row.Fecha).format('YYYY-MM-DD HH:mm') === "1900-01-01 00:00"
    ? "" : moment.utc( row.Fecha).format('DD/MM/YYYY HH:mm')
    let leyenda=`Crear Imagen`
    let tipoColor="inherit"
    let auxValida=1                    
    if(row.Imagen.length > 0)
    {
      leyenda=`Imagen Cargada`
      tipoColor="primary"
      auxValida=0
    }
    let imgEstilo=selectedID === row.Id ? {width:18+'px',color:'white',marginTop:.2+'rem'} :{width:18+'px',marginTop:.2+'rem'}
    let auxImg = <Tooltip title={leyenda}> 
                  <IconButton   aria-label="agregar imagen" size="small" 
                    onClick={(e) => editarPerfil(e,row,auxUsuID)} component="span">
                    <ImageIcon style={imgEstilo} color={tipoColor} /> 
                  </IconButton>
                </Tooltip> 
                                        
    return (
    <React.Fragment>
    <TableRow onClick={ () => { seleccionado(row.Id,row); } }
      className={classes.tableRow} selected={ selectedID === row.Id}      
      classes={{ hover: classes.hover, selected: classes.selected }}        
    >
      <TableCell align="center" padding={'none'} style={{ width:10+'px'}} >
        <Tooltip title={`Información de registro`}>
          <IconButton   aria-label="add circle" size="small" onClick={() => abrirModalInfo(row)} component="span">
            {selectedID === row.Id ? 
              <InfoIcon style={{color:'white',width:18+'px'}} />
              :<InfoIcon style={{width:18+'px'}} color="primary"/>
            }
          </IconButton>                     
        </Tooltip>                                                                                                                                                       
      </TableCell>  
      <TableCell align="center" padding={'none'} style={{ width:10+'px'}} >
        <Tooltip title={`Ver Mapa`}>
          <IconButton   aria-label="add circle" size="small" onClick={() => abrirModalMapa(row)} component="span">
            {selectedID === row.Id ? 
              <LocationOnIcon style={{color:'white',width:18+'px'}} />
              :<LocationOnIcon style={{width:18+'px'}} color="primary"/>
            }
          </IconButton>                            
        </Tooltip>                                                                                                                                              
      </TableCell>    
      {celda("center",{width:10+'px',paddingLeft:.3+'rem'}, auxImg)}
      {/* celda("center",{fontWeight:700,width:40+'px', paddingRight:2+'px'}, row.Id) */}    
      {celda("center",{width:105+'px',fontSize:12+'px',paddingLeft:8+'px'}, auxFReg)}
      {celda("center",{width:90+'px',fontSize:12+'px',paddingLeft:8+'px',paddingRight:8+'px'}, row.Origen)}
      {celda("center",{width:220+'px',fontSize:12+'px', paddingRight:2+'px'}, auxMuni)}
      {celda("center",{fontWeight:700,width:40+'px', paddingRight:2+'px'}, row.SeccNum)}        
      {celda("center",{width:50+'px',fontSize:12+'px',paddingLeft:8+'px'}, row.Tel)}       
      {celda("left",{width:220+'px',fontSize:12+'px',paddingLeft:8+'px'}, row.Nombre)}  
      {celda("left",{width:200+'px',fontSize:12+'px',paddingLeft:8+'px'}, row.Social)}
      {celda("left",{width:200+'px',fontSize:12+'px',paddingLeft:8+'px'}, row.Email)}                                                                                     
      {tipoEdoProspecto(row.StatusColor,row.StatusNom)}             
      {celda("center",{width:105+'px',fontSize:12+'px',paddingLeft:8+'px'}, auxNac)}                                                                                                                                                                                   
    </TableRow>        
    </React.Fragment>
  );}
  
  const vacia=<TableCell  className={classes.tableCell} align="center"> </TableCell>
  const ColumnaVacia=<TableCell  className={classes.tableCell} align="center" style={{ backgroundColor:fondo,color:'white'}}  > </TableCell>

  function tablaCompleta(auxlista){ 
    return (
    <TableContainer className={classes.container}>
    <Table stickyHeader aria-label="sticky table">
      <TableHead>
        <TableRow>
          {ColumnaVacia}
          <TableCell
            align="center" padding={"none"}
            style={{ backgroundColor: fondo, color: "white" }}
          >            
            <IconButton onClick={()=>{}} size="small" >
              <Tooltip title="Exportar a Excel">
                <SaveIcon style={{ color: "white" }}size="small" />
              </Tooltip>
            </IconButton>           
          </TableCell>
          <TableCell
            align="center" padding={"none"}
            style={{ backgroundColor: fondo, color: "white" }}
          >
            {auxlista.length}
          </TableCell>
          {columnas.map((row) => {
            return (
              <TableCell
                style={{ backgroundColor:fondo, color: "white" }}
                key={row.titulo + row.id} align={row.alinear}
                padding={"none"}
              >
                {row.titulo}
              </TableCell>
            );
          })}
        </TableRow>
      </TableHead>
      <TableBody>
        {auxlista.length != 0
          ? auxlista.map((row) => (
              <Renglon key={"regPerfilGen" + row.Id} row={row} />
            ))
          : null}
      </TableBody>
    </Table>
    </TableContainer>
    );
  }
    
  const tablaSinDatos=()=>{
    return(
    <TableContainer className={classes.container2}>
    <Table stickyHeader aria-label="sticky table">
      <TableHead>
        <TableRow>            
          <TableCell align="center" padding={'none'}  style={{ backgroundColor:fondo,color:'white'}} >              
            {/* */}           
          </TableCell>       
          {columnas.map(row => {              
            return(  
            <TableCell style={{ backgroundColor:fondo,color:'white'}} 
              key={row.titulo+row.id}    align={row.alinear} padding={'none'} 
            >
              {row.titulo}
            </TableCell>    
            )                
          })}                                                
        </TableRow>
      </TableHead>
      <TableBody>
        <TableRow  className={classes.tableRow} > 
          {vacia}
          {vacia}  
          {vacia}                
          <TableCell  className={classes.tableCell} align="center">Sin </TableCell>  
          {vacia}               
          <TableCell  className={classes.tableCell} align="center">Registros</TableCell>
          {vacia}      
          {vacia}               
          {vacia}      
        </TableRow>
      </TableBody>
    </Table>
    </TableContainer>
  )}
     
  
  const contenido = sinDatos&&listPagina.length!==0 ?tablaCompleta(listPagina):tablaSinDatos()
  const tabla = espera ?  (<Box pt={3}><LinearProgress/> </Box>) :( contenido )
 
  const modalMap = infoMapaMod ? <ModalMapa seleccionado={regisSelect} titulo={tituloMapa} subtitulo={subtInfo}
                                    setModalAbierto={setInfoMapaMod } modalAbierto={infoMapaMod}/> : null;     

  const modInfo = modalInfo ? <InfoPromo seleccionado={regisSelect} modalAbierto={modalInfo} 
                                setModalAbierto={setModalInfo} titulo={tituloMapa} /> : null;            
                                 
  const modNvoImg = modalNvoImg  && listPlantilla.length!== 0  ? 
                                  <ModalNvoPerfilImg modalAbierto={modalNvoImg} setModalAbierto={setModalNvoImg} 
                                    titulo= {nvoRegisPerfil.Nom ? "Perfil "+nvoRegisPerfil.Nom:"Perfil"}  auxPerfImg={listPlantilla}
                                    nvoRegisPerfil={nvoRegisPerfil} actualizar={actualizar} setActualizar={setActualizar}
                                  />:null         
                                     
 /*  const modNvoAviso = modalAvisoImg  ? <ModalAvisoPerfil modalAbierto={modalAvisoImg} setModalAbierto={setModalAvisoImg} 
                                        titulo="Validación" abrirModImg={abrirModalNvoPerfilImg} 
                                        actualizar={actualizar} setActualizar={setActualizar}
                                      />:null    */     
  const paginacion = !espera&&listPagina.length!==0 && totalPaginas>1 ? generarFooter() : null;                                                                                                                                                                   
  return (
  <div style={{minWidth:52+'rem',maxWidth:63.7+'rem'}}>        
    {tabla}  
    {paginacion}           
    {modalMap}
    {modInfo}    
    {modNvoImg}
    {/* modNvoAviso */}    
  </div>
  )
}

export default TablaPerfilPublico