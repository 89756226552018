import React,{createContext,useState} from 'react';

export const GeneralesContext = createContext();

const GeneralesProvider = (props) => {
    const [generales,guardarGenerales]=useState([])
    return (
        <GeneralesContext.Provider
            value={ { generales , guardarGenerales } }
        >
            { props.children }
       </GeneralesContext.Provider> 
    )
}

export default GeneralesProvider
