//import jwt from "jsonwebtoken";
import moment from "moment";
export function authUser() {
  const fecha = localStorage.getItem('fch22');
  let validar = false;
  if (fecha) 
  {   let fechaMod=moment(fecha).add(24,'h').format("YYYY-MM-DDTHH:mm")            
    const hoy=moment().format("YYYY-MM-DDTHH:mm")
    if (fechaMod>hoy) 
    {   validar=true
    }             
  } 
  return validar;
}

