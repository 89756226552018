import React,{useState} from 'react';
import {Breadcrumbs,Typography,Link, Box} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import {AuthContext} from '../context/AuthContext'
import {GeneralesContext} from '../context/GeneralesContext'
import axios from 'axios';
import qs from 'qs';
import { authUser } from '../funciones/AuthUser'
import { useHistory } from "react-router-dom";
import NombreIni from './NombreInicio'
import ZonaInicio from './ZonaInicio';
import MunicipioInicio from './MuniInicio';
import SeccionInicio from './SeccionInicio'
import RegionInicio from './RegionInicio'
import CoordinadorSecc from './CoorSeccional'
import PromotorInicio from './PromotorInicio';
import PublicoInicio from './PublicoInicio';
import PerfilExterno from '../perfilExt/PExterno'; 
import {llamaApiCargaToken,ErrorGeneral } from '../funciones/ClienteAxios';

const useStyles = makeStyles((theme) => ({
    textoSelect: {
      '&:hover': {
        color:'#9ccdfa',
      },
      fontSize:1.25+'rem'
    },
}));

const PerfilInicio = () => {
    let history = useHistory();     
    const classes = useStyles();    
    const source = axios.CancelToken.source();    
    const usuID = localStorage.getItem('UsuId');
    const usu = localStorage.getItem('UsuarioNom');            
    const nvl=  localStorage.getItem('Tipo')?parseInt(localStorage.getItem('Tipo')):0;        
    const Dispvo =localStorage.getItem('Dispvo') ;    
    const auxDispV =Dispvo && Dispvo.length >= 12 ? Dispvo:'w8rf51v21dsd2cs'    
    const {usuario,guardarUsuario}=React.useContext(AuthContext);
    const {generales,guardarGenerales}=React.useContext(GeneralesContext); 
    const[listMuni,setListMuni]= useState([]);
    const[opcion,setOpcion]= useState(0)
    const [espera,setEspera]= useState(false);
    
    React.useEffect(()=>{                                                            
        veriSesion()

        return ()=> {
            source.cancel();
        }          
    },[])
    
    const veriSesion =  () => {            
        let auxValidacion=authUser()
        if(!auxValidacion ||(usu===undefined||usu===''||usu===null)||(nvl===undefined||nvl===''||nvl===null)
            ||(usuID===undefined||usuID===''||usuID===null) )
        {   guardarUsuario([])				
            guardarGenerales([])    
            localStorage.clear();            	             
            history.push("/")	                	
        }
        else
        {   if (usuario.length==0) 
            {   guardarUsuario({Nombre:usu,nivel:nvl});                                                         
            }        
            
            llamadaListMuni();                                 
            guardarGenerales({...generales,Titulo:'Perfiles'})                                                       
        }   
    }

    function llamadaListMuni()  {            
        const url= `lugar-municipio-tipo`;                            
        const data = qs.stringify({                
            usuario:usuID,              
            estado:process.env.REACT_APP_EDO,                
            tipo:nvl,                        
            idDispositivo:auxDispV,
            });
                    
        function respuesta (aux) 
        {   if (aux[0].Id != -1) 
            {   setListMuni(aux)
            }                                                                      
        }
        llamaApiCargaToken(data, url, respuesta, ErrorGeneral, setEspera, history, source);
    };

    const selectOpcion =(e,auxValor)=>{
        e.preventDefault();        
        setOpcion(auxValor)
    }

    const miBreacumb=(valor,titulo)=>{
        return(
        <Typography variant="h5" className={classes.textoSelect} 
            color={opcion === valor ? "Primary":'textPrimary'} onClick={e=>selectOpcion(e,valor)}
        >
            {titulo}
        </Typography>
        )
    }

    const menuAdmin=(  
            <Breadcrumbs separator="-"   aria-label="breadcrumb"   > {/*nivel administrador */}
                {miBreacumb(7,"Estatal")}
                {miBreacumb(4,"Zona")} 
                {miBreacumb(1 ,"Municipio")} 
                {miBreacumb(2,"Sección")}               
                {miBreacumb(6,"Promotor")}          
                {miBreacumb(3,"General")}      
                {/*   usuID !== undefined && usuID !== null && (parseInt(usuID) === 1 || parseInt(usuID) === 170303 ) ? 
                miBreacumb(8,"Público"):null  */}                       
                {/*  usuID !== undefined && usuID !== null && (parseInt(usuID) === 1 || parseInt(usuID) === 170303 ) ? 
                miBreacumb(9,"Externo"):null */}                                
            </Breadcrumbs> 
            )

    const menu=(<Breadcrumbs separator="-" aria-label="breadcrumb" > {/*nvl zona */}            
                {miBreacumb(4,"Zona")} 
                {miBreacumb(1 ,"Municipio")}   
                {miBreacumb(2,"Sección")}                    
                {miBreacumb(6,"Promotor")}  
                {miBreacumb(3,"General")}      
                {/* miBreacumb(8,"Público") */}                      
            </Breadcrumbs>
            )

    const menu2=(<Breadcrumbs separator="-" aria-label="breadcrumb" >{/*nivel coord region*/}
            {miBreacumb(1 ,"Municipio")}   
            {miBreacumb(2,"Sección")}              
            {miBreacumb(6,"Promotor")}  
            {miBreacumb(3,"General")}      
            {/* miBreacumb(8,"Público") */}                                  
        </Breadcrumbs>
        )  

    const menu3=(<Breadcrumbs separator="-" aria-label="breadcrumb" >{/*nivel coord municipio*/}        
        {miBreacumb(2,"Sección")}              
        {miBreacumb(6,"Promotor")}  
        {miBreacumb(3,"General")}      
        {/* miBreacumb(8,"Público") */}                                  
    </Breadcrumbs>
    )  

    const menu4=(<Breadcrumbs separator="-" aria-label="breadcrumb" >{/*nivel coord seccional*/}        
        {miBreacumb(2,"Sección")}              
        {miBreacumb(6,"Promotor")}  
        {miBreacumb(5,"General")}      
        {/* miBreacumb(8,"Público") */}
    </Breadcrumbs>
    )   
    
    const menu5=(<Breadcrumbs separator="-" aria-label="breadcrumb" >{/*nivel coord seccional   en este nivel se usa coorSeccional */}      
        {miBreacumb(5,"General")}      
        {/* miBreacumb(8,"Público") */}          
    </Breadcrumbs>
    )   

    const filtraTipo=()=>{        
        //console.log(nvl);
        let opciones=null 
        switch (nvl) {
            case 1 :
                opciones=menuAdmin                
                break;
            case 2:
                opciones=menu5
                break;    
            case 3:
                opciones=menu
                //console.log("coordinador regional");
                break;    
            case 4:
                opciones=menu2
                //console.log("coordinador regional");
                break;
            case 5:
                opciones=menu3
                break;
            case 6:
                opciones=menu4
                break;    
                
            default:
                break;
        }
        return(opciones)
    }
    const contenido9 = opcion === 9 ?<PerfilExterno listMuni={listMuni}/>: null
    const contenido8 = opcion === 8 ? <PublicoInicio listMuni={listMuni} /> : contenido9       
    const contenido7 = opcion === 7 ? <ZonaInicio listMuni={listMuni} /> : contenido8       
    const contenido6 = opcion === 6 ? <PromotorInicio listMuni={listMuni} /> :  contenido7       
    const contenido5 = opcion === 5 ? <CoordinadorSecc listMuni={listMuni} /> : contenido6                        
    const contenido4 = opcion === 4 ? <RegionInicio listMuni={listMuni} /> : contenido5                        
    const contenido3 = opcion === 1 ? <MunicipioInicio listMuni={listMuni} /> : contenido4                        
    const contenido2 = opcion === 2 ? <SeccionInicio listMuni={listMuni} /> : contenido3                        
    const contenido = opcion === 3 ? <NombreIni /> : contenido2  

    return( 
    <div>
        <Box display="flex" pt={2} pb={1} style={{justifyContent:'center'}}>
            {filtraTipo()}
        </Box>
        {contenido}
    </div>
    );
};

export default PerfilInicio;
